import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';
//import {  } from '@fullcalendar/core';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UiDialogService } from '../../../../services/uiDialog.service';
import { DomSanitizer } from '@angular/platform-browser';
//import {states} from '../../../../../assets/csc-json/states.json';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
// import * as dragula from 'dragula';
// import 'dragula';
// import  'fullcalendar';
// import $ from 'jquery'; 

import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'console';

// declare var $; 
@Component({
  selector: 'app-training-event',
  templateUrl: './training-event.component.html',
  styleUrls: ['./training-event.component.scss'],
  providers: [AppService, Constants, UiDialogService]
})
export class TrainingEventComponent implements OnInit {
  options: FullCalendarOptions;
  // options: any;
  // events: EventObject[];
  SubscribeCompanyChange: Subscription;
  language_id: any;
  public banner: any = [];
  public loader: boolean = true;
  public allEvent: any = [];
  public pageData: any = [];
  public object1: any = [];
  @ViewChild('calendar', { static: true }) calendar: any;
  bannerURL: any = '';
  bannerImageTitle: string = '';
  bannerLinkTarget: string = '';
  bannerTitle: string = '';
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  arabicTitle: any;
  englishTitle: any;

  bannerData: any;
  yearValues: any[] = [];
  monthValues: any[] = [];
  monthName: any[] = [];
  eventYear: string = '';
  eventMonth: any;
  events: any[] = [];
  selectedYearText: string = '';
  bannerSlider: any[] = [];
  bannerArabicTitle: any;
  @ViewChild('targetServDesc', null) targetServDesc: ElementRef;
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  pageInfo: any;


  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, public Service: AppService, public router: Router, public route: ActivatedRoute, public sanitizer: DomSanitizer, public uiDialog: UiDialogService, public constant: Constants, public titleService: Title) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {
    this.titleService.setTitle('EIAC - Training Events');
    let getyear = this.route.snapshot.queryParamMap.get('year');
    let getMonth = this.route.snapshot.queryParamMap.get('month');    

    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');

    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.loadService();
      });


    if (getMonth === null || getMonth === '') {
      this.options = {
        defaultDate: new Date(),
        editable: false,
        navLinks: true,
        weekends: true, 
        eventLimit: true,
        hiddenDays: [], //0-6 range day number 
        locale: (parseInt(localStorage.getItem("language_id")) == 1) ? 'en' : 'ar'       
      }
      this.eventYear = new Date().getFullYear().toString();
      let getMon = new Date().getMonth();
      this.eventMonth = getMon + 1;
      //console.log('Cur year: ', this.eventYear, " :: ", getMon);
    } else {
      let dateStr: string;
      let mon: number;
      let day: number = 1;
      let yearValue = getyear;
      let monthValue: any = (getMonth);

      this.eventMonth = getMonth;
      this.eventYear = (getyear);
      dateStr = yearValue.toString() + '-' + monthValue.toString() + '-' + day.toString();
      this.options = {
        defaultDate: dateStr,
        editable: false,
        navLinks: true,
        eventLimit: true,
        locale: (parseInt(localStorage.getItem("language_id")) == 1 ) ? 'en' : 'ar'
      }
    }


    // this.scrollToPage('calendarEvent');
    this.monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    //console.log(this.monthName);
    //year set
    for (var i = 2020; i <= 2050; i++) {
      this.yearValues.push({ label: i.toString(), value: i });
    }
    for (var k = 0; k < this.monthName.length; k++) {
      this.monthValues.push({ name: this.monthName[k], value: (k+1) });
    }
    // console.log(this.monthName, " -- ", this.monthValues);

    this.loadService();


  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            //console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep)
      }
    }

  }

  scrollToPage(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }

  scrollForm(type: string) {
    //console.log('calling scroll...');
    //el.scrollIntoView({behavior: 'smooth'});
    switch (type) {
      case 'targetServDesc':
        this.targetServDesc.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
    }
  }

  loadEventByYearMonth(monthValue: number) {
    // console.log(monthValue);    
    let yearValue = this.eventYear;
    let theEvtUrl = 'training-event?year=' + yearValue + '&month=' + monthValue;
    // console.log("Change year/month: ", theEvtUrl);
    top.location.href = theEvtUrl;

  }


  loadEventYear(yearValue: number) {
    //let theEvtUrl = 'training-event?year='+yearValue;
    let monthValue = this.eventMonth;
    let theEvtUrl = 'training-event?year=' + yearValue + '&month=' + monthValue;
    if (monthValue != '') {
      top.location.href = theEvtUrl;
    }

    //  if(yearValue){
    //    let dateStr: string;
    //    let mon: number = 1;
    //    let day: number = 1;
    //    dateStr = yearValue.toString() + '-' + mon.toString() + '-' + day.toString();
    //    //console.log(dateStr, " - ", this.options);
    //    if(this.options['defaultDate'] != undefined)
    //    this.options['defaultDate'] = new Date(dateStr);
    //    //this.calendar.options = this.options;
    //    //console.log('updateing Date: ',dateStr, " - ", this.options);
    //    //this.loadService();
    //  }
  }

  ngAfterViewInit() {
    ////console.log("after vie loading...");    
  }
  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  loadService() {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.Service.getWithMultipleParams(this.constant.API.event_service, 'event_service', this.language_id)
      .subscribe(
        res => {
          this.allEvent = [];
          this.allEvent = res['allEventData'];
          this.pageData = res['pageData'];
          this.options.locale = (parseInt(localStorage.getItem("language_id")) == 1 ) ? 'en' : 'ar';
          console.log("@Event Data: ", this.allEvent);
          console.log("@Calendar Options: ",this.options);

          console.log("@Enter event browse....1");
          // data.event_end_date_time.substring(0, data.event_end_date_time.length - 1);
          this.allEvent.forEach(data => {
            if (data['eventDates'] != null) {
              for (let key in data['eventDates']) {
                console.log(">>> event....1: ", data.course.name);
                let theEvtUrl = 'training-course-detail/' + data.training_course;
                this.events.push({
                  id: data.id, url: "javascript:void(0)", redirectUrl: theEvtUrl, title: data.course.name, description: data.course.description, start: data['eventDates'][key]['event_date'], end: data['eventDates'][key]['event_date']
                })
              }
            } else { 
              if (data.event_start_date_time != undefined && data.event_end_date_time != undefined) {
                let theEvtUrl = 'training-course-detail/' + data.training_course;
                console.log(">>> event....2: ", data.course.name);
                this.events.push({
                  id: data.id, url: "javascript:void(0)", redirectUrl: theEvtUrl, title: data.course.name, description: data.course.description, start: data.event_start_date_time.substring(0, data.event_start_date_time.length - 1), end: data.event_end_date_time.substring(0, data.event_end_date_time.length - 1)
                })
              }
            }
            // let theEvtUrl = 'training-course-list?course_id='+data.training_course;
            // this.events.push({
            //   id :data.id , url:theEvtUrl, title: data.course, start: data.event_start_date_time.substring(0, data.event_start_date_time.length - 1), end: data.event_end_date_time.substring(0, data.event_end_date_time.length - 1)
            // })

          });
          console.log("@Enter event browse....2");

          console.log("@updated events: ", this.events);

          // if(res['banner'] && res['banner'].length>0){ 
          //   ////console.log('aaaaa', res['banner'][0]['image'], " :: ", res['banner'][0].image);
          //   this.banner = this.constant.mediaPath+res['banner'][0]['banner']['image'];
          //   //this.bannerImageTitle = res['banner'][0]['title'];
          //   this.bannerImageTitle = (res['banner'][0]['image_name'] != null) ? res['banner'][0]['image_name'] : '';
          //   this.bannerURL = (res['banner'][0]['banner']['url'] != '') ? this.getSantizeUrl(res['banner'][0]['banner']['url']) : '';  
          //   this.bannerLinkTarget = (res['banner'][0]['url'] != null) ? '_blank' : '_self';

          //   this.arabicTitle = res['banner'][0]['title'];
          //   this.englishTitle = res['banner'][1]['title'];
          //   this.footerBanner = this.constant.mediaPath+res['banner'][0]['banner']['footer_image'];
          //   this.footerTitle = res['banner'][1].footer_title;
          //   this.footerDescription = res['banner'][1].footer_description;

          // } 

          if (res['banner'].length > 0) {
            this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            this.bannerTitle = res['banner'][select_language]['title'];
            this.pageInfo = res['banner'][select_language];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][select_language]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][select_language]['headerBanner'].length > 0) {
              res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }

          this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
          this.footerTitle = res['banner'][select_language].footer_title;
          this.footerDescription = res['banner'][select_language].footer_description;

          

          this.loader = false;

        },
        error => {

        })
  }

  clickButton(event) {
    //Do the needful
  }

  //On Nav header click
  handleNavclick(evtData: any) {
    ////console.log('Nav Header Click - ', evtData);
  }

  //On Date click in any month
  handleDateClick(evtData: any) {
    ////console.log('Date Click - ', evtData);
  }



  //Handling day
  handleDay(eventDay: any) {
    ////console.log("Event Day: ", eventDay);
    //elem - innerText/innerHTML/hidden/height/width/title/textContent
    let dateObj = eventDay.date;
    let elem = eventDay.el;
    let output = eventDay.view;
    output.header.el.innerText.toString().replace("Sun", 'Holiday');
    output.header.el.innerText.toString().replace("Mon", 'AdDay');
    if (new Date(dateObj).getDay() === 5 && output.header.el.innerText != undefined) {
      //elem.title = "FFF";
      //output.header.el.innerText = 'Sun Mon Tue Wed Thu Holiday Holiday';
      let find = output.header.el.innerText.toString().toLowerCase().indexOf('fri');
      if (find != -1) {
        ////console.log('changing text haedrer...');
        output.header.el.innerText.toString().replace("Fri", 'Holiday');
      }
      // //console.log('search fri: ', find);
    }
    if (new Date(dateObj).getDay() === 6 && elem.hidden != undefined) {
      //elem.hidden = true;
    }
    // //console.log(eventDay, " :: ", dateObj, " - ", new Date(dateObj).getDay());
  }
  //fc-day fc-widget-content fc-tue fc-other-month fc-past
  //fc-day-grid-event fc-h-event fc-event fc-start fc-end fc-allow-mouse-resize

  handleDates(eventDates: any) {
    ////console.log("Dates Event: ", eventDates);
  }

  //Click on event on calendar
  handleEventClick(evtData: any) {
    // console.log('Event Click - ', evtData);
    let obj = {
      // title : evtData.event._def.title,
      title: evtData.calEvent.title,
      // description : evtData.event._def.extendedProps.description,
      description: evtData.calEvent.description,
      // link : evtData.event._def.extendedProps.redirectUrl
      link: evtData.calEvent.redirectUrl
    };
    // console.log('Event Click - ', title+description+link);
    this.uiDialog.eventCalenderDialog(obj);
  }
}

