import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { HappinessMeterComponent } from './happiness-meter/happiness-meter.component';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { EnquiryDialogboxComponent } from './enquiry-dialogbox/enquiry-dialogbox.component';
import { EventCalenderComponent } from './event-calender/event-calender.component';
import { BodDialogComponent } from './bod-dialog/bod-dialog.component';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';
import { HttpClientModule , HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [DialogBoxComponent, HappinessMeterComponent, EnquiryDialogboxComponent, EventCalenderComponent, BodDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPubSubModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  entryComponents: [DialogBoxComponent, HappinessMeterComponent, EnquiryDialogboxComponent],
  providers: [AppService]
})
export class CommonAllModule { }
