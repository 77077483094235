import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { AppService } from '../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { ReturnStatement } from '@angular/compiler';
import { UiDialogService } from '../../services/uiDialog.service';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  providers: [AppService, Constants, UiDialogService],
})
export class SearchPageComponent implements OnInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  //Banner Configuration
  @ViewChildren('myCheckbox') private myCheckboxes: QueryList<any>;
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    fade: false,
    autoplay: false,
    draggable: true,

    cssEase: 'linear',
    pauseOnHover: false,
  };
  //speed: 1000,

  footerBanner: string = '';
  footerTitle: string = '';
  footerDescription: string = '';

  banner: string = '';
  bannerLinkTarget: string = "";
  bannerURL: string = '';
  bannerImageTitle: string = '';
  bannerTitle: string = '';
  arabicTitle: any;
  englishTitle: any;
  bannerArabicTitle: any;
  bannerSlider: any[] = [];

  faqCategories: any[] = [];
  faqAllItems: any[] = [];

  srch_loader: boolean = false;
  srchString: string = '';
  srchFilterOptions: any[] = [];
  srchResults: any[] = [];
  selectFilterTypes: any[] = [];
  srchPost: any = {};

  //Add pagination
  paginationConfig: any;
  pageLimit: number = 5;
  pageCurrentNumber: number = 1;
  pageConfigData: any = {};
  pageData: any = {};
  pageTotal: number;

  length = 0;
  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public apiRequestData: any = {};
  allCheck: boolean = true;

  masterSelected: boolean = true;
  anyChecked: boolean = true;
  filterDirectoryCount: number = 0;
  allCount: number = 0;

  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, public route: ActivatedRoute, public router: Router,
    public _constant: Constants, public _service: AppService, public uiDialog: UiDialogService) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }
  //https://www.the-art-of-web.com/javascript/search-highlight/

  loadBanner = async () => {
    ////////console.log('>>>>Banner');
    await this._service.getwithoutData(this._constant.API.directory_page)
      .subscribe(
        res => {
          if (res['banner'].length > 0) {
            this.banner = this._constant.mediaPath + res['banner'][1]['banner']['image'];
            this.bannerTitle = res['banner'][1]['title'];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][1]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][1]['headerBanner'].length > 0) {
              res['banner'][1]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this._constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this._constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }
          this.footerBanner = this._constant.mediaPath + res['banner'][1]['banner']['footer_image'];
          this.footerTitle = res['banner'][1].footer_title;
          this.footerDescription = res['banner'][1].footer_description;
          this.srch_loader = false;
        },
        error => {

        })
  }
  loadFAQ = async () => {
    let catIds: any[] = [];
    await this._service.get(this._constant.API.faq_category, '')
      .subscribe(
        record => {
          ////////console.log('FAQ: ', record);
          let data: any = record;
          if (data && data.data != undefined) {
            //this.faqCategories = data.data;
            data.data.forEach(rec => {
              catIds.push({ id: rec.faq_category, title: rec.title })
            })
            if (catIds.length) {
              catIds.forEach(item => {
                this._service.get(this._constant.API.faq_question_ans, item.id)
                  .subscribe(
                    rec => {
                      let reData: any = rec['data'];
                      //////////console.log(">>>cat question: ", item.id, " :: ", reData);
                      reData.forEach(dataItem => {
                        this.faqAllItems.push({ catId: item.id, pathId: dataItem.id, question: dataItem.question })
                      })
                    })
              })
            }
          }
        })
    //////////console.log(">>>Store faq cat ids: ", catIds, " -- ", this.faqAllItems);
  }
  loadFilterType() {
    let filterTypes: any[] = this._constant.searchFilterTypeLabel;
    for (var i = 0; i < filterTypes.length; i++) {
      this.srchFilterOptions.push({ title: filterTypes[i].title, value: filterTypes[i].value, isSelected: filterTypes[i].isSelected, count: filterTypes[i].count });
    }
  }
  onPaginateChange(event) {
    ////////console.log("paginate event: ",event)
    this.apiRequestData.pageEvent = event;
    //this.loadData(this.apiRequestData);
    this.searchByKey();
  }

  searchType(theText: any) {
    //console.log(">>>Eneter: ", theText, " :: ", this.srchString);
    // if(this.srchString != ''){
    //   this.typedQuery = true;
    // }
    if (this.srchString === '') {
      //console.log("empty string....");
      this.searchByKey();
    }
  }

  ngOnInit() {

    //this.loadBanner();
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
      });
    this.loadFAQ();
    this.loadFilterType();
    this.apiRequestData.pageEvent = {};
    //let query_srch = this.route.snapshot.queryParamMap.get('q');
    //console.log(">>>>1 ", sessionStorage);
    let query_srch = sessionStorage.getItem('query');
    //console.log('query: ', query_srch);

    if (query_srch != undefined && query_srch != '') {
      this.srchString = query_srch;
      this.searchByKey();
    } else {
      //this.srchPost['keyword'] = '';
      //this.srchPost['search_type_arr'] = ['all'];
      this.searchByKey();
    }
    if (sessionStorage.getItem('query') != undefined && sessionStorage.getItem('query') != '') {
      sessionStorage.setItem('query', '');
    }

    //Find search element
    //Action Event for Search Element
    let srchElem: HTMLElement = document.getElementById('srchKey') as HTMLElement;
    if (srchElem) {
      srchElem.addEventListener('keypress', (evt) => {
        let targetObj: any = evt.target;
        //&& targetObj.value != ''
        if (evt && typeof evt === 'object') {
          if (evt.key === 'Enter' && evt.code === 'Enter') {
            this.searchByKey();
          }
        }
      })
    }
    this.srchFilterOptions.forEach((item, key) => {
      if (item.value) {
        this.selectFilterTypes.push(item.value);
      }
    });
    //this.masterSelected = true;
    // let myCheckboxes = this.myCheckboxes;
    // //console.log("checkoboxes: ", myCheckboxes);
    // var array = document.getElementsByTagName("input");
    //   for(var ii = 0; ii < array.length; ii++)
    //   {
    //     if(array[ii].type == "checkbox")
    //     {
    //         if(array[ii].className == 'check-item')
    //         {
    //           array[ii].checked = true;

    //         }
    //     }
    //   }
  }

  //Slider change callback
  afterChange(theEvt: any) {
    ////////////////////console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      ////////////////////console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        ////////////////////console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          ////////////////////console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        ////////////////////console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        ////////////////////console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            ////////////////////console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          ////////////////////console.log('slider start....');
        }, sleep)
      }
    }

  }
  filterReset() {
    ////console.log('reset...');
    this.masterSelected = false;
    this.srchFilterOptions.forEach((item, key) => {
      /////console.log(item.title, " :: ", item.isSelected);
      //if(!item.isSelected){
      item.isSelected = false;
      //}
    })
    //check /uncheck checking
    this.srchFilterOptions.forEach((item, key) => {
      if (!item.isSelected) {
        this.anyChecked = false;
      }
    })
    if (!this.masterSelected) {
      this.anyChecked = false;
    }
    this.selectFilterTypes = [];
    this.srchFilterOptions.forEach((item, index) => {
      item.count = 0;
    })
  }
  filterAllClick(theVal: any) {
    ////console.log("@All: ", theVal);
    if (theVal.checked) {
      ////console.log('eeee');
      this.masterSelected = true;
      this.anyChecked = true;
      this.srchFilterOptions.forEach((item, key) => {
        if (!item.isSelected) {
          item.isSelected = true;
        }
      })
      this.srchFilterOptions.forEach((item, key) => {
        if (item.value) {
          this.selectFilterTypes.push(item.value);
        }
      });
    }
    if (!theVal.checked) {
      this.masterSelected = false;
      this.srchFilterOptions.forEach((item, key) => {
        if (item.isSelected) {
          item.isSelected = false;
        }
      })

      this.selectFilterTypes = [];

    }
    //check /uncheck checking
    let checkFilter = this.srchFilterOptions.find(item => item.isSelected === true);
    ////console.log('checking ..', checkFilter, " -- ", this.masterSelected, " -- ", theVal);
    if (checkFilter != undefined && typeof checkFilter === 'object') {
      this.anyChecked = true;
    }
    if (checkFilter === undefined) {
      this.anyChecked = false;
    }
    // console.log(">>>All check...", this.masterSelected, " - ", this.selectFilterTypes);
    this.searchByKey();
  }

  filterClick(theEvt: any) {
    if (theEvt) {
      //////console.log(">>>Event: ", theEvt, " :: ", theEvt.checked, " -- ", theEvt.source.value);
      ////////console.log("@Select Type: ", this.selectFilterTypes);
      if (theEvt.checked) {
        //console.log(">>>checked...");
        let getType = this.srchFilterOptions.find(rec => rec.value === theEvt.source.value);
        if (getType) {
          this.selectFilterTypes.push(getType.value);
        }
        //console.log(">>>checked...calling...");
        this.searchByKey();
      }
      if (!theEvt.checked) {
        //console.log(">>>not checked...", this.selectFilterTypes);
        this.masterSelected = false;
        let selectType = this.selectFilterTypes.findIndex(item => item == theEvt.source.value);
        this.selectFilterTypes.forEach((re, index) => {
          ////////console.log(re);
          if (re == theEvt.source.value) {
            ////////console.log("#Find Select Type index: ", index);
            this.selectFilterTypes.splice(index, 1);
          }
        })
        //console.log(">>>not checked...calling...", this.selectFilterTypes);
        this.searchByKey();
        ////////console.log("#Find Select Type: ", selectType);
        // if(selectType){
        //   //////////console.log('#Deleting type');
        //   this.selectFilterTypes.splice(0, 1);
        // }

      }
      ////console.log('checking chek/uncheck...');
      //check /uncheck checking
      let checkFilter = this.srchFilterOptions.find(item => item.isSelected === true);
      ////console.log('checking ..', checkFilter);
      if (checkFilter != undefined && typeof checkFilter === 'object') {
        this.anyChecked = true;
      }
      if (checkFilter === undefined) {
        this.anyChecked = false;
      }
      let countCheck = 0;
      this.srchFilterOptions.forEach((item, key) => {
        ////console.log('checking ..', item.isSelected, " :: ", item);
        if (item.isSelected) {
          countCheck++;
        }
      })
      ////console.log(">> checked length: ", countCheck, " :: ", this.srchFilterOptions.length);
      if (countCheck > 0 && countCheck == this.srchFilterOptions.length) {
        this.masterSelected = true;
      }
      // if(!this.masterSelected){
      //   this.anyChecked = false;
      // }
      ////////console.log("@Filter Select Type: ", this.selectFilterTypes);
    }
  }
  makeFAQLink(question: string, pathId: number) {
    let makeURL: string = 'support/faq-question-answer/';
    let findQ = this.faqAllItems.find(rec => rec.question === question);
    //////////console.log(">>>Link Find: ", findQ, " -- ", pathId);
    if (findQ && findQ.pathId == pathId) {
      //////////console.log("@@@Questin found...", findQ.catId, " -- ", pathId);
      makeURL += findQ.catId + '/' + pathId;
      return makeURL;
    } else {
      return 'javascript:void(0)';
    }
  }
  // title: string, pathId: any
  makeSentence = (theDesc: string, type: string, title?: string, pathId?: any) => {
    let string = theDesc.toString();
    let sentences: string = '';
    let sentences1 = string.replace(/\.(?!\d)|([^\d])\.(?=\d)/g, '$1.|');
    let getT = sentences1.split('|')[0];
    ////console.log("senns: ", getT, " -- ", type); 
    if (type === 'faq_page' && sentences1 != '') {
      let findQ = this.faqAllItems.find(rec => rec.pathId === pathId);
      if (findQ) {
        return title;
      }
    }
    if (type === 'poll_page') {
      return title;
    }
    sentences = string.replace(/\.(?!\d)|([^\d])\.(?=\d)/g, '$1.|');
    if (sentences.split('|').length >= 1) {
      ////console.log('>>> ', type);
      let ftext = sentences.split('|')[0];
      if (ftext != '' && ftext != '<br>') {
        return ftext;
      }
      //return sentences.split('|')[0];
    }
  }

  //paginate page change
  pageChanged(event) {
    this.pageCurrentNumber = event;
    this.pageConfigData.currentPage = event;
    this.searchByKey();
  }


  //Search action goes here #C6B8A4
  searchByKey() {
    let srchParamValue = this.srchString;
    //console.log(">>>sssss: ", this.selectFilterTypes, " -- ", this.masterSelected);
    // if(!this.anyChecked){
    //   return false;
    // }
    //this.pageCurrentNumber = 1;
    //let srchParamType   = 'keyword';
    this.srch_loader = true;
    ////////console.log("srch results: ", srchParamValue, " -- ", this.selectFilterTypes, " -- ", this.selectFilterTypes.length);
    this.srchPost['keyword'] = srchParamValue;
    this.srchPost['search_type_arr'] = (this.selectFilterTypes.length == 0) ? ((this.masterSelected) ? ["all"] : '') : this.selectFilterTypes;

    this.srchFilterOptions.forEach((item, index) => {
      item.count = 0;
    })

    this._service.post(this._constant.API.search_results, this.srchPost)
      .subscribe(
        res => {
          //console.log(">>>search results: ", res);
          let dataRes: any = res;
          let filterRes: any[] = [];
          this.srchResults = [];
          let tempCount: any = {};
          if (res['banner'].length > 0) {
            ////////console.log(">>>Enter....1")
            this.banner = this._constant.mediaPath + res['banner'][1]['banner']['image'];
            this.bannerTitle = res['banner'][1]['title'];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][1]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][1]['headerBanner'].length > 0) {
              ////////console.log(">>>Enter....2")
              res['banner'][1]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this._constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;//, delay: 0
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this._constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
            this.footerBanner = this._constant.mediaPath + res['banner'][1]['banner']['footer_image'];
            this.footerTitle = res['banner'][1].footer_title;
            this.footerDescription = res['banner'][1].footer_description;
          }


          //   tempCount = {
          //     "accr_service": 8,
          //     "pillar_page": 3,
          //     "page": 1,
          //     "about_eiac": 1,
          //     "recognition": 1,
          //     "bod": 11,
          //     "faq_page": 24,
          //     "poll_page": 5,
          //     "survey": 3,
          //     "training_course": 1
          // }
          //console.log("Temp: ", tempCount, " -- ", typeof tempCount);
          this.srchFilterOptions.forEach((item, index) => {
            // let tempVal = '';
            // tempVal = item.value;
            // if(item.value === 'accr_service'){
            //   //item.value = 'service_page';
            //   tempVal = 'service_page';
            // }

            var key;
            //console.log('>>> ', item);
            //console.log("data count: ", dataRes.count_res, " :: ", tempCount, " -- ", this.filterDirectoryCount);
            if (dataRes.count_res != undefined && typeof dataRes.count_res === 'object') {
              tempCount = dataRes.count_res;
            }
            for (key in tempCount) {
              let tval = tempCount[key]
              //console.log(tval," :: ", key);
              if (key === item.value) {
                item.count = tval;
              }
              if (key === 'directory') {
                this.filterDirectoryCount = tval;
              }
            }

            if (item.value === 'directory' && item.count == 0) {
              //console.log("no directory count...");
              item.count = this.filterDirectoryCount;
            }


            // let getCount: any = filterRes.find(recData => recData.page_type === tempVal);
            // //console.log(">>>Count: ", item.value, " :: ", getCount);
            // if(getCount){
            //   item.count = getCount.count_res;
            // }
          })

          if (res['data'].length > 0) {

            //this.srchResults = dataRes.data;
            ////console.log(dataRes.data);
            dataRes.data.forEach((rec, index) => {
              //let string = rec.description.toString();
              let textData = this._service.stripHTML(rec.description);//string.replace(/\.(?!\d)|([^\d])\.(?=\d)/g,'$1.');
              rec.description = textData;
              ////console.log("Text: ", textData);
              if (textData === '' || textData == null) {
                rec.description = rec.title;
              }
              filterRes.push(rec);
            });

            ////console.log("fILTER results...", filterRes, " -- ", filterRes.length);

            //return;
            this.srchResults = filterRes;
            this.length = this.srchResults.length;
            //this.allCount = this.length;
            this.pageTotal = this.srchResults.length;
            let allCountA = 0;
            this.srchFilterOptions.forEach(rec => {
              let count = rec.count;
              console.log("count: ", count, " :: ", rec);
              allCountA += count;
            })
            this.allCount = allCountA;
            console.log("all: ", allCountA, " -- ", this.allCount);
            //console.log("search results...", this.srchResults, " - ", this.srchResults.length);

            // this.length = dataRes.data.length;
            // this.pageTotal = dataRes.data.length;
            this.pageCurrentNumber = 1;
            // console.log("search results...", dataRes.data, " -- ", dataRes);
            // this.pageConfigData = {
            //   itemsPerPage: this.pageLimit,
            //   currentPage: this.pageCurrentNumber,
            //   totalItems: this.pageTotal
            // };
          }
          if (this.srchResults.length == 0) {
            //console.log('results....null');
            this.srchResults = [];
            let allCountA = 0;
            this.srchFilterOptions.forEach(rec => {
              let count = rec.count;
              console.log("count: ", count, " :: ", rec);
              allCountA += count;
            })
            this.allCount = allCountA;
            console.log("all: ", allCountA, " -- ", this.allCount);
          }

          this.srch_loader = false;
          // if(dataRes.banner){

          // }else{

          // }

        });

    /*this._service.getReq(this._constant.API.search_results, srchParamType,srchParamValue)
    .subscribe(
      data =>{
            let result: any = data;
            if(result != undefined && typeof result === 'object'){
                 if(result.response === 'success'){
                    this.srch_loader = false;
                    this.srchResults = result.data;
                    
                    this.pageTotal = result.data.length;
                    this.pageConfigData = {
                      itemsPerPage: this.pageLimit,
                      currentPage: this.pageCurrentNumber,
                      totalItems: this.pageTotal
                    };
                    // setTimeout(() => {
                    //   this.highlightText(this.srchString);
                    // }, 100);
                    //////////console.log("search: ", this.srchResults);
                 }else{
                  this.srch_loader = false;
                 }
            }
      });*/
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

}
