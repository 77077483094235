import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UiDialogService } from 'src/app/services/uiDialog.service';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
  providers: [UiDialogService]
})
export class ValidationMessagesComponent {

  @Input() errorMessages: any;
  @Output() errorHandle   = new EventEmitter();

  constructor(public uiDialog: UiDialogService) { 
    ////console.log('@@@Valid');
  }

  ngOnInit() {
     ////console.log("Valid comp...", this.errorMessages);
  }
  showValidationError(){
    ////console.log("@@@Valid comp...", this.errorMessages);
  }
}
