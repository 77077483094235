import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Http, Headers } from "@angular/http";
import { TranslateService } from "@ngx-translate/core";
import { from, Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import {
  VERSION,
  MatDialogRef,
  MatDialog,
  MatDatepicker,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
//import {HappinessMeterComponent} from '../pages/common-all/happiness-meter/happiness-meter.component';
import { Observable } from "rxjs";

@Injectable()
export class AppService {
  currentlatitude: any;
  //service.eiac.gov.ae/webservice
  public apiServerUrl = "https://eiac.gov.ae/";

  public countryURL =
    "https://raw.githubusercontent.com/sagarshirbhate/Country-State-City-Database/master/Contries.json";
  public localCountryURL = "assets/country-local.json";
  //public subjectValidError    =    new Rx.BehaviorSubject('');
  // public mapboxToken          =    'pk.eyJ1IjoicHJpbmF2IiwiYSI6ImNrNmh4YXVpcTJwbnMzbm4zYTc1ZG5kbHIifQ.lEliOwWLfcau6c0McnkGUA';
  //public mapboxToken          =    'pk.eyJ1IjoicHJpbmF2IiwiYSI6ImNrNmh4YXVpcTJwbnMzbm4zYTc1ZG5kbHIifQ.lEliOwWLfcau6c0McnkGUA';

  // public assetsBasePath       =   "https://localhost:4200/assets/csc-json/";

  public assetsBasePath = "https://eiac.gov.ae/assets/csc-json/";
  public portalPath = "https://portal.eiac.gov.ae/login";
  public mapboxToken = "";

  //public dutyTime = [{"key":'00:00:00',"value":'00:00'},{"key":'00:30:00',"value":'00:30'},{"key":'01:00:00',"value":'01:00'},{"key":'01:30:00',"value":'01:30'}];

  public regExName: any;
  public regExUrl: any;
  public regExCont: any;
  public regExNumb: any;
  public regExEmail: any;
  constructor(
    public http: HttpClient,
    public translate: TranslateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    //initilize input type regex
    this.regExName = /^[a-zA-Z\s]*$/;
    this.regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    this.regExCont = /^[a-zA-Z0-9,.:@#$%^&*!\s]*$/;
    this.regExNumb = /^[0-9]*$/;
    this.regExUrl = "/(w*W*)?w*(.(w)+)+(Wd+)?(/w*(W*w)*)*/";
  }

  //Filter text input inject
  inputFilter1(e: any) {
    //By ASCII
    var k;
    document.all ? (k = e.keyCode) : (k = e.which);
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  inputFilter(e: any, regType?: any) {
    //console.log('@ ',e, " - ", regType)
    //By Regular expression
    try {
      let regEx =
        regType === undefined
          ? new RegExp(this.regExCont)
          : new RegExp(regType);
      if (regEx.test(e.key)) {
        //console.log('test true');
        return true;
      } else {
        //console.log('test false');
        e.preventDefault();
        return false;
      }
    } catch (ex) {
      //console.log('Exception: ',ex);
    }
  }
  stripHTML(theText: string, ...allowTags: any[]) {
    let stripText: string = "";
    if (theText != undefined) {
      if (allowTags.length > 0) {
        stripText = theText
          .toString()
          .replace(
            new RegExp(`<(?!\/?(${allowTags.join("|")})\s*\/?)[^>]+>`, "g"),
            ""
          );
      } else {
        stripText = theText.toString().replace(/<[^>]+>/g, "");
      }
    }
    return stripText;
  }
  returnCurrentDate() {
    return new Date();
  }

  allCountries(): Observable<any> {
    //this.countryURL
    return this.http.get(this.localCountryURL);
  }

  isObjectEmpty(Obj) {
    for (var key in Obj) {
      //console.log(key, " -- ", Obj);
      if (Obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  //Open Calendar by Click
  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
    //setTimeout(() => this._input.nativeElement().focus()); //By focus if needed
  }

  getCountryCity() {
    var countryCityURL = this.assetsBasePath + "countries-city.json";
    return this.http.get(countryCityURL);
  }

  getCountry() {
    var countryURL = this.assetsBasePath + "countries.json";
    //var cscObj = new csc();
    //let countryCCC = this.objCountry.getAllCountries();
    return this.http.get(countryURL);
  }

  getState() {
    var stateURL = this.assetsBasePath + "states.json";
    return this.http.get(stateURL);
  }

  getCity() {
    var cityURL = this.assetsBasePath + "cities.json";
    return this.http.get(cityURL);
  }

  addMinutesToTime() {
    var x = 30; //minutes interval
    var timesVal = []; // time array
    var timesKey = []; // time array
    var tt = 0; // start time
    var ap = [" AM", " PM"]; // AM-PM
    var newdutyTimeArr = [];

    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      timesKey[i] =
        ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2) + ":00"; // pushing data in array in [00:00 - 12:00 AM/PM format]
      timesVal[i] =
        ("0" + (hh % 12)).slice(-2) +
        ":" +
        ("0" + mm).slice(-2) +
        ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
      newdutyTimeArr.push({ key: timesKey[i], val: timesVal[i] });
    }

    return newdutyTimeArr;
  }

  /*********************************************
   * @checkInput
   * @param:
   * inputParamas:       Type of validation
   * modelInput:         model data value
   * Master Check Input function
   *
   *********************************************/
  allowCharacterAndDigitsOnly(e) {
    var k;
    document.all ? (k = e.keyCode) : (k = e.which);
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  IsNumeric(e) {
    var keyCode = e.which ? e.which : e.keyCode;
    var ret = (keyCode >= 48 && keyCode <= 57) || keyCode == 43;
    return ret;
  }
  isInArray(value, array) {
    return array.indexOf(value) > -1;
  }
  checkInput(inputParamas: string, modelInput: any) {
    var resultValidInput = false;

    switch (inputParamas) {
      case "email":
        resultValidInput = new RegExp(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        ).test(modelInput);
        break;

      case "onlyName":
        resultValidInput = new RegExp(/^[a-z ]+$/).test(modelInput);
        break;

      case "onlyNumberWithPlus":
        resultValidInput = new RegExp(/^[+-]?\d+$/).test(modelInput);
        break;

      case "onlyNumber":
        resultValidInput = new RegExp(/^[0-9]+$/).test(modelInput);
        break;

      case "blank":
        resultValidInput = new RegExp(/^[a-zA-Z ]*$/).test(modelInput);
        break;

      case "url":
        resultValidInput = new RegExp(
          /(\w*\W*)?\w*(\.(\w)+)+(\W\d+)?(\/\w*(\W*\w)*)*/
        ).test(modelInput);
        break;
    }
    return resultValidInput ? true : false;
  }

  post(url: string, body: any) {
    //console.log('======');
    //console.log(body)
    //console.log('======');
    return this.http.post(url, body);
  }

  // post(url: string, body: any) {
  //   let headers = new HttpHeaders({'Content-Type':'application/json', 'Access-Control-Allow-Origin': '*'});
  //   return this.http.post(url, body, {headers: headers});
  //   }
  get(url: string, param?) {
    let params = new HttpParams().set("data", param);
    // let headers = new HttpHeaders({'Content-Type':'application/json', 'Access-Control-Allow-Origin': '*'});
    // let httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*'
    //   }),
    //   params: new HttpParams().set('data', param)
    // };
    //get request CORS
    return this.http.get(url, { params: params });
  }

  getWithMultipleParams(url: string, param?, param2?) {
    const params = new HttpParams()
      .set("data", param)
      .set("language_id", param2);

    return this.http.get(url, { params });
  }

  getWithMultipleParamsHome(url: string, param?, param2?) {
    const params = new HttpParams()
      .set("status", param)
      .set("language_id", param2);

    return this.http.get(url, { params });
  }

  getReq(url: string, paramType, paramValue) {
    let params: any;
    if (paramType != "" && paramValue != "") {
      params = new HttpParams().set(paramType, paramValue);
      return this.http.get(url, { params: params });
    }
    return this.http.get(url);
  }

  getwithoutData(url: string) {
    let params = new HttpParams();
    return this.http.get(url);
  }
  delete() {}
  put(url: string, body: any) {
    return this.http.put(url, body);
  }

  public isValidEmail(email: string) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  public getTranslateValueByKey(keyPath: string): string {
    let retMesag = "";
    const title = this.translate.get(keyPath).subscribe((text: string) => {
      if (text != null) {
        retMesag = text;
      }
    });
    ////console.log('returning tetxt: ', retMesag);
    return retMesag;
  }
  public numberSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  public createLogin(post: any) {
    let body = post;
    let url = this.apiServerUrl + "login";
    return this.http.post<any>(url, body);
  }
  public resetPassword(post: any) {
    let body = post;
    let url = this.apiServerUrl + "forgot-password";
    return this.http.post<any>(url, body);
  }
  public resetChangePassword(post: any) {
    let body = post;
    let url = this.apiServerUrl + "reset-password";
    return this.http.post<any>(url, body);
  }
  //Make all key value of any object set empty
  public objectKeyValueEmpty(object: any) {
    //console.log('Get object: ', object);
    if (object != null) {
      object.forEach((rec, k) => {
        //console.log(rec[k]);
      });
    }
    // let filterObject = object.filter(record => {
    //    //console.log(record);
    // })
    //(object, val) => Object.keys(object).forEach(k => object[k] = '');
    ////console.log(filterObject);
    //return filterObject;
  }
  public objectToArrayValue(getObject: any) {
    let filteredArray = [];
    for (var prop in getObject) {
      filteredArray.push(getObject[prop].errorMessage);
    }
    return filteredArray;
  }
  public removeDuplicateProp(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  inputEvent(event, key, obj) {
    var dateString = event.value._d;
    var d = new Date(dateString);
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    var convertedDate = yy + "-" + mm + "-" + dd;

    //var convertedDate = dateString.toLocaleDateString();
    delete obj[key]["certificate_expiry_date"];
    obj[key]["converted_date"] = convertedDate;
    return obj;
  }

  inputEventForSecond(event, key, obj, inpuyBoxName = "") {
    var dateString = event.value._d;
    var d = new Date(dateString);
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    var convertedDate = yy + "-" + mm + "-" + dd;

    //var convertedDate = dateString.toLocaleDateString();
    if (inpuyBoxName == "initial_date") {
      delete obj[key]["initial_date"];
      obj[key]["initial_date_formatted"] = convertedDate;
    } else {
      delete obj[key]["certificate_expiry_date_second"];
      obj[key]["converted_date_second"] = convertedDate;
    }
    return obj;
  }

  // openHappinessIndigatorDialog(obj: any, index: number) {
  //   const dialogRef = this.dialog.open(HappinessMeterComponent,{
  //     data:{
  //       message: 'Are you sure want to delete?',
  //       buttonText: {
  //         ok: 'Yes',
  //         cancel: 'No'
  //       },
  //       obj:obj,
  //       index:index,
  //     }
  //   });
  // }
}
