import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Title, Meta } from '@angular/platform-browser';
import { UiDialogService } from '../../../services/uiDialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { EnquiryDialogboxComponent } from '../../../pages/common-all/enquiry-dialogbox/enquiry-dialogbox.component';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RecaptchaComponent } from 'ng-recaptcha';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
  providers: [AppService, Constants, UiDialogService]
})

export class ServiceComponent implements OnInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  flag: any;
  flagURL: string;
  public loader: boolean = true;
  public serviceList: any = [];
  public service_steps: any = [];
  public service_requirements: any = [];
  public service_provider: any = [];
  public service_channel: any = [];
  public banner: any = [];
  public bannerData: any = [];

  bannerURL: any = '';
  bannerLinkTarget: string = '';
  bannerTitle: string = '';

  langData: any;

  @Output() onLoad = new EventEmitter();

  public schemeSlideData: any = {};
  public enquiryForm: any = {};
  public is_submit: boolean = false;
  public checkSecurity: boolean = false;
  public checkCaptchaValidation: boolean = false;
  suffixEnquiryFormurl: any;
  serviceDescriptionImage: any;
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  arabicTitle: any;
  englishTitle: any;
  version = VERSION;
  afterSubmit: any;
  bannerSlider: any[] = [];
  bannerArabicTitle: any;
  pageData: any;

  @ViewChild('targetServDesc', null) targetServDesc: ElementRef;
  @ViewChild('targetEnqForm', null) targetEnqForm: ElementRef;
  @ViewChild('captchaRef', { static: true }) captchaRef: RecaptchaComponent;
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, private meta: Meta, public route: ActivatedRoute, public titleService: Title,
    public uiDialog: UiDialogService,
    public router: Router, public Service: AppService, public constant: Constants, public sanitizer: DomSanitizer, public toastr: ToastrService, public http: HttpClient, public dialog: MatDialog) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {

    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');

    console.log("@change:11 ", this.language_id);


    this.flag = this.route.snapshot.paramMap.get("flag");
    if (this.flag == 'certification-bodies') {
      this.suffixEnquiryFormurl = 'certification_bodies/accreditation_enquiry_mail';
      this.enquiryForm.enquiry_type = 'certification_bodies';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Certification Bodies' });
      this.meta.updateTag({ name: 'description', content: 'Apply for Accreditation of CAB Service, Approve Quotation & Sign Agreement, CAB Upload the Required Documents, Assessment Schedule, Conduct Assessment, Receive Accreditation Certificate, Receive Accreditation Cycle' });
      this.meta.updateTag({ name: 'keywords', content: 'Pre-application, Application, Assessment, Reporting on Assessment & Accreditation Decision, Post Accreditation Compliance' });

    } else if (this.flag == 'testing-calibration') {
      this.suffixEnquiryFormurl = 'testing_calibration/accreditation_enquiry_mail';
      this.enquiryForm.enquiry_type = 'testings_calibration';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Testing and Calibraton laboratories' });
      this.meta.updateTag({ name: 'description', content: 'Apply for Accreditation of CAB Service, Approve Quotation & Sign Agreement, CAB Upload the Required Documents, Assessment Schedule, Conduct Assessment, Receive Accreditation Certificate, Receive Accreditation Cycle' });
      this.meta.updateTag({ name: 'keywords', content: 'Pre-application, Application, Assessment, Reporting on Assessment & Accreditation Decision, Post Accreditation Compliance' });

    } else if (this.flag == 'pt-providers') {
      this.suffixEnquiryFormurl = 'pt_providers/accreditation_enquiry_mail';
      this.enquiryForm.enquiry_type = 'pt_provider';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Proficiency Testing Providers' });
      this.meta.updateTag({ name: 'description', content: 'Apply for Accreditation of CAB Service, Approve Quotation & Sign Agreement, CAB Upload the Required Documents, Assessment Schedule, Conduct Assessment, Receive Accreditation Certificate, Receive Accreditation Cycle' });
      this.meta.updateTag({ name: 'keywords', content: 'Pre-application, Application, Assessment, Reporting on Assessment & Accreditation Decision, Remain Accredited' });

    } else if (this.flag == 'halal-conformity') {
      this.suffixEnquiryFormurl = 'halal_conformity_bodies/accreditation_enquiry_mail';
      this.enquiryForm.enquiry_type = 'halal_conformity_bodies';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Halal Conformity Bodies' });
      this.meta.updateTag({ name: 'description', content: 'Apply for Accreditation of CAB Service, Approve Quotation & Sign Agreement, CAB Upload the Required Documents, Assessment Schedule, Conduct Assessment, Receive Accreditation Certificate, Receive Accreditation Cycle' });
      this.meta.updateTag({ name: 'keywords', content: 'Pre-application, Application, Assessment, Reporting on Assessment & Accreditation Decision, Post Accreditation Compliance' });

    } else if (this.flag == 'health-care') {
      this.suffixEnquiryFormurl = 'health_care/accreditation_enquiry_mail';
      this.enquiryForm.enquiry_type = 'healthcare';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Healthcare and Medical laboratories' });
      this.meta.updateTag({ name: 'description', content: 'Apply for Accreditation of CAB Service, Approve Quotation & Sign Agreement, CAB Upload the Required Documents, Assessment Schedule, Conduct Assessment, Receive Accreditation Certificate, Receive Accreditation Cycle' });
      this.meta.updateTag({ name: 'keywords', content: 'Pre-application, Application, Assessment, Reporting on Assessment & Accreditation Decision, Post Accreditation Compliance' });

    } else if (this.flag == 'inspection-bodies') {
      this.suffixEnquiryFormurl = 'inspection_body/accreditation_enquiry_mail';
      this.enquiryForm.enquiry_type = 'inspection_bodies';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Inspection Bodies' });
      this.meta.updateTag({ name: 'description', content: 'Apply for Accreditation of CAB Service, Approve Quotation & Sign Agreement, CAB Upload the Required Documents, Assessment Schedule, Conduct Assessment, Receive Accreditation Certificate, Receive Accreditation Cycle' });
      this.meta.updateTag({ name: 'keywords', content: 'Pre-application, Application, Assessment, Reporting on Assessment & Accreditation Decision, Post Accreditation Compliance' });

    } else if (this.flag == 'no-objection-certificate') {
      this.suffixEnquiryFormurl = 'noc/registration_enquiry_mail ';
      this.enquiryForm.enquiry_type = 'no_objection_certificate';

      this.meta.updateTag({ name: 'title', content: 'EIAC - No Objection Certificate' });
      this.meta.updateTag({ name: 'description', content: 'Apply for no objection certificate, Receive no objection certificate' });
      this.meta.updateTag({ name: 'keywords', content: 'Dubai Economic Department, Application, Receive NOC' });

    } else if (this.flag == 'work-permit') {
      this.suffixEnquiryFormurl = 'work_permit/registration_enquiry_mail';
      this.enquiryForm.enquiry_type = 'work_permit';

      this.meta.updateTag({ name: 'title', content: 'EIAC - Work permit' });
      this.meta.updateTag({ name: 'description', content: 'Application submission, Document Review, Audit Visit, Report & Decision' });
      this.meta.updateTag({ name: 'keywords', content: 'Application submission, Document Review, Audit Visit, Report & Decision' });

    } else if (this.flag == 'public-training') {
      this.suffixEnquiryFormurl = 'public_training/training_enquiry_maill';
    } else if (this.flag == 'in-premise') {
      this.suffixEnquiryFormurl = 'in_premise/training_enquiry_mail';
    }
    this.flagURL = this.flag.split('_').join('-') + '-form';
    console.log(this.flagURL);
    this.getIPAddress();
    this.loadService();


    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        console.log("@change:22 ", this.language_id);
        this.loadService();
      });
    ////console.log('@Subject input example...'); 
    //subject example
    // let subject = new Subject<any>();
    // // subject.next("First Value");
    // // subject.next("Second Value");
    // // subject.next("Third Value");
    // subject.subscribe((data) => {
    //   ////console.log("Subscriber data >>>>> "+ data);
    // });

    // subject.next("First Value");
    // subject.next("Second Value");
    // subject.next("Third Value");

  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  scrollForm(type: string) { 
    ////console.log('calling scroll...');
    //el.scrollIntoView({behavior: 'smooth'});
    switch (type) {
      case 'targetServDesc':
        this.targetServDesc.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 'targetEnqForm':
        this.targetEnqForm.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
    }
  }

  openForm(meta: string){
    console.log("@Meta: ", meta);
    let getURL: string = '';
    switch(meta){

      case 'inspection-bodies':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/inspection-bodies-form';
        console.log("@URL: ", getURL, " -- ", this.constant.portal_SERV);
        window.open(getURL)
      break;
      case 'testing-calibration':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/testing-calibration-form';
        window.open(getURL)
      break;
      case 'certification-bodies':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/certification-bodies-form';
        window.open(getURL)
      break;
      case 'health-care':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/health-care-form';
        window.open(getURL)
      break;
      case 'halal-conformity':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/halal-conformity-form';
        window.open(getURL)
      break;
      case 'pt-providers':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/pt-providers-form';
        window.open(getURL)
      break;
      case 'no-objection-certificate':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/no-objection-certificate-form';
        window.open(getURL)
      break;
      case 'work-permit':
        getURL = this.constant.portal_SERV + 'dashboard/cab_client/work-permit-form';
        window.open(getURL)
      break; 

      default:
      break;
    }

  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.enquiryForm.ip_address = res.ip;
    });
  }

  onSubmit(ngForm) {
    ////console.log(this.flag,'flag');
    //this.postPoll.emit();
    // if(this.checkSecurity == true)
    // {
    //   this.checkCaptchaValidation = true;
    // }else{
    //   this.checkCaptchaValidation = false;
    // }
    this.is_submit = true;

    if (ngForm.form.valid && this.checkCaptchaValidation == true) {
      this.afterSubmit = true;
      this.Service.post(this.constant.API.enquiry_form + this.suffixEnquiryFormurl, this.enquiryForm)
        .subscribe(
          res => {
            if (res['status'] == true) {
              this.is_submit = false;
              // this.checkCaptchaValidation = false;
              // this.enquiryForm= {};
              this.enquiryForm.name = '';
              this.enquiryForm.email = '';
              this.enquiryForm.comments = '';
              this.enquiryForm.phone = '';
              this.captchaRef.reset();
              this.checkCaptchaValidation = false;

              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  message: 'Mail sent successfully.',
                  success: '1',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this,
                  index: '',
                }
              });
            } else {
              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  success: '0',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this
                }
              });
            }


            // this.toastr.success(res['msg'], '');
          },
          error => {

          })
    }

  }

  ngAfterViewInit() {
    let p = new Promise((resolve, reject) => {
    });
    //console.log("setting up");
    p
      .then(r => {
      })
      .catch(e => {
      })
  }

  getRevalidatedSubmit() {
    this.afterSubmit = false;
  }

  loadService() {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.Service.getWithMultipleParams(this.constant.API.all_services, this.flag, this.language_id)
      .subscribe(
        res => {
          //////console.log(res);

          console.log("Service Data:", res);
          this.langData = {laguage: this.language_id};
          console.log("Lang Data:", this.langData);

          this.titleService.setTitle("EIAC - " + res['data'][0]['title']);
          this.serviceList = res['data'][0]
          this.serviceDescriptionImage = res['data'][0]['service_page'].image;
          // if(res['banner'].length>0){
          //   ////console.log(res['banner'])
          //   this.bannerData = res['banner']
          //   this.banner = this.constant.mediaPath+res['banner'][0]['banner']['image'];

          //   this.bannerTitle = (res['banner'][0]['banner']['image_name'] != null) ? res['banner'][0]['banner']['image_name'] : '';
          //   this.bannerURL = (res['banner'][0]['banner']['url'] != '') ? this.getSantizeUrl(res['banner'][0]['banner']['url']) : '';
          //   this.bannerLinkTarget = (res['banner'][0]['banner']['url'] != null) ? '_blank' : '_self';

          //   this.arabicTitle = res['banner'][0]['title'];
          //   this.englishTitle = res['banner'][1]['title'];
          //   this.footerBanner = this.constant.mediaPath+res['banner'][0]['banner']['footer_image'];
          //   this.footerTitle = res['banner'][1].footer_title;
          //   this.footerDescription = res['banner'][1].footer_description;
          // }

          if (res['banner'].length > 0) {
            this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            this.bannerTitle = res['banner'][select_language]['title'];
            this.pageData = res['banner'][select_language];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][select_language]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][select_language]['headerBanner'].length > 0) {
              res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }

          this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
          this.footerTitle = res['banner'][select_language].footer_title;
          this.footerDescription = res['banner'][select_language].footer_description;

          
          
          this.service_steps = JSON.parse(this.serviceList.service_steps)          
          this.service_requirements = JSON.parse(this.serviceList.service_requirements)
          this.service_provider = JSON.parse(this.serviceList.service_provider)
          var servicesChannel = ["موقع الكتروني", "المكتب الرئيسي", "البريد الإلكتروني"];
          this.service_channel = this.language_id == '1' ? Object.values(JSON.parse(this.serviceList.service_channel)) : servicesChannel;
          if (res['schemeData'].length > 0) {
            this.schemeSlideData = res['schemeData'];
            this.schemeSlideData['image'] = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            //console.log("schedata passing: ", this.schemeSlideData);
            //this.onLoad.emit({value: res['schemeData']});
          } else {
            this.schemeSlideData = [];
          }

          this.loader = false;

        },
        error => {

        })
  }

  afterChange(theEvt: any) {
    ////console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      ////console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        ////console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          ////console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        ////console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        ////console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            ////console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          ////console.log('slider start....');
        }, sleep)
      }
    }

  }

  resolvedSecurity(captchaResponse: string) {
    let captchaStatus = captchaResponse;
    if (captchaStatus != '') {
      this.checkSecurity = true;
      this.checkCaptchaValidation = true;
    }
  }
}
