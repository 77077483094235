import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { empty as observableEmpty, Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { FooterComponent } from '../../../partial/component/footer/footer.component';
import { UiDialogService } from '../../../../services/uiDialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-service',
  templateUrl: './training-service.component.html',
  styleUrls: ['./training-service.component.scss'],
  providers: [AppService, Constants, UiDialogService]
})
export class TrainingServiceComponent implements OnInit {

  SubscribeCompanyChange: Subscription;
  language_id: any;
  // slideConfig = {"slidesToShow": 2, "slidesToScroll": 2};
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: false,
  };
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 2,
    "dots": true,
    "infinite": true,
    "nextArrow": "<div class='nav-btn next-slide'><span class=icon-right-arrow></span></div>",
    "prevArrow": "<div class='nav-btn prev-slide'><span class=icon-left-arrow></span></div>",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  // 
  //
  /*
slideConfig = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 1500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      };
      next() {
        this.slickModal.slickNext();
      }
      prev() {
        this.slickModal.slickPrev();
      }

  */

  public trainingServicesListForEvents: any = [];
  public trainingServicesListForTeachers: any = [];
  public trainingServicesListForStudents: any = [];
  public subscriptions: Subscription[] = [];
  public dataLoading: boolean = null;
  public loader: boolean = true;
  public pageList: any = [];
  public service_steps: any = [];
  public service_requirements: any = {};
  public service_provider: any = [];
  public service_channel: any = [];
  trainingListbyCategory: any = [];
  rowCount: any = 1;
  totalRow: any = 1;
  allTrainingEvents: any;
  courseArrLength: any;
  trainingTitle: any;
  trainingDescription: any;
  otherDescription: any;
  otherDescriptionTitle: any;
  otherDescriptiondescription: any;
  records: any;
  records_year: any;
  records_types: any;
  records_cabs: any;
  records_certificates: any;
  allCourceDetails: any;
  serviceDescriptionImage: any;
  public banner: any = [];
  bannerURL: any = [];
  bannerLinkTarget: string = '';
  bannerTitle: string = '';
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  arabicTitle: any;
  englishTitle: any;

  allPageData: any;
  slideCount = 0;
  bannerSlider: any[] = [];
  bannerArabicTitle: any;
  courselistbyTypes: any;
  pageData: any;

  loadmoreTraining: any = { '0': 1, '1': 1, '2': 1, '3': 1, '4': 1, '5': 1, '6': 1, '7': 1, '8': 1, '9': 1, '10': 1, '11': 1, '12': 1, '13': 1, '14': 1 };

  @ViewChild(FooterComponent, { static: false }) child: FooterComponent;
  @ViewChild('targetServDesc', null) targetServDesc: ElementRef;

  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, private meta: Meta, public appService: AppService, public uiDialog: UiDialogService, public constant: Constants,
    public titleService: Title, public sanitizer: DomSanitizer, public router: Router) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {
    this.titleService.setTitle('EIAC - TRAINING SERVICES');
    this.meta.updateTag({ name: 'title', content: 'EIAC - Training Service' });
    this.meta.updateTag({ name: 'description', content: 'Choose Suitable Type of Training service, Attend the Public or In Premise Training, Have an EIAC Training Certificate issued to you' });
    this.meta.updateTag({ name: 'keywords', content: 'Comprehensive Training' });


    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.loadBOD();
      }
    );
    this.loadBOD();

  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
}

  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            //console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep)
      }
    }

  }

  nextSlide() {
    //let dots = Math.ceil(totLen / 2);
    //this.slideCount+=2;
    //console.log("next: ", this.slideCount, " -- ", dots);
    // console.log("prev: ", this.slickModal);
    this.slickModal.slickNext();
    //this.slickModal.slickGoTo(totLen);
  }
  prevSlide() {
    // console.log("prev: ", this.slickModal);
    this.slickModal.slickPrev();
  }

  addSlide() {
    // this.slides.push({img: "http://placehold.it/350x150/777777"})
  }

  removeSlide() {
    // this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    // console.log('slick initialized: ', e);
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }

  fixedNumberLoop(n: number): any[] {
    return Array(n);
  }

  openHappiness() {
    //console.log('sending...trainig...');
    //let openStatus: any = {open: true}
    //this.onOpen.emit(openStatus);
    this.child.initOpen('Test');
  }

  loadmoreTrainingList(show_count, index) {

    this.loadmoreTraining[index] = parseInt(this.loadmoreTraining[index]) + parseInt(show_count);
    //console.log(this.loadmoreTraining);
    var fullCourseDetails = this.allCourceDetails;
    for (let key in fullCourseDetails) {
      if (key == index) {
        this.trainingListbyCategory[index]['course'] = [];
        for (let i = 0; i <= 3 * this.loadmoreTraining[index]; i++) {
          // tempObj['course'] = fullCourseDetails[key]['course'][i];
          if (fullCourseDetails[index]['course'][i]) {
            this.trainingListbyCategory[index]['course'].push(fullCourseDetails[index]['course'][i]);
          }
        }

      }
    }
    //console.log(this.trainingListbyCategory);
    this.loader = false;
  }

  loadBOD() {
    let select_language = this.language_id == 1 ? 1 : 0;
    let trainingService = this.constant.trainingService;
    this.dataLoading = true;
    this.subscriptions.push(
      this.appService.get(trainingService, this.language_id)
        .subscribe(
          result => {
            this.trainingServicesListForEvents = [];
            this.allTrainingEvents = [];
            let dataObject: any = [];
            this.allTrainingEvents = result['allEventData'];
            this.courseArrLength = Object.keys(this.allTrainingEvents).length;

            for (let i = 0; i <= this.rowCount * 2; i++) {
              if (this.allTrainingEvents[i]) {
                this.trainingServicesListForEvents.push(this.allTrainingEvents[i]);
              }
            }
            /*
            console.log("Before traverse: ", this.trainingServicesListForEvents);
            //Trversing data virtual key date
            this.trainingServicesListForEvents.forEach((rec, key) => {
                    let sortDate: any = '';
                    if(rec.eventDates != null && rec.eventDates.length > 0){
                      sortDate = rec.eventDates[0].event_date;
                    }
                    if(sortDate != ''){
                      this.trainingServicesListForEvents[key].sortDate = sortDate;
                    }                      
            })
            console.log("After traverse: ", this.trainingServicesListForEvents);
            this.trainingServicesListForEvents.sort((a, b) => (a.sortDate < b.sortDate) ? 1 : -1);
            console.log("@Trainign event After Sort: ", this.trainingServicesListForEvents);
            */
            this.trainingServicesListForTeachers = result['teacher'];
            this.trainingServicesListForStudents = result['student'];
            // if(dataObject.status = 200 && dataObject.records.length > 0){
            //   //console.log('BOD records: ', dataObject);
            //   this.dataLoading = false;
            //   this.bodList = dataObject.records;
            // }
            this.loader = false;
          },
          error => {
          })
    );

    this.appService.get(this.constant.API.training_course_type, this.language_id).subscribe(
      res => {
        this.courselistbyTypes = res['course_type'];
        
      }
    )
    // this.appService.getwithoutData(this.constant.API.training_service_course)
    // .subscribe(
    //   res => {
    //     // this.trainingListbyCategory = res['fullCourseDetails'];
    //     // this.trainingListbyCategory = '';
    //     this.allCourceDetails = res['fullCourseDetails'];
    //     var fullCourseDetails = res['fullCourseDetails'];
    //     var targated_aud = [];
    //     for(let key in fullCourseDetails){
    //       let tempObj = {};

    //       tempObj['targated_aud'] = '';
    //       tempObj['course'] = [];

    //       tempObj['targated_aud'] = fullCourseDetails[key].cab_type;
    //       for(let key2 in fullCourseDetails[key]['course']){
    //         if(fullCourseDetails[key]['course'][key2].course != null){
    //           tempObj['course'].push(fullCourseDetails[key]['course'][key2]);
    //         }
    //       }
    //       tempObj['courseSliderLength'] = Math.ceil(fullCourseDetails[key]['course'].length/2);

    //       this.trainingListbyCategory.push(tempObj);  
    //     }
    //     // this.trainingListbyCategory.push(targated_aud);
    //     // console.log(this.trainingListbyCategory,'trainingListbyCategory');
    //     this.loader = false;
    //   },
    //   error => {

    //   })

    this.appService.getWithMultipleParams(this.constant.API.service_details_page, 'training_service', this.language_id)
      .subscribe(
        res => {
          if (res['banner'].length > 0) {
            this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            this.bannerTitle = res['banner'][select_language]['title'];
            this.bannerArabicTitle = res['banner'][0]['title'];
            this.pageData = res['banner'][select_language];

            if (res['banner'][select_language]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][select_language]['headerBanner'].length > 0) {
              res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }

          this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
          this.footerTitle = res['banner'][select_language].footer_title;
          this.footerDescription = res['banner'][select_language].footer_description;

          this.pageList = res['data'][0];

          this.allPageData = this.pageList;
          this.serviceDescriptionImage = res['data'][0]['service_page'].image;
          this.trainingTitle = this.pageList.title;
          this.trainingDescription = this.pageList.service_description;
          // this.service_steps = JSON.parse(this.pageList.service_steps);
          if(this.language_id == '1') {
            this.service_steps = JSON.parse(this.pageList.service_steps);
          }else{
            this.service_steps = JSON.parse(this.pageList.service_steps);
            this.service_steps.forEach((res,key) => {
              if(res['step'] == 'Step 1') {
                this.service_steps[key]['step'] = 'الخطوة 1';
              }else if(res['step'] == 'Step 2') {
                this.service_steps[key]['step'] = 'الخطوة 2';
              }else if(res['step'] == 'Step 3') {
                this.service_steps[key]['step'] = 'الخطوة 3';
              }
              
              // this.service_steps[key]['step'] = this.service_steps[key]['step'] == 'Step 2' ? 'الخطوة 2' : '';
              // this.service_steps[key]['step'] = this.service_steps[key]['step'] == 'Step 3' ? 'الخطوة 3' : '';
            });
            // console.log(this.service_steps,'service_steps');
          }
          this.otherDescription = JSON.parse(this.pageList.other_description);
          this.otherDescriptionTitle = this.otherDescription.title;
          this.otherDescriptiondescription = this.otherDescription.description;
          this.service_requirements = JSON.parse(this.pageList.service_requirements);
          //console.log('@ ', this.service_requirements);
          this.records = JSON.parse(res['records']);
          this.records_year = res['years'] > 0 ? res['years'] : 0;
          this.records_types = this.records.types;
          this.records_cabs = this.records.cabs;
          this.records_certificates = this.records.certificates;
        },
        error => {

        })
  }

  convertObjectToArray(persons) {
    var resultArray = Object.keys(persons).map(function (personNamedIndex) {
      let person = persons[personNamedIndex];
      // do something with person
      // console.log(person);
      return person;
    });
  }

  courseSliderArray(dataArr: any) {
    // console.log(dataArr.length,'DataCount');
    let tempArr = {};
    let count = 0;
    let countIndex = 0;
    tempArr[countIndex] = [];
    for (var key in dataArr) {
      // console.log(count," - ",countIndex ," :: ", key, " -> ", (count%2));
      if (count % 2 == 0 && count != 0) {
        // console.log("increm : ", count);
        countIndex++;
        tempArr[countIndex] = [];
        tempArr[countIndex].push(dataArr[count]);
      } else {
        tempArr[countIndex].push(dataArr[count]);
      }
      count++;
    }
    return tempArr;
  }

  loadmoreAud(aud_id = '', courseid) {
    var clickf = "/training-course-list?aud_id=";
    if (aud_id != '') {
      return "<a href='" + clickf + aud_id + "&courseid=" + courseid + "'>More...</a>";
    } else {
      return "<a href='" + clickf + "'>More...</a>";
    }
  }

  scrollForm(type: string) {
    //console.log('calling scroll...');
    //el.scrollIntoView({behavior: 'smooth'});
    switch (type) {
      case 'targetServDesc':
        this.targetServDesc.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
    }
  }

  loadmore(course_id = '') {
    var clickf = "/training-course-list?course_id=";
    if (course_id != '') {
      return "<a href='" + clickf + course_id + "'>More...</a>";
    } else {
      return "<a href='" + clickf + "'>More...</a>";
    }
  }
  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  load_more(total_show) {
    this.trainingServicesListForEvents = [];
    this.rowCount = this.rowCount + parseInt(total_show);
    this.totalRow = this.rowCount * 3;
    this.trainingServicesListForEvents = [];

    for (let i = 0; i <= this.totalRow; i++) {
      if (this.allTrainingEvents[i]) {
        this.trainingServicesListForEvents.push(this.allTrainingEvents[i]);
      }
    }
    // for(let i=1; i<= this.rowCount*1; i++)
    ////console.log(courseArrLength,'courseArrLength')
  }

}
