import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-event-calender',
  templateUrl: './event-calender.component.html',
  styleUrls: ['./event-calender.component.scss']
})
export class EventCalenderComponent implements OnInit {

  title: string = ""
  message: string = ""
  link: string;
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<EventCalenderComponent>) { 
    if(data){
      this.title = data.obj.title
      this.message = data.obj.description || this.message;
      this.link = data.obj.link
    }
  }

  ngOnInit() {
  }

  readMore(linkid =""){
    if(linkid!=''){
      return "<span><a href='"+linkid+"'>More...</a></span>";
    }
  }
}
