import { Component, OnInit, ChangeDetectorRef, Input, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Constants } from 'src/app/services/constant.service';
import { AppService } from 'src/app/services/app.service';
import { PLATFORM_ID } from '@angular/core';
import { PlatformLocation, isPlatformBrowser, isPlatformServer } from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [Constants, TranslateService],
})
export class HeaderComponent implements OnInit {
  @Input() loggedStatus: boolean = false;

  public title: string;
  public logUser: any;
  public selLang: string;
  isMenu: boolean = false;
  addHeaderClass: string = '';
  qeury: any;
  curURL: string = '';
  typedQuery: boolean = false;
  isSearch: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any, public translate: TranslateService, public constant: Constants, public _router: Router,
    public router: Router, public cdRef: ChangeDetectorRef, public Service: AppService) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');

    //this.logUser = localStorage.getItem('logUser');   
    this.logUser = (this.constant.userToken != '') ? this.constant.userToken : localStorage.getItem('logUser');
    //console.log('Header: 1', this.logUser, " Logged status:  ", this.loggedStatus);  
    //alert('Header...constr');
  }

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.selLang = localStorage.getItem('sellang');
      if (this.selLang != null || this.selLang != undefined) {
        this.translate.use(this.selLang);
      } else {
        this.translate.use('en');
      }
      //this.logUser  = (this.constant.userToken != '') ? this.constant.userToken : localStorage.getItem('logUser');

      this.constant.getLoggedInToken.subscribe(token => {
        if (token != null) {
          this.logUser = (token != '') ? token : localStorage.getItem('token');
        }
      });
      this.qeury = sessionStorage.getItem('query');
      this.curURL = this._router.url;
    }
  }
  logout() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        localStorage.setItem('token', '');
        localStorage.setItem('logUser', '');
        //this.constant.userToken = '';
        this.logUser = this.constant.userToken;
        //this.logUser = localStorage.getItem('logUser');
        //this.constant.loggedIn.next(false);
        //this.constant.loggedStatus  = false;
        this.router.navigateByUrl('/sign-in');
      }, 10);
    }
  }
  changeLanguage(language: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.selLang = language;
      this.translate.use(language);
      localStorage.setItem('sellang', language);
    }
  }
  searchTypeClose() {
    if (isPlatformBrowser(this.platformId)) {
      this.typedQuery = false;
      this.qeury = '';
    }
  }
  searchClick() {
    if (isPlatformBrowser(this.platformId)) {

      let url = '/search';//+this.qeury;
      // this.qeury = ;

      //console.log("URL val: ", this._router.url)
      // if(sessionStorage.getItem('query') != undefined && sessionStorage.getItem('query') != ''){
      //   sessionStorage.setItem('query', '');
      // }
      if (this.qeury != undefined && this.qeury != '') {
        //console.log(">>>");
        sessionStorage.setItem('query', this.qeury);
        // if(curURL === '/search'){
        //   top.location.href = url;
        // }else{
        this._router.navigateByUrl('/search')
        //}
        //

      }
    }
  }
  searchType(theText: any) {
    //console.log(">>>Eneter: ", theText, " :: ", this.qeury);
    if (isPlatformBrowser(this.platformId)) {
      if (this.qeury != '') {
        this.typedQuery = true;
      }
      if (this.qeury === '') {
        this.typedQuery = false;
      }
    }
  }
  openMenuSearch() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.curURL != '/search') {
        this.isSearch = !this.isSearch
        this.searchTypeClose();
      }
      if (this.curURL === '/search') {

        let elem = document.getElementById('srchKey');

        console.log("search...", elem);
        elem.scrollIntoView();

      }
    }
  }
  //Open Menu Show Hide
  openMenuShowHide() {
    if (isPlatformBrowser(this.platformId)) {
      this.isMenu = !this.isMenu;
      if (this.isMenu) {
        this.addHeaderClass = 'menueActive';
      }
      else {
        this.addHeaderClass = '';
      }
    }
  }

}
