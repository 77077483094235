import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  public token ;

  constructor(
    public _router: Router,
  ) {
    this.token =localStorage.getItem('token');
    //console.log();
    
  }
  /**
   * Take any HTTP request made with HttpClient and add the proper outgoing headers for authentication purposes.
   * @param req
   * @param next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // modify the request
    let headers = req.headers;
    const noAuth = headers['lazyUpdate'] && headers['lazyUpdate'].length;
  
    //console.log('Http intercepts: ',headers);
    if (this.token && !noAuth) {
      headers = headers.set(
        'Authorization', 'Token '+ this.token
      );
    }
    if (noAuth) {
      headers['lazyUpdate'] = null;
    }

    // modify the request
    const authReq = req.clone({
      headers: headers
    });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let data = {};
        // data = {
        //   reason: error && error.error.reason ? error.error.reason : '',
        //   status: error.status
        // };
        return throwError(error);
      }));
  }
}
