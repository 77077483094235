import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-play-media',
  templateUrl: './play-media.component.html',
  styleUrls: ['./play-media.component.scss']
})
export class PlayMediaComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<PlayMediaComponent>) { 
    if(data){
      //Do the needful
    }
  }

  ngOnInit() {
  }

  onPlayClick(): void {
    if(this.data && this.data.vidObject != undefined){
      this.data.vidObject.play();
    }
    this.dialogRef.close({authorization:false});
  }

  onPlayClose(): void {
    this.dialogRef.close({authorization:false});
  }

}
