import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { EnquiryDialogboxComponent } from '../../../../pages/common-all/enquiry-dialogbox/enquiry-dialogbox.component';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RecaptchaComponent } from 'ng-recaptcha';
import { UiDialogService } from '../../../../services/uiDialog.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-asscssors-service',
  templateUrl: './asscssors-service.component.html',
  styleUrls: ['./asscssors-service.component.scss'],
  providers: [AppService, Constants, UiDialogService]
})
export class AsscssorsServiceComponent implements OnInit {

  SubscribeCompanyChange: Subscription;
  language_id: any;
  public schemeSlideData: any = {};
  public loader: boolean = true;
  public pageList: any = [];
  public service_steps: any = [];
  public service_requirements: any = {};
  public service_provider: any = [];
  public service_channel: any = [];
  public banner: any = [];
  arabicTitle: any;
  englishTitle: any;
  records: any;
  records_year: any;
  records_types: any;
  records_cabs: any;
  records_certificates: any;
  bannerURL: any = '';
  bannerLinkTarget: string = '';
  bannerTitle: string = '';
  public enquiryForm: any = {};
  public is_submit: boolean = false;
  public checkSecurity: boolean = false;
  public checkCaptchaValidation: boolean = false;
  getServicesDescription: any
  other_title: any;
  other_description: any;
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  getAllServices: any;
  version = VERSION;
  afterSubmit: any;
  bannerSlider: any[] = [];
  bannerArabicTitle: any;
  assessors_page: any;
  pageData: any;
  flag: any;

  @ViewChild('enquiryAsscessorsForm', null) enquiryAsscessorsForm: ElementRef;
  @ViewChild('targetServDesc', null) targetServDesc: ElementRef;
  @ViewChild('targetServDesc1', null) targetServDesc1: ElementRef;
  @ViewChild('captchaRef', { static: true }) captchaRef: RecaptchaComponent;
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: false,
  };
  public lat;
  public lng;

  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, private meta: Meta, public Service: AppService, public uiDialog: UiDialogService, public constant: Constants, public titleService: Title, public sanitizer: DomSanitizer, public toastr: ToastrService, public http: HttpClient, public dialog: MatDialog) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.titleService.setTitle('EIAC - ASSESSORS SERVICES');
    this.meta.updateTag({ name: 'title', content: 'EIAC - Assessors & Experts Corner' });
    this.meta.updateTag({ name: 'description', content: 'Team leader, Technical Assessor, Management System Assessor, Technical Expert' });
    this.meta.updateTag({ name: 'keywords', content: 'Assessors & Experts Corner' });
    this.assessors_page = 'assessors';
    this.getIPAddress();
    this.loadService();
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.loadService();
      });
   
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

  loadService() {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.Service.getWithMultipleParams(this.constant.API.service_details_page, 'assessors', this.language_id)
      .subscribe(
        res => {
          this.pageList = res['data'][0]
          // this.service_steps = JSON.parse(this.pageList.service_steps)
          if(this.language_id == '1') {
            this.service_steps = JSON.parse(this.pageList.service_steps);
          }else{
            this.service_steps = JSON.parse(this.pageList.service_steps);
            this.service_steps.forEach((res,key) => {
              if(res['step'] == 'Step 1') {
                this.service_steps[key]['step'] = 'الخطوة 1';
              }else if(res['step'] == 'Step 2') {
                this.service_steps[key]['step'] = 'الخطوة 2';
              }else if(res['step'] == 'Step 3') {
                this.service_steps[key]['step'] = 'الخطوة 3';
              }
              
              // this.service_steps[key]['step'] = this.service_steps[key]['step'] == 'Step 2' ? 'الخطوة 2' : '';
              // this.service_steps[key]['step'] = this.service_steps[key]['step'] == 'Step 3' ? 'الخطوة 3' : '';
            });
            // console.log(this.service_steps,'service_steps');
          }
          this.service_requirements = JSON.parse(this.pageList.service_requirements)
          this.service_provider = JSON.parse(this.pageList.service_provider)
          this.service_channel = Object.values(JSON.parse(this.pageList.service_channel))
          this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];

          this.records = JSON.parse(res['records']);
          this.records_year = res['years'] > 0 ? res['years'] : 0;
          this.records_types = this.records.types;
          this.records_cabs = this.records.cabs;
          this.records_certificates = this.records.certificates;
          this.getServicesDescription = JSON.parse(this.pageList.other_description);
          this.other_title = this.getServicesDescription.title;
          this.other_description = this.getServicesDescription.description;

          if (res['schemeData'] && res['schemeData'].length > 0) {
            this.schemeSlideData = res['schemeData'];
            this.schemeSlideData['image'] = this.constant.mediaPath + res['banner'][0]['banner']['image'];
            //console.log("schedata passing: ", this.schemeSlideData);
            //this.onLoad.emit({value: res['schemeData']});
          } else {
            this.schemeSlideData = [];
          }
          // console.log(this.service_provider);
          // this.bannerTitle = (res['banner'][0]['banner']['image_name'] != null) ? res['banner'][0]['banner']['image_name'] : '';
          // this.bannerURL = (res['banner'][0]['banner']['url'] != '' ) ? this.getSantizeUrl(res['banner'][0]['banner']['url']) : '';
          // //console.log(this.bannerURL,'bannerURL');
          // this.bannerLinkTarget = (res['banner'][0]['banner']['url'] != null) ? '_blank' : '_self';

          // this.footerBanner = this.constant.mediaPath+res['banner'][0]['banner']['footer_image'];
          // this.footerTitle = res['banner'][1].footer_title;
          // this.footerDescription = res['banner'][1].footer_description;

          // this.arabicTitle = res['banner'][0]['title'];
          // this.englishTitle = res['banner'][1]['title'];

          if (res['banner'].length > 0) {
            this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            this.bannerTitle = res['banner'][select_language]['title'];
            this.pageData = res['banner'][select_language];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][select_language]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][select_language]['headerBanner'].length > 0) {
              res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }

          this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
          this.footerTitle = res['banner'][select_language].footer_title;
          this.footerDescription = res['banner'][select_language].footer_description;
          this.loader = false;
        },
        error => {

        })

    this.Service.getWithMultipleParams(this.constant.API.service_details_page, 'accreditation_service', this.language_id)
      .subscribe(
        res => {
          this.getAllServices = res['allServiceList'];
          //console.log(this.getAllServices,'getAllServices')
        },
        error => {

        })
  }

  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            //console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep)
      }
    }

  }

  scrollForm(type: string) {
    //console.log('calling scroll...');
    //el.scrollIntoView({behavior: 'smooth'});
    switch (type) {
      case 'targetServDesc':
        this.targetServDesc.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      case 'targetServDesc1':
        this.targetServDesc1.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
    }
  }
  serviceLists(id) {
    const el = document.getElementById(id);
    //this.vps.scrollToAnchor(el);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.enquiryForm.ip_address = res.ip;
    });
  }


  scrollToPage(id) {
    const el = document.getElementById(id);
    //console.log("@elem: ",el);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });    //arguement true bypass the non-exist element or undefined
    }
  }

  onSubmit(ngForm) {
    //this.postPoll.emit();
    // if(this.checkSecurity == true)
    // {
    //   this.checkCaptchaValidation = true;
    // }else{
    //   this.checkCaptchaValidation = false;
    // }
    this.is_submit = true;


    if (ngForm.form.valid && this.checkCaptchaValidation == true) {
      this.afterSubmit = true;
      this.enquiryForm.enquiry_type = 'assessor_service';
      this.Service.post(this.constant.API.enquiry_form + 'assessors/contact_pillar_feedback', this.enquiryForm)
        .subscribe(
          res => {
            if (res['status'] == true) {
              this.is_submit = false;

              // this.checkCaptchaValidation = false;
              // this.enquiryForm= {};
              this.enquiryForm.name = '';
              this.enquiryForm.email = '';
              this.enquiryForm.comments = '';
              this.enquiryForm.phone = '';
              // this.toastr.success(res['msg'], '');
              this.captchaRef.reset();
              this.checkCaptchaValidation = false;

              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  message: 'Mail sent successfully.',
                  success: '1',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this,
                  index: '',
                }
              });
            } else {
              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  success: '0',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this
                }
              });
            }
          },
          error => {

          })
    }

  }

  getRevalidatedSubmit() {
    this.afterSubmit = false;
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  resolvedSecurity(captchaResponse: string) {
    let captchaStatus = captchaResponse;
    if (captchaStatus != '') {
      this.checkSecurity = true;
      this.checkCaptchaValidation = true;
    }
  }
}
