import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { Output, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class Constants {
  @Output() getLoggedInToken: EventEmitter<any> = new EventEmitter();

  //public apiUrl: string 		= 'https://service.eiac.gov.ae/webservice/';
  public apiUrl: string = "https://service.eiac.gov.ae/webservice/";

  public portal_SERV: string = "https://portal.eiac.gov.ae/";
  //http://localhost:8080/
  // public apiUrl: string = 'https://dev-service.eiac.gov.ae/webservice/';
  // public apiUrl: string 		= 'https://192.168.3.31:8000/webservice/';//'https://87.117.243.177:8080/webservice/';  //'https://87.117.243.177:8080/webservice/';

  public mediaPath: string = "https://service.eiac.gov.ae";
  public mediaPathMediacentre: string =
    "https://blog.eiac.gov.ae/wp-content/uploads/";

  public API: any = {
    listing: this.apiUrl + "posts/",
    logIn: this.apiUrl + "login",
    signup: this.apiUrl + "signup",
    customer_poll: this.apiUrl + "customer-poll",
    service_api: this.apiUrl + "get_service_list",
    healthcare_form_basic_data: this.apiUrl + "healthcare_form/",
    inspection_form_basic_data:
      this.apiUrl + "inspection_body_form_management/",
    testing_cal_form_basic_data:
      this.apiUrl + "testing_calibration_form_management/",
    workpermit_form_basic_data: this.apiUrl + "workpermit_form/",
    halal_confirmity_bodies: this.apiUrl + "halal-conformity-form/",
    contact_us: this.apiUrl + "contact-us/",
    contact_us_type: this.apiUrl + "contact-us-type/",
    enquiry_form: this.apiUrl + "enquiry/",
    faq_category: this.apiUrl + "faq-category/",
    faq_question_ans: this.apiUrl + "faq-question-ans/",
    service_details_page: this.apiUrl + "pillar_page/",
    all_services: this.apiUrl + "service_page/",
    publications: this.apiUrl + "get_service_publication/",
    publicationsAllData: this.apiUrl + "get_service_publication",
    get_banner: this.apiUrl + "banner_page/",
    suggestion: this.apiUrl + "aboutus/suggestion",
    customer_satisfaction: this.apiUrl + "customer-satisfaction/",
    public_training_form: this.apiUrl + "training-request-form/",
    cms_management: this.apiUrl + "cms-management",
    pt_provider: this.apiUrl + "pt-provider-form/",
    event_service: this.apiUrl + "event-service",
    media_center: this.apiUrl + "media_center/",
    news_announcement: this.apiUrl + "news_announcement/",
    application_for_noc: this.apiUrl + "application_for_noc/",
    certification_bodies: this.apiUrl + "certification_bodies/",
    cab_directory_by_id: this.apiUrl + "cab_directory/",
    about_us_content: this.apiUrl + "general-cms/about_us",
    table_masterhome: this.apiUrl + "table-master/home",
    table_master_aboutus: this.apiUrl + "table-master/about_us",
    criteriaIdByScope: this.apiUrl + "criteria_wise_scope/",
    happyness_meter_data_save: this.apiUrl + "happyness_meter_data_save/",
    training_course_list: this.apiUrl + "training-course-list/",
    training_service_course: this.apiUrl + "training-service-course/",
    audience_list: this.apiUrl + "training-course-list-main/",
    targated_aud_training: this.apiUrl + "targated-aud-training-course/",
    happyness_meter_question: this.apiUrl + "happyness_meter_question/",
    under_map_contact: this.apiUrl + "global_settings_data/under_map_contact",
    directory_page: this.apiUrl + "directory",
    directory_details_page: this.apiUrl + "directory-details",
    service_api_home_slider: this.apiUrl + "cab_directory_by_service/",
    global_settings_data: this.apiUrl + "global_settings_data/social",
    global_settings_contact_address:
      this.apiUrl + "global_settings_data/contact_address",
    map_box_api_value: this.apiUrl + "global_settings_data/map_box_api_value",
    elastic_search: this.apiUrl + "elastic_search/",
    course_details: this.apiUrl + "course-details/",
    training_course_type: this.apiUrl + "course-type-list/",
    org_structure: this.apiUrl + "org-structure/",
    search_results: this.apiUrl + "search_service",
    search_page: this.apiUrl + "search",
    dirtectory_help_note: this.apiUrl + "page-details/directory_help_note",
    page_visit: this.apiUrl + "page_visit",
    default_language_id: 1,
  };

  //https://127.0.0.1:8000/webservice/cab_directory_by_service/?status=all

  public unhandledExceptionError: string =
    "Unhandled Exception. Please Try Again Or Contact Support.";
  public loggedIn = new Subject<any>();
  public loggedStatus: boolean = false;
  public userToken: string = "";
  public reCaptchaSiteKey: string = "6LeeY8cUAAAAAMnRpgc79Pj6XLN0wo-m1JJZt3ie";
  public reCaptchaSecretKey: string =
    "6LeeY8cUAAAAAJHeVK3bAdJbctZ6An3hr2pZjQkZ";

  public errorDialogDuration: number = 1700;
  public getSatisFactorySurveyAPI: string = "https://api.myjson.com/bins/obrpm";
  public countryAPI: string = "https://api.myjson.com/bins/npjk2";
  public profileComplition = new Subject<any>();
  public pageTop: number = 0;
  public pageBot: number = 250;
  public NOCPostAPI: string = "";
  public WorkPermitAPI: string = "";

  public strategyAPI: string = this.apiUrl + "aboutus/strategy";
  public bodAPI: string = this.apiUrl + "aboutus/bod";
  public recognitionAPI: string = this.apiUrl + "page-details/recognition";
  public eiaclogoAPI: string = this.apiUrl + "page-details/eiac_logo";
  public aboutEiacAPI: string = this.apiUrl + "page-details/about_eiac";
  public structureAPI: string = this.apiUrl + "page-details/structure";
  public trainingService: string = this.apiUrl + "trainig-service";
  public homepageAPI: string = this.apiUrl + "general-cms/home";
  public analyticAPI: string = this.apiUrl + "global_settings_data/analytics";

  //https://192.168.3.31:8000/webservice/aboutus/strategy
  //
  //Declare FIXED search filter TYPE
  public searchFilterTypeLabel: any[] = [
    {
      title: "Accreditation (including sub types)",
      value: "accr_service",
      isSelected: true,
      count: 0,
    },
    {
      title: "Registration (including sub types)",
      value: "pillar_page",
      isSelected: true,
      count: 0,
    },
    { title: "Training", value: "training_course", isSelected: true, count: 0 },
    { title: "FAQ", value: "faq_page", isSelected: true, count: 0 },
    { title: "Poll", value: "poll_page", isSelected: true, count: 0 },
    { title: "Survey", value: "survey", isSelected: true, count: 0 },
    { title: "About Us", value: "page", isSelected: true, count: 0 },
    { title: "About EIAC", value: "about_eiac", isSelected: true, count: 0 },
    { title: "BOD", value: "bod", isSelected: true, count: 0 },
    { title: "Recognition", value: "recognition", isSelected: true, count: 0 },
    { title: "Directory", value: "directory", isSelected: true, count: 0 },
    //{title: 'Publication', value: 'publication'}
  ];
  //Not match -with result - page
  //need to add in filter type - Abount Us(about_us) | Abount EIAC(about_eiac) | Survey(survey) | Board(board) | Recognition(recognition) | Publication(publication)
}
