import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ContactUsComponent } from '../../contact-us/component/contact-us/contact-us.component';

@Component({
  selector: 'app-enquiry-dialogbox',
  templateUrl: './enquiry-dialogbox.component.html',
  styleUrls: ['./enquiry-dialogbox.component.scss']
})
export class EnquiryDialogboxComponent implements OnInit {
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  success = '0';
  afterSubmit:boolean;
  ContactUsComponentObj:any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<EnquiryDialogboxComponent>) { 
    if(data){
      this.message = data.message || this.message;
      this.success = data.success || this.success;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        
      }
      this.ContactUsComponentObj = data.obj;
      if(this.ContactUsComponentObj.afterSubmit != undefined || this.ContactUsComponentObj.afterSubmit == true)
      {
        this.ContactUsComponentObj.afterSubmit = false
      }
      console.log(this.ContactUsComponentObj.afterSubmit);
    }
  }

  ngOnInit() {
  }

  onConfirmClick(): void {
    this.afterSubmit = false;
    this.dialogRef.close({authorization:false});
  }

  onConfirmClose(): void {
    this.afterSubmit = false;
    this.dialogRef.close({authorization:false});
  }

}
