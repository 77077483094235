import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-after-login',
  templateUrl: './header-after-login.component.html',
  styleUrls: ['./header-after-login.component.scss']
})
export class HeaderAfterLoginComponent implements OnInit {
  showHeader: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  afterLoginHeader() {
    this.showHeader = !this.showHeader;
  }

}
