import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { HappinessMeterComponent } from '../../../../pages/common-all/happiness-meter/happiness-meter.component';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Subject } from "rxjs";
// import * as Rx from "rxjs";
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [AppService, Constants, GoogleAnalyticsService]
})
export class FooterComponent implements OnInit {
  public isDropout: boolean = true;
  public isMedia: boolean = false;
  public isSupport: boolean = false;
  public isEparticipate: boolean = false;
  public isAboutus: boolean = false;
  public isPublication: boolean = false;

  panelOpenState = false;
  addngClass: string = 'contentbottom';
  mediaClass: string = '';
  supportClass: string = '';
  publicationClass: string = '';
  epClass: string = '';
  about_us: string = '';
  publication: any;
  pid: any;
  linkdinUrl: any;
  twitterUrl: any;
  youtubeUrl: any;
  googleUrl: any;
  trackingCODE: any;

  @Input() openDialog: boolean;
  @Input() pageData: any;
  @Output() onData: EventEmitter<any> = new EventEmitter();
  SubscribeCompanyChange: Subscription;
  language_id: any;

  pageVisit: any = {};
  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, public route: ActivatedRoute, public router: Router, public dialog: MatDialog,
    public constant: Constants, public Service: AppService, public sanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService, public http: HttpClient,) {

    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  initOpen(value) {
    ////console.log('receiving footer...', value);
    this.openHappinessIndigatorDialog('', 1);
  }
  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.pageVisit['ip_address'] = res.ip;
    });
  }

  ngAfterViewInit() {
    //---- Start Promise UnExceptional Error
    let p = new Promise((resolve, reject) => {

      ////console.log("create promise: ");
      //reject("nope");
    });
    ////console.log("setting up");
    p
      .then(r => {
        ////console.log(">>>Promise pass...");
      })
      .catch(e => {
        ////console.log(">>>Promise unhandle...");
      })
    ////console.log("setup done");
    //---- End Promise UnExceptional Error

  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('#page data load...', this.pageData, " -- ", changes);
    //&& this.pageData.length > 0
    if (changes != undefined && this.pageData != undefined) {
      this.pageVisit['slug_url'] = this.router.url;
      //console.log(">>>records: ", this.pageData);
      if (this.pageData) {
        let data: any = this.pageData;
        // var html = data.description;
        // var div = document.createElement("div");
        // div.innerHTML = html;
        // var text = div.textContent || div.innerText || "";
        //console.log("Text name: ", text);
        this.pageVisit['page_name'] = data.title;
      }
      //this.pageVisit['page_name'] = '';
      //console.log("Data received...", " -- ", this.router.url, " :: ", this.pageVisit);

      //cal service
      this.Service.post(this.constant.API.page_visit, this.pageVisit)
        .subscribe(
          res => {
            //console.log("Page visit response: ", res);
          });

    } else {
      //console.log("NO Data input");
    }
  }

  private appendGaTrackingCode() {
    // try {
    //   const script = document.createElement('script');
    //   script.innerHTML = `
    //     (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //     })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    //     ga('create', '` + environment.googleAnalyticsKey + `', 'auto');
    //   `;
    //   document.head.appendChild(script);
    // } catch (ex) {
    //  console.error('Error appending google analytics');
    //  console.error(ex);
    // }
  }

  loadAnalytics() {
    let analyticAPI = this.constant.analyticAPI;
    //Get analytics
    this.Service.get(analyticAPI, this.language_id)
      .subscribe(
        result => {
          //console.log(">>>Footer ANalytic >>> ", result);
          let data: any = result;
          let analyTic = data.records[0].value;
          if (analyTic != undefined) {
            this.trackingCODE = analyTic;
          }
          //console.log('@Tracking: ', this.trackingCODE);

          try {
            const script = document.createElement('script');
            script.innerHTML = `
                  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                 
                  ga('create', '` + this.trackingCODE + `', 'auto');
                `;
            //console.log('>>> CSRIPT: ', script);
            document.head.appendChild(script);
          } catch (ex) {
            console.error('Error appending google analytics');
            console.error(ex);
          }
          this.googleAnalyticsService.emitEvent("testCategory", "testAction", "testLabel", 10);
        })
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.publication = this.route.snapshot.queryParamMap.get('publicationId');
    this.pid = (this.publication > 0) ? this.publication : '0';
    //Route URL get
    ////console.log('@Route...',this.router.url, " :: PageData: ", this.pageData);
    let getUrl = this.router.url;
    let getUrlSlugs = getUrl.toString().split('/');
    let getPage: string = '';
    ////console.log("#Get slugs ar: ", getUrlSlugs);
    if (getUrlSlugs != undefined && getUrlSlugs.length <= 2 && getUrlSlugs[1] === "") {
      //Home Page
      getPage = "Home";
    } else if (getUrlSlugs != undefined && getUrlSlugs.length <= 2 && getUrlSlugs[1] !== "") {
      //1st slug page
      getPage = getUrlSlugs[getUrlSlugs.length - 1];
    } else {
      //else page
      getPage = getUrlSlugs[getUrlSlugs.length - 1];
    }
    //console.log("URL: ", this.router.url);
    //this.getIPAddress();
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.loadData();
        this.loadAnalytics();
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
      });
    this.loadAnalytics();
    ////console.log("page name: ", getUrl, " :: ", getPage)
    this.loadData();
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   ////console.log(changes,'rgggggggtgrrrrghhhyttyh')
  // }
  loadData() {
    this.Service.get(this.constant.API.global_settings_data, this.language_id)
      .subscribe(
        res => {
          // this.mapContent = res['records'];
          ////console.log(res,'Footer');
          ////console.log(res['records'][0].value,'Footer');
          var allRecords = res['records'];

          for (let key in allRecords) {
            if (allRecords[key].meta_title == 'linkedin') {
              // this.linkdinUrl = allRecords[key].value;
              // //console.log(allRecords[key].value.indexOf('https'),'linkedin');
              this.linkdinUrl = (allRecords[key].value != '' && allRecords[key].value.indexOf('https') == -1) ? this.getSantizeUrl('https://' + allRecords[key].value) : this.getSantizeUrl(allRecords[key].value);
            } else if (allRecords[key].meta_title == 'twitter') {
              // this.twitterUrl = allRecords[key].value;
              this.twitterUrl = (allRecords[key].value != '' && allRecords[key].value.indexOf('https') == -1) ? this.getSantizeUrl('https://' + allRecords[key].value) : this.getSantizeUrl(allRecords[key].value);
            } else if (allRecords[key].meta_title == 'youtube') {
              // this.youtubeUrl = allRecords[key].value;
              this.youtubeUrl = (allRecords[key].value != '' && allRecords[key].value.indexOf('https') == -1) ? this.getSantizeUrl('https://' + allRecords[key].value) : this.getSantizeUrl(allRecords[key].value);
            } else if (allRecords[key].meta_title == 'google') {
              // this.googleUrl = allRecords[key].value;
              // //console.log(allRecords[key].value.indexOf('https'),'google');

              this.googleUrl = (allRecords[key].value != '' && allRecords[key].value.indexOf('https') == -1) ? this.getSantizeUrl('https://' + allRecords[key].value) : this.getSantizeUrl(allRecords[key].value);
            }
          }
          ////console.log(this.twitterUrl,'twitterUrl');
        },
        error => {

        });

    this.Service.get(this.constant.API.map_box_api_value, this.language_id)
      .subscribe(
        res => {
          //this.Service.mapboxToken = res['records'][0]['value'];
          // //console.log(mapboxApiValue,'twitterUrl');
          this.onData.emit(res['records'][0]['value']);
          ////console.log(this.Service.mapboxToken,'mapboxToken');
        },
        error => {

        });

    // var subject = new Rx.AsyncSubject();
    // subject.next('The first thing has been sent');

    // var subject = new Subject<any>();
    // subject.next('The first thing has been sent');
    // subject.subscribe((data) => {
    //     //console.log('Subscriber A:', data);


  }

  selectOpenDiv(option) {

    if (option == "about_us") {
      this.isDropout = !this.isDropout;
      if (this.isDropout) {
        this.addngClass = 'contentbottom'
      }
      else {
        this.addngClass = ''
      }
    }
    if (option == "support") {
      this.isSupport = !this.isSupport;
      if (this.isSupport) {
        this.supportClass = 'contentbottom'
      }
      else {
        this.supportClass = ''
      }
    }
    if (option == "eparticipate") {
      ////console.log('hello')
      this.isEparticipate = !this.isEparticipate;
      if (this.isEparticipate) {
        this.epClass = 'contentbottom'
      }
      else {
        this.epClass = ''
      }
    }
    if (option == "about_us") {
      ////console.log('hello')
      this.isAboutus = !this.isAboutus;
      if (this.isAboutus) {
        this.about_us = 'contentbottom'
      }
      else {
        this.about_us = ''
      }
    }
    if (option == "publication") {
      this.isPublication = !this.isPublication;
      if (this.isPublication) {
        this.publicationClass = 'contentbottom'
      }
      else {
        this.publicationClass = ''
      }
    }
    if (option == "media") {
      this.isMedia = !this.isMedia;
      if (this.isMedia) {
        this.mediaClass = 'contentbottom'
      }
      else {
        this.mediaClass = ''
      }
    }

  }

  initDialog(event) {
    ////console.log('get dialog: ', event);
  }

  openHappinessIndigatorDialog(obj: any, index: number) {
    const dialogRef = this.dialog.open(HappinessMeterComponent, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        },
        obj: obj,
        index: index,
      }
    });
  }


}
