import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from 'src/app/services/constant.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    public token: any;
    constructor( public router: Router, public constant:Constants) 
    { 
       
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.token  = (this.constant.userToken != '') ? this.constant.userToken : sessionStorage.getItem('token');
        
        let getToken = (this.token === null) ? '' : this.token;
        ////console.log('Auth check: 1',this.token, " ---> ", getToken);
        if (this.token === undefined || this.token == '' || this.token === null)  {
            ////console.log('Auth guard 2: ',getToken);
            this.router.navigate(['/user/sign-in']);
            return false;
        }
        return true;
    }
}