import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'CustStrip'
  })
  export class CustomStripTagsPipe implements PipeTransform {  
    transform(text: string, ...allowTags: any[]): string {
        //console.log(">>>Pipe: ", allowTags, " :: ", text);
        if(text != undefined){
            return allowTags.length > 0
                ? text.replace(new RegExp(`<(?!\/?(${allowTags.join('|')})\s*\/?)[^>]+>`, 'g'), '')
                : text.replace(/<[^>]+>/g, '');   
        }        
    }
}