import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-bod-dialog',
  templateUrl: './bod-dialog.component.html',
  styleUrls: ['./bod-dialog.component.scss']
})
export class BodDialogComponent implements OnInit {

  title: string = ""
  message: string = ""
  link: string;
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<BodDialogComponent>) {
    if(data){
      this.title = data.obj.title
      this.message = data.obj.description || this.message;
      this.link = data.obj.link
    }
   }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
