import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from 'src/app/services/constant.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(public router: Router, public constant: Constants) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if(localStorage.getItem('language_id') == null || localStorage.getItem('language_id') == undefined 
           || localStorage.getItem('language_id') == ''){
            localStorage.setItem('language_id', '1');
        }
        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            //console.log('HTTP Error: ', error);            
            if (error.error instanceof Blob) {
                return new Promise<any>((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = (e: Event) => {
                        try {
                            const errmsg = JSON.parse((<any> e.target).result);
                            reject(
                                errmsg.error + (errmsg.detail ? (' - ' + errmsg.detail) : '')
                            );
                        } catch (e) {
                            reject(error);
                        }
                    };
                    reader.onerror = (e) => {
                        reject(error);
                    };
                    reader.readAsText(error.error);
                });
            }
            else {
                if (!error.error.error && (error.status == 0 || (error.status >= 300 && error.status <= 600))) {
                    return observableThrowError(this.constant.unhandledExceptionError);
                }
                else {
                    if (!error.error.error && !error.error.detail) {
                        if (error.error) {
                            return observableThrowError(error.error);
                        }
                        else {
                            return observableThrowError(this.constant.unhandledExceptionError);
                        }
                    }
                    else {
                        //Check specific DB Error and handle in UI section
                        if (error.error.id != undefined) {
                            return observableThrowError(error);
                        }
                        else {
                            return observableThrowError(error.error.error + (error.error.detail ? (' - ' + error.error.detail) : ''));
                        }
                    }
                }
            }
        }));
    }
}