import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { UiDialogService } from '../../../../services/uiDialog.service';
import { EnquiryDialogboxComponent } from '../../../../pages/common-all/enquiry-dialogbox/enquiry-dialogbox.component';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RecaptchaComponent } from 'ng-recaptcha';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID } from '@angular/core';
import { PlatformLocation, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-cource-detail',
  templateUrl: './training-cource-detail.component.html',
  styleUrls: ['./training-cource-detail.component.scss'],
  providers: [AppService, Constants, UiDialogService]
})

export class TrainingCourceDetailComponent implements OnInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  trainingForm: any;
  public serviceList: any = [];
  public banner: any = [];
  public loader: boolean = true;
  public service_steps: any = [];
  public service_requirements: any = [];
  public service_provider: any = [];
  public service_channel: any = [];
  public enquiryForm: any = {};
  public is_submit: boolean = false;
  public checkSecurity: boolean = false;
  public checkCaptchaValidation: boolean = false;
  version = VERSION;
  afterSubmit: any;
  courseDetailId: any;
  courseDetails: any;
  bannerTitle: any;
  bannerArabicTitle: any;
  bannerSlider: any[] = [];
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;

  pageData: any;
  bannerSliderConfig: any;

  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  @ViewChild('captchaRef', { static: true }) captchaRef: RecaptchaComponent;
  @ViewChild('targetList', null) targetList: ElementRef;

  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: any, private meta: Meta, public route: ActivatedRoute, public uiDialog: UiDialogService, public router: Router, public Service: AppService, public constant: Constants, public toastr: ToastrService, public titleService: Title, public dialog: MatDialog, public http: HttpClient) {

    this.courseDetailId = this.route.snapshot.paramMap.get("id");
    this.Service.getwithoutData(this.constant.API.course_details + this.courseDetailId)
      .subscribe(res => {
        this.meta.updateTag({ name: 'title', content: 'EIAC - Course Details - ' + res['courseDetails'].course.replace(/&amp;/g, '&') });
      }, error => {

      })
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.loadService();
      });
    this.meta.updateTag({ name: 'description', content: 'Our Training programs' });
    this.meta.updateTag({ name: 'keywords', content: 'Our Training programs' });
    if (isPlatformBrowser(this.platformId)) {
      this.titleService.setTitle('EIAC - Course Detail');
      this.targetList.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      this.courseDetailId = this.route.snapshot.paramMap.get("id");
    }
    this.loadService();
    //console.log(this.trainingForm);
    this.getIPAddress();
  }

  // serviceFormRedirect(){
  //   if(this.trainingForm == '1'){
  //     this.router.navigate(['application-form/public-training-form']);
  //   }else if(this.trainingForm == '2'){
  //     this.router.navigate(['application-form/clientPermises-training-form']);
  //   }
  // }

  loadService() {
    //   this.Service.get(this.constant.API.course_details,'training_cource_detail')
    //   .subscribe(
    //     res => {
    //       console.log(res)
    //       this.serviceList  = res['data'][0]
    //       if(res['banner'].length>0){
    //         this.banner = this.constant.mediaPath+res['banner'][0]['banner']['image'];
    //       }

    //       this.service_steps = JSON.parse(this.serviceList.service_steps)
    //       this.service_requirements = JSON.parse(this.serviceList.service_requirements)
    //       this.service_provider = JSON.parse(this.serviceList.service_provider)
    //       this.service_channel = Object.values(JSON.parse(this.serviceList.service_channel))



    //     },
    //     error => {

    // })
    let select_language = this.language_id == 1 ? 1 : 0;
    this.Service.get(this.constant.API.course_details + this.courseDetailId, this.language_id)
      .subscribe(res => {
        // console.log(res,'Course Detail');
        this.courseDetails = res['courseDetails'];
        this.meta.updateTag({ name: 'title', content: 'EIAC - Course Details - ' + this.courseDetails.course }, 'name="title"');

        // console.log(this.meta);

        if (res['banner'].length > 0) {
          this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
          this.bannerTitle = res['banner'][select_language]['title'];
          this.pageData = res['banner'][select_language];
          this.bannerArabicTitle = res['banner'][0]['title'];

          if (res['banner'][select_language]['headerBanner'].length === 0) {
            this.bannerSlider.push({ image: this.banner });;
          }
          //
          if (res['banner'][select_language]['headerBanner'].length > 0) {
            res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
              if (rec.file_type != '' && rec.file_type === 'image') {
                let path = this.constant.mediaPath + rec.image;
                let enTitle = rec.title_en;
                let arTitle = rec.title_ar;
                let timer = rec.slider_time * 1000;
                this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
              }
              if (rec.file_type != '' && rec.file_type === 'video') {
                let path = this.constant.mediaPath + rec.image;
                let enTitle = rec.title_en;
                let arTitle = rec.title_ar;
                let timer = rec.slider_time * 1000;
                let vidExt = path.toString().split('.').pop();
                this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
              }
            });
          }
        }

        this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
        this.footerTitle = res['banner'][select_language].footer_title;
        this.footerDescription = res['banner'][select_language].footer_description;
        this.loader = false;
      }, error => {

      })
  }



  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.enquiryForm.ip_address = res.ip;
    });
  }
  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            //console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep)
      }
    }

  }

  onSubmit(ngForm) {
    this.is_submit = true;

    if (ngForm.form.valid && this.checkCaptchaValidation == true) {
      this.enquiryForm.enquiry_type = 'program_list';
      this.Service.post(this.constant.API.enquiry_form + 'training_service/contact_pillar_feedback', this.enquiryForm)
        .subscribe(
          res => {
            if (res['status'] == true) {
              this.is_submit = false;
              this.afterSubmit = true;
              // this.checkCaptchaValidation = false;
              // this.enquiryForm= {};
              this.enquiryForm.name = '';
              this.enquiryForm.email = '';
              this.enquiryForm.comments = '';
              this.enquiryForm.phone = '';
              this.captchaRef.reset();
              this.checkCaptchaValidation = false;

              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  message: 'Mail sent successfully.',
                  success: '1',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this,
                  index: '',
                }
              });
            } else {
              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  success: '0',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this
                }
              });
            }
          },
          error => {

          })
    }

  }

  getRevalidatedSubmit() {
    this.afterSubmit = false;
  }

  scrollForm(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  resolvedSecurity(captchaResponse: string) {
    let captchaStatus = captchaResponse;
    if (captchaStatus != '') {
      this.checkSecurity = true;
      this.checkCaptchaValidation = true;
    }
  }
}
