import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Constants } from 'src/app/services/constant.service';

@Injectable({ providedIn: 'root' })
export class AuthCheck implements CanActivate {
   public token: any;
    constructor( public router: Router, public constant:Constants) 
    {  }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.token  = (this.constant.userToken != '') ? this.constant.userToken : sessionStorage.getItem('token');
        let getToken = (this.token === null) ? '' : this.token;
        ////console.log('Auth check: 1',this.token, " ---> ", getToken);
        if ((getToken !== '' && getToken != undefined))  {
            ////console.log('Auth check: 2',getToken);
            this.router.navigate(['/dashboard']);
            return false;           
        }
        return true;       
        
    }
}