import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-schemes',
  templateUrl: './schemes.component.html',
  styleUrls: ['./schemes.component.scss']
})
export class SchemesComponent implements OnInit {
  @ViewChild('owlCar', { static: false })
  @ViewChild('owlCarBot', { static: false })

  @Input() schemeData: any;
  SubscribeCompanyChange: Subscription;
  language_id: any;

  customOptions: OwlOptions;
  imgSlides: any[] = [];
  sliderData: any[] = [];
  public customOptionsTextSlider: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ["<i class='icon-left-arrow'></i>", "<i class=icon-right-arrow></i>"],
    responsive: {
      480: {
        items: 1
      },
      768: {
        items: 1
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 2,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    },
    nav: true
  }
  slides = [
    { img: "./assets/images/slider1.png", 'text1': 'Scheme 1', 'text2': 'Food & Enviorment' },
    { img: "./assets/images/slider2.png", 'text1': 'Scheme 2', 'text2': 'Construction Meterial & Geotechnicle' },
    { img: "./assets/images/slider3.png", 'text1': 'Scheme 3', 'text2': 'Calibration Laboratories' },
  ];
  /*
  {img: "./assets/images/slider2.png", 'text1': 'Scheme 4','text2':'Construction Meterial & Geotechnicle'},
  {img: "./assets/images/slider1.png", 'text1': 'Scheme 5','text2':'Construction Meterial & Geotechnicle'},
  {img: "./assets/images/slider3.png", 'text1': 'Scheme 6','text2':'Construction Meterial & Geotechnicle'},
  */
  slideConfig = { "slidesToShow": 4, "slidesToScroll": 4 };
  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, public cdr: ChangeDetectorRef) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
    ////console.log('#Schemes data load...content',this.schemeData); 
  }

  loadSlide(myData) {
    //console.log("get data: ", myData);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('#Schemes data load...', this.schemeData, " -- ", changes, "--", this.sliderData);
    this.sliderData = [];
    if (changes != undefined && this.schemeData != undefined && this.schemeData.length > 0) {
      this.schemeData.forEach(rec => {
        let tempSlideObj: any = {};

        let cabData: any;
        let titleHead = rec.title;
        if (rec.cab_data != undefined) {
          cabData = rec.cab_data;
        }
        let titleSub = (typeof rec.scope_accridiation === 'object') ? (rec.scope_accridiation.sub_service_name != undefined && rec.scope_accridiation.sub_service_name != '') ? rec.scope_accridiation.sub_service_name : rec.scope_accridiation.service_name : '';
        const randomImage = this.imgSlides[Math.floor(Math.random() * this.imgSlides.length)];
        //console.log('img: ', randomImage.image, " :: ",titleHead, " :: ", titleSub);
        this.sliderData.push({ img: this.schemeData.image, text1: titleHead, text2: titleSub, cabData: cabData });

      })
    } else {

      //this.sliderData = this.slides;
      this.sliderData = [];
    }
  }

  ngOnInit() {
    ////console.log(this.customOptions);
    //console.log('#Schemes data load...init',this.schemeData);
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
      });
    this.imgSlides.push({ 'image': './assets/images/slider1.png' })
    this.imgSlides.push({ 'image': './assets/images/slider2.png' })
    this.imgSlides.push({ 'image': './assets/images/slider3.png' })

    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      navSpeed: 500,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        }
      },
      nav: false
    }

  }
  
  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

}
