import { Component } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { Constants } from 'src/app/services/constant.service';
import * as AOS from 'aos';
import { filter } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'eiac-frontend';
  public logout: boolean = false;
  showLoader:boolean = false;
  constructor(public router: Router, public constant:Constants, private _service: AppService) {}
  ngOnInit(){
    AOS.init({duration: 1200});
    

    // if(localStorage.getItem('logUser') != ''){
    //     let logUserData   = JSON.parse(localStorage.getItem('logUser'));
    //     if(logUserData != null){
    //       this.logout = true;
    //     }
    // }
    //Scroll to adjust page height
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //       return;
    //   }
    //   window.scrollTo(this.constant.pageTop, this.constant.pageBot);
    // });

    //Router navigate
    // this.router.events.pipe(
    //   filter(event => event instanceof RoutesRecognized)
    //     ).subscribe((e: any) => {
    //         //console.log("Router: ", e);
    //     });
    // this.router.events
    //   .filter((e: any) => e instanceof RoutesRecognized)
    //   .pairwise()
    //   .subscribe((e: any) => {
    //     //console.log("router : ",e);
    //   });


  }
  loggingOut(){
    localStorage.setItem('logUser', null);
    localStorage.setItem('token', null);
    this.router.navigateByUrl('/');
  }
}
