import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partial',
  templateUrl: './partial.component.html',
  styleUrls: ['./partial.component.scss']
})
export class PartialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
