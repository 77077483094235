import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { EnquiryDialogboxComponent } from '../../../../pages/common-all/enquiry-dialogbox/enquiry-dialogbox.component';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { RecaptchaComponent } from 'ng-recaptcha';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { UiDialogService } from '../../../../services/uiDialog.service';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-training-course-list',
  templateUrl: './training-course-list.component.html',
  styleUrls: ['./training-course-list.component.scss'],
  providers: [AppService, Constants, UiDialogService]
})
export class TrainingCourseListComponent implements OnInit, AfterViewInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  public loader: boolean = true;
  trainingCourseList: any;
  trainingPreviousCourseList: any;
  audienceList: any;
  trainingCourceId: any = '';
  audId: any = '';
  trainingCourceId2: any = '';
  audienceId: any = 0;
  trainingList: any = [];
  isTable: any = '';
  isTable2: any = '';
  targated_aud_name: any;
  allCourseTraining: any = [];
  rowCount: any = 1;
  allCourses: any;
  totalRow: any = 5;
  isViewchildData: any;
  countAllList: any;
  courselistbyTypes: any;

  bannerURL: any = '';
  bannerLinkTarget: string = '';
  bannerTitle: string = '';
  public banner: any;
  public enquiryForm: any = {};
  public is_submit: boolean = false;
  public checkSecurity: boolean = false;
  public checkCaptchaValidation: boolean = false;
  bannerImageTitle: string = '';
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  arabicTitle: any;
  englishTitle: any;
  inPremiseId: any;
  publicTrainingId: any;
  isdDynamicsopenClose: any;
  isProgramopenClose: any;
  pageHeading: any;
  scheduleProgram: any;
  coursesProgram: any;
  courseidForAud: any;
  is_data: any;
  version = VERSION;
  afterSubmit: any;
  bannerSlider: any[] = [];
  bannerArabicTitle: any;
  programlistingForm: any = {};
  programEvent: string = '';
  scheduleProgramSection: string = '';
  programSection: any;
  filterTypeCond: any;
  courseId: any;
  courseTypeId: any;
  pageData: any;

  // @ViewChildren('div') divs: QueryList<ElementRef>
  @ViewChild('destination', null) destination: ElementRef;
  @ViewChild('allprogramDestination', null) allprogramDestination: ElementRef;
  @ViewChild('inPremise', null) inPremise: ElementRef;
  @ViewChild('publicTraining', null) publicTraining: ElementRef;
  @ViewChild('captchaRef', { static: true }) captchaRef: RecaptchaComponent;
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  // @ViewChild('publicTrainingId', null) publicTrainingId: ElementRef;
  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, private meta: Meta, public Service: AppService, public uiDialog: UiDialogService, public router: Router, public sanitizer: DomSanitizer, public route: ActivatedRoute, public constant: Constants, public titleService: Title, public toastr: ToastrService, public http: HttpClient, public dialog: MatDialog) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.trainingCourceId = this.route.snapshot.queryParamMap.get('course_id');
    this.audId = this.route.snapshot.queryParamMap.get('aud_id');
    this.courseidForAud = this.route.snapshot.queryParamMap.get('courseid');
    this.isdDynamicsopenClose = this.trainingCourceId;
    this.inPremiseId = this.route.snapshot.queryParamMap.get('in_premise');
    this.publicTrainingId = this.route.snapshot.queryParamMap.get('public_training');
    this.isTable = this.trainingCourceId != '' ? '0' : '';

    this.courseId = this.route.snapshot.queryParamMap.get('courseid');
    this.courseTypeId = this.route.snapshot.queryParamMap.get('course_typeid');
    this.titleService.setTitle('EIAC - Training Course List');
    this.meta.updateTag({ name: 'title', content: 'EIAC - Training Programs' });
    this.meta.updateTag({ name: 'description', content: 'Scheduled Programs, Programs Listings' });
    this.meta.updateTag({ name: 'keywords', content: 'EIAC Training programs' });
    this.loadServiceAll();
    this.getIPAddress();

    this.isProgramopenClose = this.courseidForAud;
    this.courseTypeId && this.courseTypeId > 0 ? this.audienceType(this.courseTypeId, '', '', 'course_type') : this.audienceType(this.audienceId, 'is_data', '', 'target_audience');
    // if(this.audId && this.audId > 0)
    // {

    // }else if(!this.audId){

    // }

    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.courseTypeId && this.courseTypeId > 0 ? this.audienceType(this.courseTypeId, '', '', 'course_type') : this.audienceType(this.audienceId, 'is_data', '', 'target_audience');
        this.loadServiceAll();
      }
    );

  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
}

  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            //console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep)
      }
    }

  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  scrollToPage(id) {
    const el = document.getElementById(id);
    //console.log("@elem: ",el);
    if (el) {
      el.scrollIntoView(true);    //arguement true bypass the non-exist element or undefined
    }
  }

  ngAfterViewInit() {
    //---- Start Promise UnExceptional Error
    let p = new Promise((resolve, reject) => {
      //console.log("create promise: ");
      //reject("nope");
    });
    //console.log("setting up");
    p
      .then(r => {
      })
      .catch(e => {
      })
    //console.log("setup done");
    //---- End Promise UnExceptional Error

    if (this.trainingCourceId) {
      // const interval =  setInterval(() =>{
      //   this.destination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
      //   clearInterval(interval);
      // }, 0);
    } else if (this.audId) {
      //console.log("Enter....audId", this.allprogramDestination);
      //this.scrollToPage('allprogramDestination');
      // const interval =  setInterval(() =>{
      //   //{ behavior: "smooth", block: "start" }
      //   this.allprogramDestination.nativeElement.scrollIntoView(true);
      //   //this.scrollToPage('allprogramDestination');
      //   clearInterval(interval);
      // }, 0);
    }
    // else if(this.inPremiseId == '')
    // {
    //   // this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    //   const interval =  setInterval(() =>{
    //     this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    //     clearInterval(interval);
    //   }, 8000);
    // }
  }

  scrollForm(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  audienceType(id?: any, is_data?: any, data?: any, filter_type?: any) {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.scheduleProgramSection = '';
    this.programEvent = '';
    this.is_data = is_data;
    this.audienceId = id;
    this.allCourses = '';
    this.allCourseTraining = [];
    // this.rowCount = 1;
    this.totalRow = 5;
    this.rowCount = 1;
    this.trainingList = [];
    this.loader = true;
    //console.log(id);

    var urlSuffix = '';
    if (filter_type == 'target_audience' && id == 0) {
      urlSuffix = this.constant.API.training_course_list + 'all/' + id;
      this.filterTypeCond = 'target_audience';
    } else if (filter_type == 'target_audience' && id > 0) {
      urlSuffix = this.constant.API.training_course_list + 'audience/' + id;
      this.filterTypeCond = 'target_audience';
    } else if (filter_type == 'course_type' && id == 0) {
      urlSuffix = this.constant.API.training_course_list + 'all/' + id;
      this.filterTypeCond = 'course_type';
    } else if (filter_type == 'course_type' && id > 0) {
      urlSuffix = this.constant.API.training_course_list + 'course_type/' + id;
      this.filterTypeCond = 'course_type';
    }

    console.log("@get API: ", urlSuffix);


    this.Service.get(urlSuffix, this.language_id)
      .subscribe(
        res => {
          this.loader = false;

          if (res['banner'] && res['banner'].length > 0) {
            this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            this.bannerTitle = res['banner'][select_language]['title'];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][select_language]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][select_language]['headerBanner'].length > 0) {
              res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }

          this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
          this.footerTitle = res['banner'][select_language].footer_title;
          this.pageData = res['banner'][select_language];
          this.footerDescription = res['banner'][select_language].footer_description;
          this.pageHeading = res['page_heading'][2].page_title;
          this.scheduleProgram = res['page_heading'][0].page_title;
          this.coursesProgram = res['page_heading'][1].page_title;

          console.log("@get API: ", res);

          var targatedAudianceCourse = res['targatedAudianceCourse'];
          //this.trainingList = res['targatedAudianceCourse'];

          for (let key in targatedAudianceCourse) {
            if (targatedAudianceCourse[key].event && targatedAudianceCourse[key].event.tutor != '') {
              this.trainingList.push(targatedAudianceCourse[key]);
              if (this.audienceId == '0') {
                this.trainingList = this.getUnique(this.trainingList);
              }
              // //console.log(targatedAudianceCourse[key],'targatedAudianceCourse');
            }
          }
          // //console.log(this.trainingList,'trainingList');
          this.allCourses = res['courseList'];
          //console.log(this.allCourses,'allCourses')

          for (let i = 0; i <= this.rowCount * 4; i++) {
            if (this.allCourses[i]) {
              this.allCourseTraining.push(this.allCourses[i]);
            }
          }

          // console.log(this.allCourseTraining,'allCourseTraining');

          this.targated_aud_name = res['targatedAudName'];
          // //console.log(this.getUnique(this.trainingList),'allCourseTraining');
        },
        error => {

        },
        () => {

          if (this.courseTypeId) {
            setTimeout(() => {
              this.scrollToPage('courseTypewiseView');
            }, 1)
          }
          else if (this.audId) {
            setTimeout(() => {
              this.scrollToPage('allprogramDestination');
            }, 1)
          }
          else if (this.inPremiseId == '') {
            // this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            // const interval =  setInterval(() =>{
            //   this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            //   clearInterval(interval);
            // }, 0);
            setTimeout(() => {
              this.scrollToPage('inpremiseTraining');
            }, 1)
          }
          else if (this.trainingCourceId == '') {
            // this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            // const interval =  setInterval(() =>{
            //   this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            //   clearInterval(interval);
            // }, 0);
            setTimeout(() => {
              this.scrollToPage('allprogramDestination');
            }, 1)
          }
          else if (this.publicTrainingId == '') {
            // this.allprogramDestination.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            // const interval =  setInterval(() =>{
            //   this.publicTraining.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
            //   clearInterval(interval);
            // }, 0);
            setTimeout(() => {
              this.scrollToPage('publicTraining');
            }, 1)
          }
        }
      )
  }

  shortProgramListing(section: any) {
    // console.log('event');
    // console.log(this.programEvent,'programEvent');
    // console.log(section,'section');

    if (section == 'program_listing') {
      this.programSection = section;
      if (this.programEvent == 'coursename') {
        // this.allCourseTraining = ;
        this.allCourseTraining.sort((a, b) => (a.course.name > b.course.name) ? 1 : -1);
        // console.log()
      } else if (this.programEvent == 'audience') {
        this.allCourseTraining.sort((a, b) => (a.target_audiance > b.target_audiance) ? 1 : -1);

      } else if (this.programEvent == 'days') {
        this.allCourseTraining.sort((a, b) => (a.course.training_course.training_days > b.course.training_course.training_days) ? 1 : -1);
      }

    } else if (section == 'schedule_program_listing') {
      // this.scheduleProgramSection = section;
      if (this.scheduleProgramSection == 'coursename') {
        // this.allCourseTraining = ;
        this.trainingList.sort((a, b) => (a.course.name > b.course.name) ? 1 : -1);
        // console.log()
      } else if (this.scheduleProgramSection == 'audience') {
        this.trainingList.sort((a, b) => (a.target_audiance > b.target_audiance) ? 1 : -1);

      } else if (this.scheduleProgramSection == 'date') {
        this.trainingList.sort((a, b) => (a.event.eventDates[0].event_date > b.event.eventDates[0].event_date) ? 1 : -1);

      } else if (this.scheduleProgramSection == 'days') {
        this.trainingList.sort((a, b) => (a.course.training_course.training_days > b.course.training_course.training_days) ? 1 : -1);
      }

    }

  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.enquiryForm.ip_address = res.ip;
    });
  }
  getUnique(array) {
    var uniqueArray = [];
    // Loop through array values
    for (var key in array) {
      // //console.log(value['training_course']);
      if (uniqueArray.indexOf(array[key]['training_course']) === -1) {
        uniqueArray.push(array[key]['training_course']);
      }
    }
    return this.getmainArray(uniqueArray);
  }

  getmainArray(uniqueArray) {
    var allData = [];
    for (var key in uniqueArray) {
      // this.trainingList.find(
      //    rec => rec.training_course == uniqueArray[key]
      // );

      var result = this.trainingList.find(rec => rec.training_course === uniqueArray[key]);
      allData.push(result);
    }
    return allData;
  }
  load_more(total_show) {
    this.rowCount = this.rowCount + parseInt(total_show);
    this.totalRow = this.rowCount * 5;
    this.allCourseTraining = [];
    var courseArrLength = Object.keys(this.allCourses).length;
    for (let i = 0; i <= this.totalRow; i++) {
      if (this.allCourses[i]) {

        this.allCourseTraining.push(this.allCourses[i]);
      }
    }

    if (this.programEvent != '' && this.programSection != '') {
      this.shortProgramListing(this.programSection);
    }

    // for(let i=1; i<= this.rowCount*1; i++)
    //console.log(courseArrLength,'courseArrLength')
  }

  dynamicsopenClose(id, status) {
    if (status == 'open') {
      this.isdDynamicsopenClose = id;
    } else if (status == 'close') {
      this.isdDynamicsopenClose = '0';
    }
  }

  isOurProgramopenClose(id, status) {
    if (status == 'open') {
      this.isProgramopenClose = id;
    } else if (status == 'close') {
      this.isProgramopenClose = '0';
    }
  }

  openTable(key, trainingCourceId) {
    this.isTable = key;
    this.trainingCourceId = trainingCourceId;
    //console.log(trainingCourceId)
  }

  closeTable() {
    this.isTable = '';
    this.trainingCourceId = '';
  }

  openTable2(key, trainingCourceId2) {
    this.isTable2 = key;
    this.trainingCourceId2 = trainingCourceId2;
    // //console.log(trainingCourceId2)
  }

  closeTableN() {
    this.isTable2 = '';
    this.trainingCourceId2 = '';
  }

  loadService() {
    // this.Service.getwithoutData(this.constant.API.training_course_list)
    // .subscribe(
    //   res => {
    //     this.trainingCourseList = res['upcomming'];
    //     this.trainingPreviousCourseList = res['previous'];
    //     this.loader = false;
    //   },
    //   error => {

    //   })
  }

  loadServiceAll() {    
    this.Service.get(this.constant.API.audience_list, this.language_id)
      .subscribe(
        res => {
          this.audienceList = res['targatedAudiance'];
          this.countAllList = res['totalCourseCout'];
          this.courselistbyTypes = res['courseTypeList'];

        },
        error => {

        })

    // this.Service.getwithoutData(this.constant.API.targated_aud_training+'0')
    //   .subscribe(
    //     res => {
    //       var countAll = res['courseList'];
    //       this.countAllList = countAll.length;
    //     },
    //     error => {

    // })
    // this.Service.getwithoutData(this.constant.API.training_course_type).subscribe(
    //   res=> {
    //     this.courselistbyTypes = res['course_type'];
    //   }
    // )
  }

  onSubmit(ngForm) {
    //this.postPoll.emit();
    // if(this.checkSecurity == true)
    // {
    //   this.checkCaptchaValidation = true;
    // }else{
    //   this.checkCaptchaValidation = false;
    // }
    this.is_submit = true;


    if (ngForm.form.valid && this.checkCaptchaValidation == true) {
      this.afterSubmit = true;
      this.enquiryForm.enquiry_type = 'program_list';
      this.Service.post(this.constant.API.enquiry_form + 'training_service/contact_pillar_feedback', this.enquiryForm)
        .subscribe(
          res => {
            if (res['status'] == true) {
              this.is_submit = false;

              // this.checkCaptchaValidation = false;
              // this.enquiryForm= {};
              this.enquiryForm.name = '';
              this.enquiryForm.email = '';
              this.enquiryForm.comments = '';
              this.enquiryForm.phone = '';
              this.captchaRef.reset();
              this.checkCaptchaValidation = false;

              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  message: 'Mail sent successfully.',
                  success: '1',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this,
                  index: '',
                }
              });
            } else {
              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  success: '0',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this
                }
              });
            }
          },
          error => {

          })
    }

  }

  getRevalidatedSubmit() {
    this.afterSubmit = false;
  }

  resolvedSecurity(captchaResponse: string) {
    let captchaStatus = captchaResponse;
    if (captchaStatus != '') {
      this.checkSecurity = true;
      this.checkCaptchaValidation = true;
    }
  }
}
