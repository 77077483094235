import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Inject } from '@angular/core';
import { AppService } from '../../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PLATFORM_ID } from '@angular/core';
import { PlatformLocation, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';

@Component({
  selector: 'app-partial-header',
  templateUrl: './partial-header.component.html',
  styleUrls: ['./partial-header.component.scss'],
  providers: [AppService, Constants]
})
export class PartialHeaderComponent implements OnInit {
  isMenu: boolean = false;
  addHeaderClass: string = '';

  @Input() bannerData: any;
  qeury: any;

  curURL: string = '';
  typedQuery: boolean = false;

  control = new FormControl();
  streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets: Observable<string[]>;
  data = [
    {
      id: 1,
      name: 'Dakota Gaylord PhD',
      address: '14554 Smith Mews'
    },
    {
      id: 2,
      name: 'Maria Legros',
      address: '002 Pagac Drives'
    },
    {
      id: 3,
      name: 'Brandyn Fritsch',
      address: '8542 Lowe Mountain'
    },
    {
      id: 4,
      name: 'Glenna Ward V',
      address: '1260 Oda Summit'
    },
    {
      id: 5,
      name: 'Jamie Veum',
      address: '5017 Lowe Route'
    }
  ];
  isSearch: boolean = false;
  SubscribeCompanyChange: Subscription;
  language_id: any;  
  show_language: any = 'E'

  constructor(public NgxPubSubService: NgxPubSubService, public translate: TranslateService, @Inject(PLATFORM_ID) private platformId: any, public Service: AppService, public _router: Router, public route: ActivatedRoute, public constant: Constants) {

    if (localStorage.getItem("language_id") == '' || localStorage.getItem("language_id") == 'undefined' || localStorage.getItem("language_id") == null) {
      localStorage.setItem("language_id", this.constant.API.default_language_id);
    }
    this.language_id = localStorage.getItem("language_id");

    if (this.language_id == 1) {
      this.show_language = 'E';
    } else if (this.language_id == 2) {
      this.show_language = 'ع';
    } else {
      this.show_language = 'Change Lang';
    }

  }


  selectEvent(item) {
    // do something with selected item
    console.log("UACom select event....", item);
  }

  onChangeSearch(val: string) {
    console.log("UACom onChangeSearch event....", val);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    console.log("UACom onFocused event....", e);
    // do something when input is focused
  }
  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;

      }
    );
    // let query_srch = this.route.snapshot.queryParamMap.get('q');
    // if(query_srch != undefined && query_srch != ''){
    //   this.qeury = query_srch;
    // }
    if (isPlatformBrowser(this.platformId)) {
      this.qeury = sessionStorage.getItem('query');
      this.curURL = this._router.url;

      //Auto suggest
      this.filteredStreets = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    }

    //console.log('c url: ', this.curURL);
    //console.log("@Header: ", query_srch);
    // this.Service.getwithoutData(this.constant.API.map_box_api_value)
    // .subscribe(
    //   res =>{
    //     this.Service.mapboxToken = res['records'][0]['value'];
    //     // console.log(mapboxApiValue,'twitterUrl');
    //     console.log(this.Service.mapboxToken,'mapboxToken');
    //   },
    //   error => {

    // });
    ////console.log('@Partial header...', this.bannerData);
  }

  useLanguage(language_id) {
    this.NgxPubSubService.publishEvent('language_id', language_id);
    localStorage.setItem("language_id", language_id);
    if (this.language_id == 1) {
      this.show_language = 'E';
    } else if (this.language_id == 2) {
      this.show_language = 'ع';
    } else {
      this.show_language = 'Change Lang';
    }
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  searchTypeClose() {
    this.typedQuery = false;
    this.qeury = '';
  }
  searchType(theText: any) {
    //console.log(">>>Eneter: ", theText, " :: ", this.qeury);
    if (this.qeury != '') {
      this.typedQuery = true;
    }
    if (this.qeury === '') {
      this.typedQuery = false;
    }
  }
  ngAfterViewInit() {
    ////console.log('#Partial header...', this.bannerData);
    //this.qeury = sessionStorage.getItem('query');
  }
  ngOnChanges(changes: SimpleChanges) {
    //this.qeury = sessionStorage.getItem('query');
    //console.log('#Partial header...', this.bannerData, " -- ", changes);
    // for (let property in changes) {
    //     if (property === 'count') {
    //       //console.log('Previous:', changes[property].previousValue);
    //       //console.log('Current:', changes[property].currentValue);
    //       //console.log('firstChange:', changes[property].firstChange);
    //     } 
    // }
  }
  openMenuSearch() {
    if (this.curURL != '/search') {
      this.isSearch = !this.isSearch
      this.searchTypeClose();
    }
    if (this.curURL === '/search') {

      let elem = document.getElementById('srchKey');

      console.log("search...", elem);
      elem.scrollIntoView();

    }
  }
  openMenuShowHide() {
    this.isMenu = !this.isMenu;
    this.isSearch = false;
    if (this.isMenu) {
      this.addHeaderClass = 'menueActive';
    }
    else {
      this.addHeaderClass = '';
    }
  }

  searchClick() {
    if (isPlatformBrowser(this.platformId)) {
      let url = '/search';//+this.qeury;
      // this.qeury = ;

      //console.log("URL val: ", this._router.url)
      // if(sessionStorage.getItem('query') != undefined && sessionStorage.getItem('query') != ''){
      //   sessionStorage.setItem('query', '');
      // }
      if (this.qeury != undefined && this.qeury != '') {
        //console.log(">>>");
        sessionStorage.setItem('query', this.qeury);
        // if(curURL === '/search'){
        //   top.location.href = url;
        // }else{
        this._router.navigateByUrl('/search')
        //}
        //

      }
    }
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }
}
