import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "../../../services/app.service";
import { Constants } from "src/app/services/constant.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import {
  VERSION,
  MatDialogRef,
  MatDialog,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DialogBoxComponent } from "../../../pages/common-all/dialog-box/dialog-box.component";
import { Title, Meta } from "@angular/platform-browser";
import { BnNgIdleService } from "bn-ng-idle";
import { UiDialogService } from "../../../services/uiDialog.service";
import { DomSanitizer } from "@angular/platform-browser";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Subscription } from "rxjs";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-publication",
  templateUrl: "./publication.component.html",
  styleUrls: ["./publication.component.scss"],
  providers: [AppService, Constants, UiDialogService],
})
export class PublicationComponent implements OnInit {
  //Banner slider - slick carousel
  @ViewChild("slickSliderModal", { static: false })
  slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: "linear",
    pauseOnHover: false,
  };
  //Banner slider - slick carousel
  isPubData: boolean = true;
  isPubData2: boolean = false;
  isPubData3: boolean = false;
  isPubData4: boolean = false;
  isPubData5: boolean = false;
  addClass: String = "";
  accrediationDatas: any;
  circularTypeData: any;
  publicationData: any;
  accredationId: any;
  accreditationId: any;
  accreditationIdDynamics: any;
  fetchDatas: any;
  status: boolean = false;
  publicationId: any;
  pId: any;
  public loader: boolean = true;
  getPublicationData: any;
  isdDynamicsopenClose: any;
  publicationText: any;
  version = VERSION;
  allPublicationData: any;

  bannerURL: any = "";
  bannerLinkTarget: string = "";
  bannerTitle: string = "";

  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  arabicTitle: any;
  englishTitle: any;
  public banner: any = [];
  imageTitle: any;
  pdfPath: any;
  allCountData: any;
  allCircularCount: any;
  allLegislationsCount: any;
  getPublicationText: any;
  rowCount: any = 1;
  totalRow: any = 1;
  pubId: any;
  pubType: any;
  loadmoreCount: any;
  bannerSlider: any[] = [];
  pageData: any;
  SubscribeCompanyChange: Subscription;
  language_id: any;

  constructor(
    public NgxPubSubService: NgxPubSubService,
    private translate: TranslateService,
    private meta: Meta,
    public titleService: Title,
    public Service: AppService,
    public uiDialog: UiDialogService,
    public sanitizer: DomSanitizer,
    public constant: Constants,
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public bnIdle: BnNgIdleService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.bnIdle.startWatching(480).subscribe((res) => {
      if (res) {
        window.location.reload();
      }
    });
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? "en" : "ar");
  }
  //Slider change callback
  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === "image") {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep);
      }
      if (bannerData.type === "video") {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener(
            "ended",
            () => {
              //console.log('@video has ended...start from');
              videElem.currentTime = 0;
              videElem.pause();
            },
            false
          );
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep);
      }
    }
  }

  ngAfterViewInit() {
    let p = new Promise((resolve, reject) => {});
    //console.log("setting up");
    p.then((r) => {}).catch((e) => {});
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? "en" : "ar");
    this.titleService.setTitle("EIAC - Publication");
    this.meta.updateTag({ name: "title", content: "EIAC - Publication" });
    this.meta.updateTag({
      name: "description",
      content: "publiations, circulars, legislations",
    });
    this.meta.updateTag({
      name: "keywords",
      content: "publiations, circulars, legislations",
    });
    this.pdfPath = this.constant.mediaPath;
    this.publicationId = this.route.snapshot.queryParamMap.get("publicationId");

    this.accreditationId =
      this.publicationId && this.publicationId > 0 ? this.publicationId : "0";
    var pubId = "0";
    if (this.publicationId && this.publicationId == "100") {
      var pubtype = "legislations";

      this.meta.updateTag({ name: "title", content: "EIAC - Legislation" });
      this.meta.updateTag({
        name: "description",
        content: "publiations, circulars, legislations",
      });
      this.meta.updateTag({
        name: "keywords",
        content: "publiations, circulars, legislations",
      });
    } else if (this.publicationId && this.publicationId == "101") {
      var pubtype = "circulars";

      this.meta.updateTag({ name: "title", content: "EIAC - Circulars" });
      this.meta.updateTag({
        name: "description",
        content: "publiations, circulars, legislations",
      });
      this.meta.updateTag({
        name: "keywords",
        content: "publiations, circulars, legislations",
      });
    } else if (this.publicationId && this.publicationId == "102") {
      var pubtype = "other";

      this.meta.updateTag({ name: "title", content: "EIAC - Others" });
      this.meta.updateTag({
        name: "description",
        content: "publiations, circulars, legislations",
      });
      this.meta.updateTag({
        name: "keywords",
        content: "publiations, circulars, legislations",
      });
    } else {
      var pubtype = "publication";
    }
    this.accreditationIdDynamics =
      this.publicationId && this.publicationId > 0 ? this.publicationId : "0";
    //console.log(this.accreditationId,'accreditationId');
    this.fetchData(pubId, pubtype);
    //this.fetchDatas = this.fetchData('0');
    // if(this.publicationText == 'Publication')
    // {
    //   this.publicationText = 'PUBLICATION';
    // }else if(this.publicationText == 'Legistlations')
    // {
    //   this.publicationText = 'LEGISTLATION';
    // }else{
    //   this.publicationText = 'CIRCULARS';
    // }

    if (this.accreditationId == "100") {
      this.publicationText = this.language_id == 1 ? "LEGISLATION" : "التشريع";
    } else if (this.accreditationId == "101") {
      this.publicationText = this.language_id == 1 ? "CIRCULARS" : "الدوائر";
    } else if (this.accreditationId == "102") {
      this.publicationText = this.language_id == 1 ? "OTHERS" : "الآخرين";
    } else if (this.accreditationId == "0") {
      this.publicationText = this.language_id == 1 ? "PUBLICATION" : "النشر";
    } else if (this.accreditationId == "general") {
      this.publicationText = this.language_id == 1 ? "PUBLICATION" : "النشر";
    }
    //console.log(this.addClass);

    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe(
      "language_id",
      (data) => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? "en" : "ar");
        this.fetchData(pubId, pubtype);

        this.publicationId =
          this.route.snapshot.queryParamMap.get("publicationId");

        this.accreditationId =
          this.publicationId && this.publicationId > 0
            ? this.publicationId
            : "0";
        if (this.accreditationId == "100") {
          this.publicationText =
            this.language_id == 1 ? "LEGISLATION" : "التشريع";
        } else if (this.accreditationId == "101") {
          this.publicationText =
            this.language_id == 1 ? "CIRCULARS" : "الدوائر";
        } else if (this.accreditationId == "102") {
          this.publicationText = this.language_id == 1 ? "OTHERS" : "الآخرين";
        } else if (this.accreditationId == "0") {
          this.publicationText =
            this.language_id == 1 ? "PUBLICATION" : "النشر";
        } else if (this.accreditationId == "general") {
          this.publicationText =
            this.language_id == 1 ? "PUBLICATION" : "النشر";
        }
      }
    );
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

  accreditationType(event, text, accredate_id = "") {
    this.accreditationId = "";
    this.accreditationIdDynamics = "";
    // //console.log(event,'event');
    // //console.log(text,'text');
    // //console.log(accredate_id,'accredate_id');
    if (text == "") {
      this.getPublicationData = "";
      this.accreditationId = accredate_id;
      this.accreditationIdDynamics = event;
      this.fetchData(event);
      this.publicationText = this.language_id == 1 ? "PUBLICATION" : "النشر";
    } else if (text == "general") {
      this.getPublicationData = "";
      this.accreditationId = accredate_id;
      this.accreditationIdDynamics = event;
      this.fetchData(event);
      this.publicationText = this.language_id == 1 ? "PUBLICATION" : "النشر";
    } else if (text == "others") {
      this.getPublicationData = "";
      this.accreditationId = accredate_id;
      this.accreditationIdDynamics = event;
      this.fetchData(event, "other");
      this.publicationText = this.language_id == 1 ? "Applications, Agreements and Checklists" : "التطبيقات والاتفاقيات وقوائم المراجعة";
    } else {
      this.accreditationId = accredate_id;
      this.accreditationIdDynamics = event;
      this.getPublicationData = text;
      this.getPublicationText = text;
      this.getPublicationData == "Legistlations"
        ? this.fetchData(event, "legislations")
        : this.fetchData(event, "circulars");
      this.publicationText =
        text == "Legistlations" ? "LEGISLATION" : "CIRCULARS";
      if(this.publicationText=='LEGISLATION'){
        this.publicationText = this.language_id==1 ? 'LEGISLATION' : 'التشريع';
      }
      if(this.publicationText=='CIRCULARS'){
        this.publicationText = this.language_id==1 ? 'CIRCULARS' : 'الدوائر';
      }
    }

    // //console.log(this.publicationText,'publicationText');
    //this.accredationId = event.target.getAttribute('data-id');
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  fetchData(id, pub_type = "publication") {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.loader = true;
    this.getPublicationData = "";
    if (id != "") {
      this.pubId = id;
      this.pubType = pub_type;
      this.loadmoreCount = "";
      this.totalRow = 5;
      this.rowCount = 1;
      //var pub_type = 'publication'
      ////console.log(this.constant.API.publications,id +'&'+pub_type,'idddddddd');
console.log(this.pubType,'  ',this.pubId);
      this.Service.getwithoutData(
        this.constant.API.publications +
          "?data=" +
          id +
          "&pub_type=" +
          pub_type +
          "&count=" +
          this.totalRow +
          "&language_id=" +
          this.language_id
      ).subscribe(
        (res) => {
          // this.countryLists = res['country'];
          // this.contactusTypes = res['contactus_type'];
          this.loader = false;
          this.accrediationDatas = res["accrediation_data"];
          this.circularTypeData = res["circularTypeData"];
          this.publicationData = res["publication_data"];
          this.allCountData = res["allCount"];
          this.allCircularCount = res["allCount"].allCircularCount;
          this.allLegislationsCount = res["allCount"].allLegislationsCount;
          this.accreditationId =
            this.accreditationId > 0 ? this.accreditationId : "0";
          this.status = this.publicationData.length < 1 ? true : false;

          this.banner =
            this.constant.mediaPath +
            res["banner"][select_language]["banner"]["image"];

          this.footerBanner =
            this.constant.mediaPath +
            res["banner"][select_language]["banner"]["footer_image"];
          this.footerTitle = res["banner"][select_language].footer_title;
          this.footerDescription =
            res["banner"][select_language].footer_description; //english

          this.bannerTitle =
            res["banner"][select_language]["banner"]["image_name"] != null
              ? res["banner"][select_language]["banner"]["image_name"]
              : "";
          //res['banner'][0]['banner']['url'] = 'https://image.shutterstock.com/image-photo/dubai-summer-2016-modern-desalination-600w-729868966.jpg';
          this.bannerURL =
            res["banner"][select_language]["banner"]["url"] != ""
              ? this.getSantizeUrl(
                  res["banner"][select_language]["banner"]["url"]
                )
              : "";
          this.bannerLinkTarget =
            res["banner"][select_language]["banner"]["url"] != null
              ? "_blank"
              : "_self";
          this.arabicTitle = res["banner"][0]["title"];
          this.englishTitle = res["banner"][1]["title"];
          this.pageData = res["banner"][select_language];
          this.imageTitle =
            res["banner"][select_language]["banner"]["image_name"];

          //Banner Slider
          // this.bannerSlider.push({image:'https://service.eiac.gov.ae/media/banner/image/Registration.jpg', type: 'image',
          //   enTitle: this.englishTitle + " - " + 'Slider', arTitle: this.arabicTitle, delay: 5000});
          if (res["banner"][select_language]["headerBanner"].length === 0) {
            this.bannerSlider.push({
              image: this.banner,
              type: "image",
              enTitle: this.englishTitle,
              arTitle: this.arabicTitle,
              delay: 0,
            });
          }
          if (res["banner"][select_language]["headerBanner"].length > 0) {
            res["banner"][select_language]["headerBanner"].forEach(
              (rec, index) => {
                if (rec.file_type != "" && rec.file_type === "image") {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000; // convert milliseconds
                  this.bannerSlider.push({
                    image: path,
                    type: rec.file_type,
                    enTitle: enTitle,
                    arTitle: arTitle,
                    delay: timer,
                  });
                }
                if (rec.file_type != "" && rec.file_type === "video") {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000; // convert milliseconds
                  let vidExt = path.toString().split(".").pop();
                  this.bannerSlider.push({
                    id: rec.id,
                    ext: vidExt,
                    video: path,
                    type: rec.file_type,
                    enTitle: enTitle,
                    arTitle: arTitle,
                    delay: timer,
                  });
                }
              }
            );
          }
          //Banner Slider
console.log(this.pubType,'  ',this.pubId);
          if (this.pubType == "publication" && this.pubId != 0  && this.pubId != 'general') {
            this.accrediationDatas.forEach((res2) => {
              if (res2.service_page.id == this.pubId) {
                this.loadmoreCount = res2.publication_count.pubCount;
              }
            });
          } else if (this.pubType == "publication" && this.pubId == "general") {
            this.loadmoreCount = this.allCountData.generalCount;
console.log(this.pubType,'  ',this.pubId,' ',this.loadmoreCount);
          } else if (this.pubType == "publication" && this.pubId == 0) {
            this.loadmoreCount = this.allCountData.allPubCount;
          } else if (this.pubType == "circulars" && this.pubId != 0) {
            this.circularTypeData.forEach((res2) => {
              if (res2.service_page.id == this.pubId) {
                this.loadmoreCount = res2.publication_count.circularCount;
              }
            });
          } else if (this.pubType == "circulars" && this.pubId == 0) {
            this.loadmoreCount = this.allCircularCount;
          } else if (this.pubType == "other" && this.pubId == 0) {
            this.loadmoreCount = this.allCountData.otherCount;
          } else if (this.pubType == "legislations" && this.pubId != 0) {
            this.accrediationDatas.forEach((res2) => {
              if (res2.service_page.id == this.pubId) {
                this.loadmoreCount = this.allLegislationsCount;
              }
            });
          } else if (this.pubType == "legislations" && this.pubId == 0) {
            this.loadmoreCount = this.allLegislationsCount;
          }

          // //console.log('============')
          this.loader = false;
console.log(this.pubType,'  ',this.pubId,' ',this.loadmoreCount);
        },
        (error) => {}
      );
    } else {
      this.Service.getwithoutData(
        this.constant.API.publicationsAllData +
          "?language_id=" +
          this.language_id
      ).subscribe(
        (res) => {
          this.publicationData = res["publication_data"];

          // //console.log('============')
          this.loader = false;
        },
        (error) => {}
      );
    }
  }

  load_more(total_show) {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.loader = true;
    this.rowCount = this.rowCount + parseInt(total_show);
    this.totalRow = this.rowCount * 5;
    // for(let i=1; i<= this.rowCount*1; i++)
    ////console.log(courseArrLength,'courseArrLength')
    this.Service.getwithoutData(
      this.constant.API.publications +
        "?data=" +
        this.pubId +
        "&pub_type=" +
        this.pubType +
        "&count=" +
        this.totalRow +
        "&language_id=" +
        this.language_id
    ).subscribe(
      (res) => {
        // this.countryLists = res['country'];
        // this.contactusTypes = res['contactus_type'];
        this.loader = false;
        this.accrediationDatas = res["accrediation_data"];
        this.circularTypeData = res["circularTypeData"];
        this.publicationData = res["publication_data"];
        this.allCountData = res["allCount"];
        this.allCircularCount = res["allCount"].allCircularCount;
        this.allLegislationsCount = res["allCount"].allLegislationsCount;
        this.accreditationId =
          this.accreditationId > 0 ? this.accreditationId : "0";
        this.status = this.publicationData.length < 1 ? true : false;

        this.banner =
          this.constant.mediaPath +
          res["banner"][select_language]["banner"]["image"];

        this.footerBanner =
          this.constant.mediaPath +
          res["banner"][select_language]["banner"]["footer_image"];
        this.footerTitle = res["banner"][select_language].footer_title;
        this.footerDescription =
          res["banner"][select_language].footer_description; //english

        this.bannerTitle =
          res["banner"][select_language]["banner"]["image_name"] != null
            ? res["banner"][select_language]["banner"]["image_name"]
            : "";
        //res['banner'][0]['banner']['url'] = 'https://image.shutterstock.com/image-photo/dubai-summer-2016-modern-desalination-600w-729868966.jpg';
        this.bannerURL =
          res["banner"][select_language]["banner"]["url"] != ""
            ? this.getSantizeUrl(
                res["banner"][select_language]["banner"]["url"]
              )
            : "";
        this.bannerLinkTarget =
          res["banner"][select_language]["banner"]["url"] != null
            ? "_blank"
            : "_self";
        this.arabicTitle = res["banner"][0]["title"];
        this.englishTitle = res["banner"][1]["title"];
        this.imageTitle =
          res["banner"][select_language]["banner"]["image_name"];

        if (this.pubType == "publication" && this.pubId != 0) {
          this.accrediationDatas.forEach((res2) => {
            if (res2.service_page.id == this.pubId) {
              this.loadmoreCount = res2.publication_count.pubCount;
            }
          });
        } else if (this.pubType == "publication" && this.pubId == 0) {
          this.loadmoreCount = this.allCountData.allPubCount;
        } else if (this.pubType == "publication" && this.pubId == "general") {
          this.loadmoreCount = this.allCountData.generalCount;
        } else if (this.pubType == "circulars" && this.pubId != 0) {
          this.circularTypeData.forEach((res2) => {
            if (res2.service_page.id == this.pubId) {
              this.loadmoreCount = res2.publication_count.circularCount;
            }
          });
        } else if (this.pubType == "circulars" && this.pubId == 0) {
          this.loadmoreCount = this.allCircularCount;
        } else if (this.pubType == "other" && this.pubId == 0) {
          this.loadmoreCount = this.allCountData.otherCount;
        } else if (this.pubType == "legislations" && this.pubId != 0) {
          this.accrediationDatas.forEach((res2) => {
            if (res2.service_page.id == this.pubId) {
              this.loadmoreCount = this.allLegislationsCount;
            }
          });
        } else if (this.pubType == "legislations" && this.pubId == 0) {
          this.loadmoreCount = this.allLegislationsCount;
        }
        // console.log(this.loadmoreCount,'loadmoreCount');
        // //console.log('============')
        this.loader = false;
      },
      (error) => {}
    );
  }
  openDialog(obj: any, index: number) {
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      data: {
        message: "Are you sure want to delete?",
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
        obj: obj,
        index: index,
      },
    });
  }

  dynamicsopenClose(id, status) {
    if (status == "open") {
      this.isdDynamicsopenClose = id;
    } else if (status == "close") {
      this.isdDynamicsopenClose = "0";
    }
    //console.log(this.isdDynamicsopenClose);
    //console.log(status);
  }
  OpenPubData() {
    this.isPubData = !this.isPubData;
    if (!this.isPubData) {
      //console.log('======================')
      this.addClass = "menueActive";
    }
  }
  OpenPubData2() {
    this.isPubData2 = !this.isPubData2;
  }
  OpenPubData3() {
    this.isPubData3 = !this.isPubData3;
  }
  OpenPubData4() {
    this.isPubData4 = !this.isPubData4;
  }
  OpenPubData5() {
    this.isPubData5 = !this.isPubData5;
  }
}
