import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.service';
import { AuthCheck } from './services/auth.check.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
// import { AppCustomPreloader } from './app-routing-loader';
import { AppService } from 'src/app/services/app.service';
//Parent Route
import { TrainingServiceComponent } from './pages/application-form/trainingComponent/training-service/training-service.component';
import { RegistrationServiceComponent } from './pages/application-form/registrationComponent/registration-service/registration-service.component'; 
import { AccreditationServiceComponent } from './pages/application-form/accreditationComponent/accreditation-service/accreditation-service.component';
import { ServiceComponent } from './pages/application-form/service/service.component';
import { AsscssorsServiceComponent } from './pages/application-form/assessorsComponent/asscssors-service/asscssors-service.component';

import { HomeComponent } from './pages/home/component/home.component';
import { TrainingEventComponent } from './pages/application-form/trainingComponent/training-event/training-event.component';
import { TrainingCourseListComponent } from './pages/application-form/trainingComponent/training-course-list/training-course-list.component';
import { TrainingCourceDetailComponent } from './pages/application-form/trainingComponent/training-cource-detail/training-cource-detail.component';
import { PublicationComponent } from './pages/application-form/publication/publication.component';

import { SearchPageComponent } from './pages/search-page/search-page.component';

//Start Routing
const routes: Routes = [ 
  {
    path: '', 
    children: [                          //<---- child components declared here
      // {
      //   path: '', 
      //   loadChildren: () => import('./pages/home/home.module').then(mod => mod.HomeModule),
      //   data: { preload: true }
      // },
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'feedback',
        loadChildren: () => import('./pages/eparticipate/eparticipate.module').then(mod => mod.EparticipateModule)
      },
      {
        path: 'support', 
        loadChildren: () => import('./pages/faq/faq.module').then(mod => mod.FaqModule)
      },
      {
        path: 'service', 
        loadChildren: () => import('./pages/service/service.module').then(mod => mod.ServiceModule)
      },
      {
        path: 'profile-completion',
        loadChildren: () => import('./pages/profile-completion/profile-completion.module').then(mod => mod.ProfileCompletionModule)
      },
      {
        path: 'application-form',
        loadChildren: () => import('./pages/application-form/application-form.module').then(mod => mod.ApplicationFormModule)
      },
      {
        path: 'customer-poll',
        loadChildren: () => import('./pages/customer-poll/customer-poll.module').then(mod => mod.CustomerPollModule)
      },
      {
        path: 'support/contact-us', 
        loadChildren: () => import('./pages/contact-us/contact-us.module').then(mod => mod.ContactUsModule)
      },
      {
        path: 'common', 
        loadChildren: () => import('./pages/common-all/common-all.module').then(mod => mod.CommonAllModule)
      },
      {
        path: 'directory', 
        loadChildren: () => import('./pages/cab-directory/cab-directory.module').then(mod => mod.CabDirectoryModule)
      },
      {
        path: 'about-us', 
        loadChildren: () => import('./pages/about-us/about-us.module').then(mod => mod.AboutUsModule)
      }
    ]
  },
  {
    path: 'sign-up', canActivate: [ AuthCheck ],
    loadChildren: () => import('./pages/before-login-modules/signup/signup.module').then(mod => mod.SignupModule)
  },
  {
    path: 'customer-satisfaction-survey',
    loadChildren: () => import('./pages/customer-satisfaction-survey/customer-satisfaction-survey.module').then(mod => mod.CustomerSatisfactionSurveyModule)
  },
  {
    path: 'search',
    component: SearchPageComponent,
  },
  {
    path: 'accreditation-service',
    component: AccreditationServiceComponent,
  },
  {
    path: 'documents/publications',
    component: PublicationComponent,
  },
  {
    path: 'assessors-service',
    component: AsscssorsServiceComponent,
  },
  {
    path: 'registration-service',
    component: RegistrationServiceComponent,
  },
  {
    path: 'training-service',
    component: TrainingServiceComponent,
  },
  {
    path: 'training-event',
    component: TrainingEventComponent,
  },
  {
    path: 'training-course-list',
    component: TrainingCourseListComponent,
  },
  {
    path: 'training-course-detail/:id',
    component: TrainingCourceDetailComponent,
  },
  {
      path: 'service/:flag',
      component: ServiceComponent,
  },
  {
    path: 'user', canActivate: [ AuthCheck ],
    loadChildren: () => import('./pages/before-login-modules/signin/signin.module').then(mod => mod.SigninModule)
  },
  {
    path: 'eiac-portal',
    loadChildren: () => import('./portal/portal.module').then(mod => mod.PortalModule)
  },
  {
    path: 'profile-completion',
    loadChildren: () => import('./pages/profile-completion/profile-completion.module').then(mod => mod.ProfileCompletionModule)
  },
  {
    path:'dashboard', canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(mod => mod.DashboardModule)
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },  
];


// const routes: Routes = [
//   { path: '404', component: NotFoundComponent },
//   { path: '**', redirectTo: '404' }, 
//   {
//     path: '', 
//     loadChildren: () => import('./pages/home/home.module').then(mod => mod.HomeModule)
//   },
// ];

// @NgModule({
//   imports: [RouterModule.forRoot(routes),{ preloadingStrategy: AppCustomPreloader }],
//   exports: [RouterModule]
// })


@NgModule({
  imports: [RouterModule.forRoot(routes,{ 
    // preloadingStrategy: AppCustomPreloader,
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule],
  providers: [AppService]
})
export class AppRoutingModule { }
