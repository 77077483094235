import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {
  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DialogBoxComponent>) { 
    if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        
      }
      
    }
  }

  ngOnInit() {
  }

  onConfirmClick(): void {
    if(this.data.obj != '' && this.data.index != ''){
      this.data.obj.splice(this.data.index, 1);
    }
    else if(this.data.authorization_checked === true)
    {
      this.data.authorization_checked = true;
    }
    this.dialogRef.close({authorization:true});
  }

  onConfirmClose(): void {
    this.dialogRef.close({authorization:false});
  }

}
