import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { Title, Meta } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FooterComponent } from '../../../partial/component/footer/footer.component';
import { UiDialogService } from '../../../../services/uiDialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { EnquiryDialogboxComponent } from '../../../../pages/common-all/enquiry-dialogbox/enquiry-dialogbox.component';
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-service',
  templateUrl: './registration-service.component.html',
  styleUrls: ['./registration-service.component.scss'],
  providers: [AppService, Constants, UiDialogService] 
})
export class RegistrationServiceComponent implements OnInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  public loader: boolean = true;
  public serviceList: any = [];
  public pageList: any = [];
  public service_steps: any = [];
  public service_requirements: any = {};
  public service_provider: any = [];
  public service_channel: any = [];
  public banner: any = [];
  public enquiryForm: any = {};
  public is_submit: boolean = false;
  public checkSecurity: boolean = false;
  public checkCaptchaValidation: boolean = false;
  arabicTitle: any;
  englishTitle: any;

  bannerURL: any = '';
  bannerLinkTarget: string = '';
  bannerTitle: string = '';
  records: any;
  records_year: any;
  records_types: any;
  records_cabs: any;
  records_certificates: any;
  getServicesDescription: any
  other_title: any;
  other_description: any;
  footerBanner: any;
  footerTitle: any;
  footerDescription: any;
  version = VERSION;
  afterSubmit: any;
  bannerSlider: any[] = [];
  bannerArabicTitle: any;
  pageData: any;

  @ViewChild(FooterComponent, { static: false }) child: FooterComponent;
  @ViewChild('targetServDesc', null) targetServDesc: ElementRef;
  @ViewChild('slickSliderModal', { static: false }) slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  constructor(private translate: TranslateService, public NgxPubSubService: NgxPubSubService, private meta: Meta, public Service: AppService, public constant: Constants, public uiDialog: UiDialogService,
    public titleService: Title, public vps: ViewportScroller, public toastr: ToastrService, public sanitizer: DomSanitizer, public http: HttpClient, public dialog: MatDialog) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  // openForm(meta: string){
  //   console.log("@Meta: ", meta);
  //   let getURL: string = '';
  //   switch(meta){

  //     case 'no-objection-certificate':
  //       getURL = this.constant.portal_SERV + 'dashboard/cab_client/no-objection-certificate-form';
  //       window.open(getURL)
  //     break;
  //     case 'work-permit':
  //       getURL = this.constant.portal_SERV + 'dashboard/cab_client/work-permit-form';
  //       window.open(getURL)
  //     break;     

  //     default:
  //     break;
  //   }
  // }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.loadService();
      }
    );
    this.titleService.setTitle('EIAC - REGISTRATION SERVICES');
    this.meta.updateTag({ name: 'title', content: 'EIAC - Registration Services' });
    this.meta.updateTag({ name: 'description', content: 'Requests Accelerated' });
    this.meta.updateTag({ name: 'keywords', content: 'No Objection Certificate, Work Activity permit' });
    this.getIPAddress();
    this.loadService();
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }


  afterChange(theEvt: any) {
    //console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      //console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === 'image') {
        let sleep: number = bannerData.delay;
        //console.log('image pause for ', sleep,' sec');
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
          //console.log('image slider start....');
        }, sleep)
      }
      if (bannerData.type === 'video') {
        let sleep: number = bannerData.delay; //miliseconds timer delay
        this.slickSliderModal.slickPause();
        //console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(vidid.toString());
        //console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Duration: ", videElem.duration);
        if (videElem.paused) {
          videElem.play();
        }
        setTimeout(() => {
          videElem.addEventListener('ended', () => {
            //console.log('@video has ended...start from');
            videElem.currentTime = 0;
            videElem.pause();
          }, false);
          this.slickSliderModal.slickPlay();
          //console.log('slider start....');
        }, sleep)
      }
    }

  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.enquiryForm.ip_address = res.ip;
    });
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openHappiness() {
    this.child.initOpen('Test');
  }

  serviceLists(el: HTMLElement) {
    //this.vps.scrollToAnchor(el);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  scrollForm(type: string) {
    //console.log('calling scroll...');
    //el.scrollIntoView({behavior: 'smooth'});
    switch (type) {
      case 'targetServDesc':
        this.targetServDesc.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
    }
  }

  loadService() {
    // this.Service.get(this.constant.API.service_api,'registration_service')
    //     .subscribe(
    //       res => {
    //         this.serviceList  = res['data']
    //       },
    //       error => {

    //   })
    let select_language = this.language_id == 1 ? 1 : 0;
    this.Service.getWithMultipleParams(this.constant.API.service_details_page, 'registration_service', this.language_id)
      .subscribe(
        res => {
          this.serviceList = res['allServiceList'];
          this.pageList = res['data'][0]
          this.service_steps = JSON.parse(this.pageList.service_steps)
          this.service_requirements = JSON.parse(this.pageList.service_requirements)
          this.service_provider = JSON.parse(this.pageList.service_provider)
          this.service_channel = Object.values(JSON.parse(this.pageList.service_channel))
          this.banner = this.constant.mediaPath + res['banner'][0]['banner']['image'];
          this.getServicesDescription = JSON.parse(this.pageList.other_description);
          this.other_title = this.getServicesDescription.title;
          this.other_description = this.getServicesDescription.description;

          this.records = JSON.parse(res['records']);
          this.records_year = res['years'] > 0 ? res['years'] : 0;
          this.records_types = this.records.types;
          this.records_cabs = this.records.cabs;
          this.records_certificates = this.records.certificates;

          // this.bannerTitle = (res['banner'][0]['banner']['image_name'] != null) ? res['banner'][0]['banner']['image_name'] : '';
          // this.bannerURL = (res['banner'][0]['banner']['url'] != '') ? this.getSantizeUrl(res['banner'][0]['banner']['url']) : '';
          // this.bannerLinkTarget = (res['banner'][0]['banner']['url'] != null) ? '_blank' : '_self';

          // this.footerBanner = this.constant.mediaPath+res['banner'][0]['banner']['footer_image'];
          // this.footerTitle = res['banner'][1].footer_title;
          // this.footerDescription = res['banner'][1].footer_description;

          // this.arabicTitle = res['banner'][0]['title'];
          // this.englishTitle = res['banner'][1]['title'];

          if (res['banner'].length > 0) {
            this.banner = this.constant.mediaPath + res['banner'][select_language]['banner']['image'];
            this.bannerTitle = res['banner'][select_language]['title'];
            this.pageData = res['banner'][select_language];
            this.bannerArabicTitle = res['banner'][0]['title'];

            if (res['banner'][select_language]['headerBanner'].length === 0) {
              this.bannerSlider.push({ image: this.banner });;
            }
            //
            if (res['banner'][select_language]['headerBanner'].length > 0) {
              res['banner'][select_language]['headerBanner'].forEach((rec, index) => {
                if (rec.file_type != '' && rec.file_type === 'image') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  this.bannerSlider.push({ image: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
                if (rec.file_type != '' && rec.file_type === 'video') {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle = rec.title_en;
                  let arTitle = rec.title_ar;
                  let timer = rec.slider_time * 1000;
                  let vidExt = path.toString().split('.').pop();
                  this.bannerSlider.push({ id: rec.id, ext: vidExt, video: path, type: rec.file_type, enTitle: enTitle, arTitle: arTitle, delay: timer });
                }
              });
            }
          }

          this.footerBanner = this.constant.mediaPath + res['banner'][select_language]['banner']['footer_image'];
          this.footerTitle = res['banner'][select_language].footer_title;
          this.footerDescription = res['banner'][select_language].footer_description;
          this.loader = false;
        },
        error => {

        })
  }
  onSubmit(ngForm) {
    //this.postPoll.emit();
    // if(this.checkSecurity == true)
    // {
    //   this.checkCaptchaValidation = true;
    // }else{
    //   this.checkCaptchaValidation = false;
    // }
    this.is_submit = true;
    if (ngForm.form.valid && this.checkCaptchaValidation == true) {
      this.enquiryForm.enquiry_type = 'registration_service';
      this.Service.post(this.constant.API.enquiry_form + 'registration-service/contact_pillar_feedback', this.enquiryForm)
        .subscribe(
          res => {
            if (res['status'] == true) {
              this.is_submit = false;
              this.afterSubmit = true;
              this.enquiryForm.name = '';
              this.enquiryForm.email = '';
              this.enquiryForm.comments = '';
              this.enquiryForm.phone = '';
              // this.checkCaptchaValidation = false;
              // this.enquiryForm= {};
              // this.toastr.success(res['msg'], '');
              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  message: 'Mail sent successfully.',
                  success: '1',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this,
                  index: '',
                }
              });
            } else {
              const dialogRef = this.dialog.open(EnquiryDialogboxComponent, {
                width: "400px",
                data: {
                  success: '0',
                  buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel'
                  },
                  obj: this
                }
              });
            }
          },
          error => {

          })
    }

  }

  getRevalidatedSubmit() {
    this.afterSubmit = false;
  }

  resolvedSecurity(captchaResponse: string) {
    let captchaStatus = captchaResponse;
    if (captchaStatus != '') {
      this.checkSecurity = true;
      this.checkCaptchaValidation = true;
    }
  }

}
