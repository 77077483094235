import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
//import { API } from 'src/app/services/constant';
import { Constants } from "../../../services/constant.service";
import { AppService } from "../../../services/app.service";
//import { ElasticsearchService } from 'src/app/services/elasticsearch.service';
import { Client } from "elasticsearch-browser";
import { TranslateService } from "@ngx-translate/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Router } from "@angular/router";
import { LoaderService } from "src/app/services/loader.service";
import {
  VERSION,
  MatDialogRef,
  MatDialogConfig,
  MatDialog,
  MatSnackBar,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { HappinessMeterComponent } from "../../../pages/common-all/happiness-meter/happiness-meter.component";
import { empty as observableEmpty, Subscription } from "rxjs";
import { Title, Meta } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";
import { UiDialogService } from "../../../services/uiDialog.service";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ShareModule } from "@ngx-share/core";
import { PlayMediaComponent } from "../../../pages/common-all/play-media/play-media.component";
//import { time } from 'console';
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [Constants, AppService, LoaderService, UiDialogService],
})
export class HomeComponent implements OnInit {
  @ViewChild("owlCar", { static: false })
  @ViewChild("owlCarBot", { static: false })
  @ViewChild("videoPlayer", { static: false })
  videoPlayer: ElementRef;

  //Banner slider - slick carousel
  @ViewChild("slickSliderModal", { static: false })
  slickSliderModal: SlickCarouselComponent;
  bannerSliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    fade: true,
    autoplay: true,
    draggable: true,
    speed: 1000,
    cssEase: "linear",
    pauseOnHover: false,
    nextArrow:
      "<div class='nav-btn next-slide'><span class=icon-right-arrow></span></div>",
    prevArrow:
      "<div class='nav-btn prev-slide'><span class=icon-left-arrow></span></div>",
  };
  //Banner slider - slick carousel

  public customOptionsTextSlider: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: [
      "<i class='icon-left-arrow'></i>",
      "<i class=icon-right-arrow></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
  };
  public customOptionsTextSlider1: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: [
      "<i class='icon-left-arrow'></i>",
      "<i class='icon-right-arrow'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: false,
  };

  listArray: any = [];
  userId: any;
  public logUserName: string;
  isConnected = false;
  status: string;
  logStatus: boolean = false;
  addngClass: string = "";
  easeContent: any;
  easeContentTitle: any;
  aboutContent: any;
  aboutContentTitle: any;
  serviceDescriptionImage: any;
  slides = [
    { img: "./assets/images/slider1.png", text: "Power Plant CAB" },
    { img: "./assets/images/slider2.png", text: "Medical Accredited CAB" },
    { img: "./assets/images/slider3.png", text: "Halal Accredited CAB" },
  ];
  @ViewChild("slickModal", { static: false })
  slickModal: SlickCarouselComponent;
  // slideConfig = {
  //   "slidesToShow": 4,
  //   "slidesToScroll":4,
  //   "dots": true,
  //   "infinite": true,
  //   "nextArrow": "<div class='nav-btn next-slide'><span class=icon-right-arrow></span></div>",
  //   "prevArrow": "<div class='nav-btn prev-slide'><span class=icon-left-arrow></span></div>",
  // };

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    nextArrow:
      "<div class='nav-btn next-slide'><span class=icon-right-arrow></span></div>",
    prevArrow:
      "<div class='nav-btn prev-slide'><span class=icon-left-arrow></span></div>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  isDropout: boolean = false;
  isMenu: boolean = false;
  version = VERSION;
  serviceList: any;
  serviceHeaderList: any;
  servicCloneeHeaderList: any[] = [];

  bannerTitle: any;
  bannerArabicTitle: any;
  public dataLoading: boolean = null;
  public subscriptions: Subscription[] = [];
  homepageContent: any;
  homepageContents: any;
  homepageSteps: any;

  homepageResourcesTitle: any;
  homepageResourcesContent: any;

  homepageWeHelpYouTitle: any;
  homepageWeHelpYouTitleContent: any;

  homepageDontHaveWasteTimetitle: any;
  homepageDontHaveWasteTimeContent: any;
  homepageDontHaveWasteTimeAfterContent: any;

  homepageaboutTitle: any;
  homepageaboutTitleContent: any;
  homepageaboutTitleAfterContent: any;

  homepageWorkwithusTitle: any;
  homepageWorkwithusTitleContent: any;

  homepageTableContent: any;
  homepagePlusiconContent: any;

  allDataNewsAnnouncements: any;
  tableData: any;
  tableTitle: any;

  footerBanner: any;
  footerTitle: any;
  footerDescription: any;

  sliderFixedImage: string;
  sliderNavId: number = 0;
  sliderStatus: string = "";

  bannerSlider: any[] = [];

  fixedImage: string = "./assets/images/slider1.png";
  userInteract: boolean = false;
  videoDialogRef: any = null;
  videoControls: boolean = false;
  openButton: boolean = false;
  pageData: any;
  qeury: any;
  typedQuery: boolean = false;
  isSearch: boolean = false;
  SubscribeCompanyChange: Subscription;
  language_id: any;
  show_language: any = "E";
  public banner: any = [];

  serviceHeader: any[] = [];

  //public es: ElasticsearchService,
  constructor(
    public NgxPubSubService: NgxPubSubService,
    private meta: Meta,
    public titleService: Title,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public _router: Router,
    public constant: Constants,
    public Service: AppService,
    public cd: ChangeDetectorRef,
    public loaderService: LoaderService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public uiDialog: UiDialogService
  ) {
    if (
      localStorage.getItem("language_id") == "" ||
      localStorage.getItem("language_id") == "undefined" ||
      localStorage.getItem("language_id") == null
    ) {
      localStorage.setItem(
        "language_id",
        this.constant.API.default_language_id
      );
    }
    this.language_id = localStorage.getItem("language_id");

    if (this.language_id == 1) {
      this.show_language = "E";
    } else if (this.language_id == 2) {
      this.show_language = "ع";
    } else {
      this.show_language = "Change Lang";
    }
    translate.setDefaultLang(this.language_id == 1 ? "en" : "ar");
  }

  ngOnInit() {
    this.titleService.setTitle("EIAC - Home");
    this.meta.updateTag({
      name: "title",
      content: "EIAC - Professionalism in Accreditation",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "Reliable and Efficient Accreditation is your highway to success",
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "accreditation streamlined, requests fullfilled, comprehensive training ",
    });
    this.translate.use(this.language_id == 1 ? "en" : "ar");
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe(
      "language_id",
      (data) => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? "en" : "ar");
        this.bannerSlider = [];
        this.loadService();
        this.loadServiceHomepage();
      }
    );

    this.sliderFixedImage = "./assets/images/slider1.png";

    this.serviceHeader.push({ title: "Accredited" });
    this.serviceHeader.push({ title: "Suspended" });
    this.serviceHeader.push({ title: "Voluntarily Withdrawn" });
    this.serviceHeader.push({ title: "Voluntary Suspended" });
    this.serviceHeader.push({ title: "Withdrawn" });

    //document.getElementById("targetList").scrollIntoView();

    // setTimeout(()=> {
    //   var elmnt = document.getElementById("targetList");
    // //////console.log('elmnt',elmnt);
    //   elmnt.scrollIntoView();
    // },100);

    //http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4
    //
    // this.bannerSlider.push(
    //   {
    //   id: 1, image:'https://service.eiac.gov.ae/media/banner/image/Accreditation_Pillar_Page-2.jpg',
    //   type:'image', delay:3500}
    //   );
    // this.bannerSlider.push(
    //   {id: 2, video:'https://vjs.zencdn.net/v/oceans.mp4', type:'video', delay:46700}
    //   );
    // this.bannerSlider.push(
    //   {id: 3, image:'https://service.eiac.gov.ae/media/banner/image/About_Us.jpg', type:'image', delay:4000}
    //   );
    //   this.bannerSlider.push(
    //     {id: 4, video:'https://www.w3schools.com/howto/rain.mp4', type:'video', delay:6100}
    //     );
    // this.bannerSlider.push(
    //   {id: 5, image:'https://service.eiac.gov.ae/media/banner/image/Registration.jpg', type:'image', delay:4000}
    //   );
    ////////console.log("Banner slider: ",this.bannerSlider );

    this.loadService();
    this.loadServiceHomepage();
  }

  searchTypeClose() {
    this.typedQuery = false;
    this.qeury = "";
  }
  searchType(theText: any) {
    //console.log(">>>Eneter: ", theText, " :: ", this.qeury);
    if (this.qeury != "") {
      this.typedQuery = true;
    }
    if (this.qeury === "") {
      this.typedQuery = false;
    }
  }

  useLanguage(language_id) {
    console.log(language_id);

    this.NgxPubSubService.publishEvent("language_id", language_id);
    localStorage.setItem("language_id", language_id);
    if (this.language_id == 1) {
      this.show_language = "E";
    } else if (this.language_id == 2) {
      this.show_language = "ع";
    } else {
      this.show_language = "Change Lang";
    }
  }

  searchClick() {
    //let url = '/search?q='+this.qeury;
    let url = "/search";
    // this.qeury = ;
    if (this.qeury != undefined && this.qeury != "") {
      //top.location.href = url;
      sessionStorage.setItem("query", this.qeury);
      this._router.navigateByUrl("/search");
    }
  }

  slickInit(e) {
    // console.log('slick initialized: ', e);
  }

  getSantizeUrl(url: string, param?: string) {
    let uurl = url + "?filter=" + param;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getYoutubeImage(url: string) {
    let uurl = url + "&output=embed";
    let videoTag = url.toString().split("v=").pop();
    return "http://img.youtube.com/vi/" + videoTag + "/hqdefault.jpg";
  }

  getImage300(url: string) {
    let imageName = url.toString().split(".");
    let imageextension = url.toString().split(".").pop();
    return imageName[0] + "-300x300." + imageextension;
    //console.log(videoTag[0]);
  }

  //Slider after click callback
  afterClick(theEvt: any) {
    ////////console.log('click happened: ');
    if (theEvt) {
      this.slickSliderModal.slickNext();
      return;
    }
  }

  //Slider change callback
  afterChange(theEvt: any) {
    ////////console.log('change happened: ');
    if (theEvt) {
      let slidIndex: number = this.slickSliderModal.currentIndex;
      let bannerData: any = this.bannerSlider[slidIndex];
      ////////console.log(">>> ",slidIndex , " :: ", this.bannerSlider[slidIndex]);
      if (bannerData.type === "image") {
        let sleep: number = bannerData.delay;
        this.slickSliderModal.slickPause();
        //console.log("enter image...",this.openButton);
        if (this.openButton) {
          this.openButton = false;
        }
        // if(this.videoDialogRef != null){
        //   this.videoDialogRef.close();
        //   this.videoDialogRef = null;
        //   this.userInteract = false;
        // }
        setTimeout(() => {
          this.slickSliderModal.slickPlay();
        }, sleep);
      }
      if (bannerData.type === "video") {
        let sleep: number = bannerData.delay; // //miliseconds timer delay
        this.slickSliderModal.slickPause();
        ////////console.log('video pause for ', sleep,' sec');
        let vidid = bannerData.id;
        let videElem: any = document.getElementById(
          vidid.toString()
        ) as HTMLElement;

        //setTimeout(() => this.videoPlayer.nativeElement.play(), 0);
        //return;
        //https://service.eiac.gov.ae/media/banner/image/aerial-hyperlapse-of-dubai-skyscrapers-during-scen-GH56S4R_1.ogv
        ////console.log(">>>Found video: ", videElem, " -- pause status: ", videElem.paused, " :: Muted: ", videElem.muted);
        if (videElem.paused) {
          var playPromise = videElem.play();
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                // Do nothing if not needed
                ////console.log("@promise enter...1");
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
                ////console.log("@promise enter...2", this.openButton);
                if (!this.openButton) {
                  this.openButton = true;
                  setTimeout(() => {
                    let butID = "play_button_" + vidid;
                    let butElem = document.getElementById(butID.toString());
                    //console.log("found play button: ",butID , " -- ",butElem);
                    butElem.addEventListener("click", (evt) => {
                      //console.log('playing event assigned...',videElem, " -- ", evt);
                      if (evt != undefined && evt.isTrusted === true) {
                        //console.log('play done...');
                        this.openButton = false;
                        videElem.play();
                      }
                    });
                    //butElem.click(); //auto click if needed
                  }, 100);
                }
                // if(!this.userInteract){
                //   ////console.log("Your browser seems not supporting video play, interact document to play video")
                //   this.userInteract = true;
                //   //videElem.controls = true;

                //   ////console.log('User not inetract...');
                //   // if(this.videoDialogRef === null){
                //   //   this.videoDialogRef = this.dialog.open(PlayMediaComponent,{
                //   //     width: "400px",
                //   //     height: "250px",
                //   //     disableClose: false,
                //   //     data:{
                //   //       vidObject: videElem,
                //   //       vidID: vidid,
                //   //       bannerData: bannerData
                //   //     }
                //   //   });
                //   // }
                // }
              });
          }
        }
        setTimeout(() => {
          videElem.addEventListener(
            "ended",
            () => {
              ////console.log('@video has ended...start from');
              videElem.currentTime = 0;
              videElem.pause();
            },
            false
          );
          // videElem.currentTime = 0;
          // videElem.pause();
          this.slickSliderModal.slickPlay();
          ////////console.log('slider start....');
        }, sleep);
      }
    }
  }

  loadServiceHomepage() {
    let homepageAPI = this.constant.homepageAPI;
    this.dataLoading = true;
    this.subscriptions.push(
      this.Service.get(homepageAPI, this.language_id).subscribe(
        (result) => {
          // if(result['banner'] && result['banner'].length>0){
          //   this.banner = this.constant.mediaPath+result['banner'][0]['banner']['image'];
          //   this.bannerTitle = result['banner'][0]['title'];
          // }
          //this.homepageresult = JSON.parse();
          ////////console.log("@ ",result);
          this.serviceDescriptionImage =
            result["records"][0]["general_content"].image_file;
          ////////console.log(this.serviceDescriptionImage,'serviceDescriptionImage');
          this.homepageContent = JSON.parse(result["records"][0].page_content);
          this.homepageContents = this.homepageContent[0];
          this.homepageSteps = this.homepageContents["step"];
          console.log(this.homepageSteps, "@ steps....");

          this.homepageResourcesTitle =
            this.homepageContents["resources"].title;
          this.homepageResourcesContent =
            this.homepageContents["resources"]["content"];
          console.log(this.homepageContents, "content");

          this.homepageWeHelpYouTitle =
            this.homepageContents["we_help_you"].title;
          this.homepageWeHelpYouTitleContent =
            this.homepageContents["we_help_you"]["content"];

          this.homepageDontHaveWasteTimetitle =
            this.homepageContents["time_to_waste"].title;
          this.homepageDontHaveWasteTimeContent =
            this.homepageContents["time_to_waste"].content;
          this.homepageDontHaveWasteTimeAfterContent =
            this.homepageContents["time_to_waste"].after_content;

          this.homepageaboutTitle = this.homepageContents["about"].title;
          this.homepageaboutTitleContent =
            this.homepageContents["about"].content;
          this.homepageaboutTitleAfterContent =
            this.homepageContents["about"].after_content;

          this.homepageWorkwithusTitle =
            this.homepageContents["work_with_us"].title;
          this.homepageWorkwithusTitleContent =
            this.homepageContents["work_with_us"].content;

          this.homepageTableContent = this.homepageContents["table"].content;

          this.homepagePlusiconContent =
            this.homepageContents["plus_icon"].content;

          //console.log(this.homepagePlusiconContent,'home baneerr below content');
        },
        (error) => {}
      )
    );
  }

  loadService() {
    let select_language = this.language_id == 1 ? 1 : 0;
    this.Service.getWithMultipleParams(
      this.constant.API.get_banner,
      "home",
      this.language_id
    ).subscribe(
      (res) => {
        console.log(res, "Banner page");
        if (res["banner"].length > 0) {
          this.banner =
            this.constant.mediaPath +
            res["banner"][select_language]["banner"]["image"];
          this.bannerTitle = res["banner"][select_language]["title"];
          this.bannerArabicTitle = res["banner"][0]["title"];
          this.pageData = res["banner"][select_language];

          if (res["banner"][select_language]["headerBanner"].length === 0) {
            // this.bannerSlider.push({image:'https://service.eiac.gov.ae/media/banner/image/Registration.jpg'});
            // this.bannerSlider.push({image:'https://service.eiac.gov.ae/media/banner/image/About_Us.jpg'});
            this.bannerSlider.push({ image: this.banner });
          }
          //
          if (res["banner"][select_language]["headerBanner"].length > 0) {
            // res['banner'][1]['headerBanner'].push(
            //   {id: 5, file_type:'video', video:'https://vjs.zencdn.net/v/oceans.mp4', title_en: 'Test Video', title_ar: 'Arabic None', type:'video', slider_time:47}
            // );
            ////////console.log("header banner: ", res['banner'][1]['headerBanner'])
            res["banner"][select_language]["headerBanner"].forEach(
              (rec, index) => {
                //let path = this.constant.mediaPath + '/media/' + rec;

                if (rec.file_type != "" && rec.file_type === "image") {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle =
                    this.language_id == 1 ? rec.title_en : rec.title_ar; //rec.title_en;
                  let arTitle = rec.title_ar;
                  let enContent =
                    this.language_id == 1 ? rec.content_en : rec.content_ar;
                  let timer = rec.slider_time * 1000; // convert milliseconds
                  this.bannerSlider.push({
                    image: path,
                    type: rec.file_type,
                    enTitle: enTitle,
                    arTitle: arTitle,
                    delay: timer,
                    enContent: enContent,
                  });
                }
                if (rec.file_type != "" && rec.file_type === "video") {
                  let path = this.constant.mediaPath + rec.image;
                  let enTitle =
                    this.language_id == 1 ? rec.title_en : rec.title_ar; //rec.title_en;
                  let arTitle = rec.title_ar;
                  let enContent =
                    this.language_id == 1 ? rec.content_en : rec.content_ar;
                  let timer = rec.slider_time * 1000; // convert milliseconds
                  let vidExt = path.toString().split(".").pop();
                  this.bannerSlider.push({
                    id: rec.id,
                    ext: vidExt,
                    video: path,
                    type: rec.file_type,
                    enTitle: enTitle,
                    arTitle: arTitle,
                    delay: timer,
                    enContent: enContent,
                  });
                }
                //let fileType =
                //this.bannerSlider.push({image:path, type: 'image'});
              }
            );
            // this.bannerSlider.push(
            //   {id: 5, video:'assets/bus.ogg', enTitle: 'Test Video', arTitle: 'Arabic None', type:'video', delay:18000}
            //   );
            //let vidpath = 'http://techslides.com/demos/sample-videos/small.mp4';
            //this.bannerSlider.push({video:vidpath, type: 'video', delay: 7000});
          }
        }
        //console.log(this.bannerSlider,'Banner images');

        this.footerBanner =
          this.constant.mediaPath +
          res["banner"][select_language]["banner"]["footer_image"];
        this.footerTitle = res["banner"][select_language].footer_title;
        this.footerDescription =
          res["banner"][select_language].footer_description;
      },
      (error) => {}
    );

    if (this.language_id == 2) {
      this.Service.getWithMultipleParamsHome(
        this.constant.API.service_api_home_slider,
        "all",
        1
      ).subscribe((res) => {
        this.serviceList = res["data"];
        let homeSlider: any[] = [];
        if (res["data"] != undefined) {
          this.serviceList = res["data"];
          this.serviceList.forEach((rec, index) => {
            if (rec.cab_data.cab > 0) {
              homeSlider.push(rec);
            }
          });
          this.serviceList = homeSlider;
          console.log(
            "@update slider data: ",
            homeSlider,
            " -- ",
            this.serviceList
          );
        }
        console.log(">>> AR ", this.language_id);
        if (res["certificate_status"] != undefined) {
          this.serviceHeaderList = res["certificate_status"];
          this.servicCloneeHeaderList = res["certificate_status"];
          this.servicCloneeHeaderList.forEach((item, key) => {
            if (key === 0) {
              this.loadSlider(item, this.language_id);
            }
          });
          console.log(
            "#Status Header List: ",
            this.serviceHeaderList,
            " :: ",
            this.language_id,
            " :: ",
            this.servicCloneeHeaderList
          );
        }
      });
    } else {
      //this.Service.get(this.constant.API.service_api,'accreditation_service')
      this.Service.getWithMultipleParamsHome(
        this.constant.API.service_api_home_slider,
        "all",
        this.language_id
      ).subscribe(
        (res) => {
          // //////console.log(">>>Service: ", res);
          this.serviceList = res["data"];
          let homeSlider: any[] = [];

          if (res["data"] != undefined) {
            this.serviceList = res["data"];
            this.serviceList.forEach((rec, index) => {
              if (rec.cab_data.cab > 0) {
                homeSlider.push(rec);
              }
            });
            this.serviceList = homeSlider;
            //console.log('update slider data: ', homeSlider, " -- ", this.serviceList);
          }
          console.log(">>> EN", this.language_id);
          if (res["certificate_status"] != undefined) {
            this.serviceHeaderList = res["certificate_status"];
            this.servicCloneeHeaderList = res["certificate_status"];
            console.log(
              "#Status Header List: ",
              this.serviceHeaderList,
              " :: ",
              this.language_id,
              " :: ",
              this.servicCloneeHeaderList
            );
          }
        },
        (error) => {}
      );
    }

    //this.loadSlider()
    this.Service.get(
      this.constant.API.news_announcement,
      this.language_id
    ).subscribe(
      (res) => {
        this.allDataNewsAnnouncements = res["records"];
      },
      (error) => {}
    );

    this.Service.get(
      this.constant.API.table_masterhome,
      this.language_id
    ).subscribe(
      (res) => {
        console.log(res, "TableData");
        this.tableData = res["tableData"];
        // this.tableTitle = res['tableData'][0]['table_master']['title'];
        this.tableTitle = res["tableData"][0].table_title;
        // ////////console.log(res['tableData'][0]['table_master']['title']);
      },
      (error) => {}
    );

    // this.Service.get(this.constant.API.cms_management,'ease')
    //   .subscribe(
    //     res => {
    //       this.easeContentTitle =  res['records'][0]['page_title'];
    //       this.easeContent =  res['records'][0]['page_content'];
    //     },
    //     error => {

    // })

    // this.Service.get(this.constant.API.cms_management,'about')
    //   .subscribe(
    //     res => {
    //       ////////console.log(res,'Test');
    //       this.aboutContentTitle =  res['records'][0]['page_title'];
    //       this.aboutContent =  res['records'][0]['page_content'];
    //     },
    //     error => {

    // })
  }

  goToDirectory(getTitle: string) {
    console.log("sel title: ", getTitle);
    let makeLink = "directory?filter=";
    if (getTitle != "") {
      makeLink += getTitle;
    }
    if (this.sliderStatus != "") {
      makeLink += "&filter_status=" + this.sliderStatus;
    }
    console.log(">>>Link: ", makeLink);
    this._router.navigateByUrl(makeLink);
  }

  loadSlider(fieldObj: any, language_id) {
    if (
      fieldObj != undefined &&
      fieldObj.field_mgmt != undefined &&
      fieldObj.field_mgmt > 0
    ) {
      console.log("sel status: ", fieldObj);
      this.sliderNavId = fieldObj.id;
      this.sliderStatus = fieldObj.title;
      this.Service.getWithMultipleParamsHome(
        this.constant.API.service_api_home_slider,
        parseInt(fieldObj.field_mgmt),
        language_id
      ).subscribe(
        (res) => {
          console.log(">>>Slider update Data: ", res);
          let homeSlider: any[] = [];
          if (res["data"] != undefined) {
            //this.serviceList  = res['data'];
            this.serviceList = res["data"];
            this.serviceList.forEach((rec, index) => {
              if (rec.cab_data.cab > 0) {
                homeSlider.push(rec);
              }
            });
            this.serviceList = homeSlider;
          }
        },
        (error) => {}
      );
    }
  }

  makeClass(navId) {
    //////////console.log("get id: ", navId, " -- ", this.sliderNavId);
    if (this.sliderNavId > 0 && this.sliderNavId == navId) {
      return "active";
    }
  }

  fixedNumberLoop(n: number): any[] {
    return Array(n);
  }
  selectOpenDiv() {
    this.isDropout = !this.isDropout;
  }
  // getservice(){
  //   this.es.getAllDocuments('eiac_product', 'data')
  // .then(response => {
  //   ////////console.log(response);
  // }, error => {
  //   console.error(error);
  // }).then(() => {
  //   ////////console.log('Show Customer Completed!>>');
  // });
  // }
  getContent() {
    this.Service.get(this.constant.API.listing, "").subscribe(
      (res) => {
        this.listArray = res;
        this.userId = localStorage.getItem("token");
      },
      (error) => {}
    );
    if (localStorage.getItem("logUser") != "") {
      let logUserData = JSON.parse(localStorage.getItem("logUser"));
      if (logUserData != null) {
        this.logUserName = logUserData.name;
      }
    }
  }

  //
  openMenuSearch() {
    this.isSearch = !this.isSearch;
    this.searchTypeClose();
  }
  //Open Menu Show Hide
  openMenuShowHide() {
    this.isSearch = false;
    this.isMenu = !this.isMenu;
    if (this.isMenu) {
      this.addngClass = "menueActive";
    } else {
      this.addngClass = "";
    }
  }
  serviceLists(el: HTMLElement) {
    //this.vps.scrollToAnchor(el);
    //console.log('service list...', el);
    el.scrollIntoView({ behavior: "smooth" });
  }

  slickScroll(el?: HTMLElement) {
    //this.vps.scrollToAnchor(el);
    el.scrollIntoView({ behavior: "smooth" });
  }

  openHappinessIndigatorDialog(obj: any, index: number) {
    const dialogRef = this.dialog.open(HappinessMeterComponent, {
      data: {
        message: "Are you sure want to delete?",
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
        obj: obj,
        index: index,
      },
    });
  }

  logoClick() {}

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }
}
