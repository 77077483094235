import {
  Component,
  OnInit,
  Input,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { AppService } from "../../../services/app.service";
import { Constants } from "src/app/services/constant.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Subscription } from "rxjs";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-media-centre",
  templateUrl: "./media-centre.component.html",
  styleUrls: ["./media-centre.component.scss"],
  providers: [AppService, Constants],
})
export class MediaCentreComponent implements OnInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  public mediaCentreDatas: Array<any> = [];
  public allData: any;

  @Input() langData;
  @Input() assessorsData: any;
  @ViewChild("slickModal", { static: false })
  slickModal: SlickCarouselComponent;
  // slideConfig = {
  //   "slidesToShow": 4,
  //   "slidesToScroll":4,
  //   "dots": true,
  //   "infinite": true,
  //   "nextArrow": "<div class='nav-btn next-slide'><span class=icon-right-arrow></span></div>",
  //   "prevArrow": "<div class='nav-btn prev-slide'><span class=icon-left-arrow></span></div>",
  // };
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    nextArrow:
      "<div class='nav-btn next-slide'><span class=icon-right-arrow></span></div>",
    prevArrow:
      "<div class='nav-btn prev-slide'><span class=icon-left-arrow></span></div>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor(
    public NgxPubSubService: NgxPubSubService,
    private translate: TranslateService,
    public Service: AppService,
    public constant: Constants,
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? "en" : "ar");
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("value changed", this.langData);
    if (this.langData != undefined && typeof this.langData === "object") {
      this.loadMediaData();
    }
  }

  loadMediaData() {
    let langDataParams: any;
    if (
      this.langData != undefined &&
      this.langData.laguage != undefined &&
      this.langData.laguage > 0
    ) {
      langDataParams = this.langData.laguage == 1 ? "?data=1" : "?data=2";
      this.Service.getwithoutData(
        this.constant.API.media_center + langDataParams
      ).subscribe(
        (res) => {
          this.allData = res["records"];
          this.mediaCentreDatas = res["records"];
        },
        (error) => {}
      );
    }
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? "en" : "ar");
    // console.log(this.assessorsData,'assessorsData');
    // this.publicationId = this.route.snapshot.queryParamMap.get('publicationId');
    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe(
      "language_id",
      (data) => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? "en" : "ar");
      }
    );

    console.log("@Calling media center....1");
    if (this.assessorsData == "assessors") {
      this.Service.getwithoutData(
        this.constant.API.media_center + "?data=18"
      ).subscribe(
        (res) => {
          //console.log('=>>>>>>');
          //console.log(res['records']);
          //console.log('=>>>>>>');
          this.allData = res["records"];
          this.mediaCentreDatas = res["records"];
        },
        (error) => {}
      );
    } else {
      console.log("@Calling media center....2");
      this.loadMediaData();
      // let langDataParams: any;
      // langDataParams = (this.langData.laguage == 1) ? '?data=1' : '?data=2';
      // this.Service.getwithoutData(this.constant.API.media_center + langDataParams)
      //   .subscribe(
      //     res => {
      //       //console.log('=>>>>>>');
      //       //console.log(res['records']);
      //       //console.log('=>>>>>>');
      //       this.allData = res['records'];
      //       this.mediaCentreDatas = res['records'];
      //     },
      //     error => {

      //     });
    }
  }

  slickInit(e) {
    // console.log('slick initialized: ', e);
  }

  getImage300(url: string) {
    let imageName = url.toString().split(".");
    let imageextension = url.toString().split(".").pop();
    return imageName[0] + "-300x300." + imageextension;
    //console.log(videoTag[0]);
  }

  getYoutubeImage(url: string) {
    let uurl = url + "&output=embed";
    let videoTag = url.toString().split("v=").pop();
    return "https://img.youtube.com/vi/" + videoTag + "/hqdefault.jpg";
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }
}
