import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent} from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { RouterModule } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateCompiler} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { LoaderComponent } from './component/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatFormFieldControl} from '@angular/material';
import { ValidationMessagesComponent } from '../../customComponent/validation-messages/validation-messages.component';
import { AppMaterial } from 'src/app/app-material.module';
import { PartialHeaderComponent } from './component/parcial-header/partial-header/partial-header.component';
import { HeaderAfterLoginComponent } from './component/header-after-login/header-after-login.component';
import { SidebarAfterLoginComponent } from './component/sidebar-after-login/sidebar-after-login.component';
import { FooterAfterLoginComponent } from './component/footer-after-login/footer-after-login.component';
import { AppService } from 'src/app/services/app.service';

import { SchemesComponent } from '../../pages/application-form/schemes/schemes.component';
import { MediaCentreComponent } from '../../pages/application-form/media-centre/media-centre.component';
import { ApplicationEnquiryFormComponent } from '../../pages/application-form/application-enquiry-form/application-enquiry-form.component';
import { ViewerComponentComponent} from '../../customComponent/viewer-component/viewer-component.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { SafePipeModule } from 'safe-pipe';
import { CustomStripTagsPipe } from '../../services/customStripTags.pipe';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { SlickCarouselModule } from 'ngx-slick-carousel';
//import { FormsModule } from '@angular/forms';
import {FormsModule, ReactiveFormsModule, FormGroup} from '@angular/forms';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FaIconLibrary, FontAwesomeModule, FaConfig } from '@fortawesome/angular-fontawesome';
import { ShareModule} from '@ngx-share/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [FooterComponent, HeaderComponent, LoaderComponent, 
    ValidationMessagesComponent, PartialHeaderComponent, HeaderAfterLoginComponent,CustomStripTagsPipe, 
    SidebarAfterLoginComponent, FooterAfterLoginComponent, SchemesComponent, 
    MediaCentreComponent, ApplicationEnquiryFormComponent],
  imports: [
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    RouterModule,
    CommonModule,
    AppMaterial,
    CarouselModule,
    FormsModule,
    SlickCarouselModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareModule,
    ShareIconsModule,
    FontAwesomeModule,
    AutocompleteLibModule,
    //RecaptchaFormsModule,
    RecaptchaModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers:[    
    AppService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LeeY8cUAAAAAMnRpgc79Pj6XLN0wo-m1JJZt3ie',
      } as RecaptchaSettings,
    },FaIconLibrary,FaConfig,
  ],
  exports: [
    TranslateModule,
    RouterModule,
    HeaderComponent,
    HeaderAfterLoginComponent, 
    SidebarAfterLoginComponent,
    PartialHeaderComponent,
    FooterAfterLoginComponent,
    FooterComponent,
    SchemesComponent,
    MediaCentreComponent,
    ApplicationEnquiryFormComponent,
    //ViewerComponentComponent,
    LoaderComponent,
    FormsModule,
    RecaptchaFormsModule,
    ValidationMessagesComponent,
    CustomStripTagsPipe,
    MatFormFieldModule,
    SlickCarouselModule
    
],
})
export class PartialModule { }
