import { Component, OnInit } from '@angular/core';
import  { AppService } from  '../../services/app.service';
import { Constants } from 'src/app/services/constant.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  providers: [AppService, Constants]
})
export class NotFoundComponent implements OnInit {

  linkdinUrl:any;
  twitterUrl:any;
  youtubeUrl:any;
  googleUrl:any;

  constructor(public constant:Constants, public Service: AppService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.Service.getwithoutData(this.constant.API.global_settings_data)
    .subscribe(
      res =>{
        // this.mapContent = res['records'];
        //console.log(res,'Footer');
        //console.log(res['records'][0].value,'Footer');
        var allRecords = res['records'];

        for(let key in allRecords){
          if(allRecords[key].meta_title == 'linkedin'){
            this.linkdinUrl = allRecords[key].value;
          }else if(allRecords[key].meta_title == 'twitter'){
            this.twitterUrl = allRecords[key].value;
          }else if(allRecords[key].meta_title == 'youtube'){
            this.youtubeUrl = allRecords[key].value;
          }else if(allRecords[key].meta_title == 'google'){
            this.googleUrl = allRecords[key].value;
          }
        }
        //console.log(this.twitterUrl,'twitterUrl');
      },
      error => {

    });
    
  }

}
