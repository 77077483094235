import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { HttpClient } from '@angular/common/http';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-happiness-meter',
  templateUrl: './happiness-meter.component.html',
  styleUrls: ['./happiness-meter.component.scss'],
  providers: [AppService, Constants]
})
export class HappinessMeterComponent implements OnInit {
  SubscribeCompanyChange: Subscription;
  language_id: any;
  public happinessMeterForm: any = {};
  ipAddress: any;
  step1: any = '';
  step2: any = '';
  step3: any = '';
  happy_image_step1: any = 'happy';
  normal_image_step1: any = 'normal';
  sad_image_step1: any = 'sad';
  version = VERSION;
  questionList: any;


  informative_uptodate_happy = 'happy';
  informative_uptodate_normal = 'normal';
  informative_uptodate_sad = 'sad';

  website_structure_happy = 'happy';
  website_structure_normal = 'normal';
  website_structure_sad = 'sad';

  website_performance_happy = 'happy';
  website_performance_normal = 'normal';
  website_performance_sad = 'sad';

  constructor(public NgxPubSubService: NgxPubSubService, private translate: TranslateService, public Service: AppService, public constant: Constants, public http: HttpClient, public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.language_id = localStorage.getItem("language_id");
    translate.setDefaultLang(this.language_id == 1 ? 'en' : 'ar');
  }

  ngOnInit() {
    this.language_id = localStorage.getItem("language_id");
    this.translate.use(this.language_id == 1 ? 'en' : 'ar');

    this.SubscribeCompanyChange = this.NgxPubSubService.subscribe('language_id',
      data => {
        this.language_id = data;
        this.translate.use(this.language_id == 1 ? 'en' : 'ar');
        this.loadService();
      });
    this.getIPAddress();
    this.loadService();
  }

  ngOnDestroy() {
    this.SubscribeCompanyChange.unsubscribe();
  }

  loadService() {
    this.Service.get(this.constant.API.happyness_meter_question, this.language_id)
      .subscribe(
        res => {
          this.questionList = res['questions'];
        })
  }

  closeModal() {
    //console.log('closing');
    //this.dialogRef.close();
    this.happinessMeterForm.ip_address = this.ipAddress;
    this.Service.post(this.constant.API.happyness_meter_data_save, this.happinessMeterForm)
      .subscribe(
        res => {
          //console.log(res,'success');
          if (res['status'] == true) {

          }
          else {

          }
        },
        error => {

        })
    const dialogRef = this.dialog.closeAll();
  }
  stepsAll(steps?: any, react?: any, site_descrp?: any) {
    if (steps == '1st') {
      this.step1 = steps
      this.happinessMeterForm.react = react;
      if (react == 'happy') {
        this.happy_image_step1 = 'happy-fill';
      } else if (react == 'normal') {
        this.normal_image_step1 = 'normal-fill';
      } else if (react == 'sad') {
        this.sad_image_step1 = 'sad-fill';
      }
    } else if (steps == '2nd' || steps == '2nds') {
      this.step2 = steps;
      if (site_descrp == 'informative_uptodate') {
        this.informative_uptodate_happy = 'happy';
        this.informative_uptodate_normal = 'normal';
        this.informative_uptodate_sad = 'sad';
        if (react == 'happy') {
          this.informative_uptodate_happy = 'happy-fill';
        } else if (react == 'normal') {
          this.informative_uptodate_normal = 'normal-fill';
        } else if (react == 'sad') {
          this.informative_uptodate_sad = 'sad-fill';
        }
        this.happinessMeterForm.informative_uptodate = react;
      }
      else if (site_descrp == 'website_structure') {
        this.website_structure_happy = 'happy';
        this.website_structure_normal = 'normal';
        this.website_structure_sad = 'sad';
        if (react == 'happy') {
          this.website_structure_happy = 'happy-fill';
        } else if (react == 'normal') {
          this.website_structure_normal = 'normal-fill';
        } else if (react == 'sad') {
          this.website_structure_sad = 'sad-fill';
        }
        this.happinessMeterForm.website_structure = react;
      }
      else if (site_descrp == 'website_performance') {
        this.website_performance_happy = 'happy';
        this.website_performance_normal = 'normal';
        this.website_performance_sad = 'sad';
        if (react == 'happy') {
          this.website_performance_happy = 'happy-fill';
        } else if (react == 'normal') {
          this.website_performance_normal = 'normal-fill';
        } else if (react == 'sad') {
          this.website_performance_sad = 'sad-fill';
        }
        this.happinessMeterForm.website_performance = react;
      }
    }
    else if (steps == '3rd') {
      //console.log(steps,'happyness_meter_data_steps');
      this.happinessMeterForm.ip_address = this.ipAddress;
      this.step3 = steps;
      this.Service.post(this.constant.API.happyness_meter_data_save, this.happinessMeterForm)
        .subscribe(
          res => {
            //console.log(res,'success');
            if (res['status'] == true) {

            }
            else {

            }
          },
          error => {

          })
    }
    this.happinessMeterForm.question1 = 'Informative, up to date and easy to find';
    this.happinessMeterForm.answer1 = this.happinessMeterForm.informative_uptodate;
    this.happinessMeterForm.question2 = 'Website, Structure and design';
    this.happinessMeterForm.answer2 = this.happinessMeterForm.website_structure;
    this.happinessMeterForm.question3 = "Website's performance and availibility";
    this.happinessMeterForm.answer3 = this.happinessMeterForm.website_performance;
    //  //console.log(this.happinessMeterForm);
    // //console.log(this.ipAddress);
  }

  stepsBack(steps) {
    if (steps == '3rd') {
      this.step1 = '1st';
      this.step2 = '2nd';
    } else if (steps == '2nd') {
      this.step1 = '';
      this.step2 = '';
      this.happy_image_step1 = 'happy';
      this.normal_image_step1 = 'normal';
      this.sad_image_step1 = 'sad';
    }
  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }
}
