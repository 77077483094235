import { Component, OnInit } from '@angular/core';
import  { AppService } from  '../../../services/app.service';
import { Constants } from 'src/app/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators} from '@angular/forms';
import { ContactUsModel } from '../../../models/contactUsModel';
//FormBuilder
@Component({
  selector: 'app-application-enquiry-form',
  templateUrl: './application-enquiry-form.component.html',
  styleUrls: ['./application-enquiry-form.component.scss'],
  providers: [AppService, Constants]
})
export class ApplicationEnquiryFormComponent implements OnInit {
  public enquiryForm: any = {};
  // enquiryForm: FormGroup;
  public is_submit:boolean=false;
  public enquiryObj: ContactUsModel;
  show:number = 0;
  public checkSecurity:boolean = false;
  public checkCaptchaValidation:boolean = false;
  //public fb: FormBuilder
  
  constructor(public Service: AppService, public constant:Constants,public toastr: ToastrService) {
    // this.enquiryForm = this.fb.group({
    //   name: ["", Validators.required],
    //   email: ["", Validators.required],
    //   message: ["", Validators.required],
    // });
   }

  ngOnInit() {
    this.checkCaptchaValidation = true;
  }

  omit_special_char123(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  omit_special_char(e: any) {
    console.log('@ ',e);
    try {
        let k;
        if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    } catch (e) {
      console.log('#input failed..',e.key);
    }
}

  onSubmit(ngForm) {
    //this.postPoll.emit();
    if(this.checkSecurity == true)
    {
      this.checkCaptchaValidation = true;
    }else{
      this.checkCaptchaValidation = false;
    }
    this.is_submit = true;
    if(ngForm.form.valid){
        this.Service.post(this.constant.API.enquiry_form,this.enquiryForm)
        .subscribe(
          res => {
            this.is_submit = false;
            // this.enquiryForm= "";
            this.toastr.success(res['msg'], ''); 
          },
          error => {
          
      })
    }
    
  }
  resolvedSecurity(captchaResponse: string) {
    let captchaStatus   =  captchaResponse;
    if(captchaStatus != ''){
      this.checkSecurity = true;
      this.checkCaptchaValidation = true;
    }
  }
}
