import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule , HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { JWTInterceptor } from './shared/jwt.interceptor';
import { ErrorInterceptor }  from './shared/error.interceptor';

import  { PartialModule } from './pages/partial/partial.module';
import  { CommonAllModule } from './pages/common-all/common-all.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { Constants } from 'src/app/services/constant.service';
import {TranslateModule, TranslateLoader, TranslateCompiler} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
//import { FormsModule } from '@angular/forms';
import {FormsModule, ReactiveFormsModule, FormGroup} from '@angular/forms';
//FormBuilder 
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material'; 
import { ToastrModule } from 'ngx-toastr';
//import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import {DialogBoxComponent} from './pages/common-all/dialog-box/dialog-box.component';
// import {EnquiryDialogboxComponent} from './pages/common-all/enquiry-dialogbox/enquiry-dialogbox.component';
import {EventCalenderComponent} from './pages/common-all/event-calender/event-calender.component';
import {BodDialogComponent} from './pages/common-all/bod-dialog/bod-dialog.component';

import { HomeComponent } from './pages/home/component/home.component';
import { TrainingServiceComponent } from './pages/application-form/trainingComponent/training-service/training-service.component';
import { TrainingEventComponent } from './pages/application-form/trainingComponent/training-event/training-event.component';
import { PublicationComponent } from './pages/application-form/publication/publication.component';
import { TrainingCourseListComponent } from './pages/application-form/trainingComponent/training-course-list/training-course-list.component';
import { TrainingCourceDetailComponent } from './pages/application-form/trainingComponent/training-cource-detail/training-cource-detail.component';
import { RegistrationServiceComponent } from './pages/application-form/registrationComponent/registration-service/registration-service.component'; 
import { AccreditationServiceComponent } from './pages/application-form/accreditationComponent/accreditation-service/accreditation-service.component';
import { ServiceComponent } from './pages/application-form/service/service.component';
import { SchemesComponent } from './pages/application-form/schemes/schemes.component';
import { ApplicationEnquiryFormComponent } from './pages/application-form/application-enquiry-form/application-enquiry-form.component';
import { MediaCentreComponent } from './pages/application-form/media-centre/media-centre.component';
import { AsscssorsServiceComponent } from './pages/application-form/assessorsComponent/asscssors-service/asscssors-service.component';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
//import { PublicationComponent } from './pages/application-form/publication/publication.component';
import  { PartialComponent } from './pages/partial/partial.component';
import { AppMaterial } from 'src/app/app-material.module';  


import { VerticalTimelineModule  } from 'angular-vertical-timeline';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { ViewerComponentComponent} from './customComponent/viewer-component/viewer-component.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PlayMediaComponent } from './pages/common-all/play-media/play-media.component';
import { ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import { ShareService} from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FaIconLibrary, FontAwesomeModule, FaConfig } from '@fortawesome/angular-fontawesome';
import { ShareModule} from '@ngx-share/core';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

//import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
//import { ConfirmationDialogComponent } from './services/confirmation-dialog/confirmation-dialog.component';
//import { ConfirmationDialogService } from './services/confirmation-dialog/confirmation-dialog.service';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("377866959506-7qddl8m47ej02u6fpsn8civvt9b1itdb.apps.googleusercontent.com")
  }
]);
export function provideConfig() {
  return config;
}
@NgModule({
  declarations: [
   AppComponent,   
   PartialComponent,//added by Milan
  //  DialogBoxComponent,
  //  EnquiryDialogboxComponent,
  //  HappinessMeterComponent,
   HomeComponent,
   AccreditationServiceComponent,
   AsscssorsServiceComponent,
   RegistrationServiceComponent,
   TrainingServiceComponent,
   TrainingEventComponent,
   ServiceComponent,
   ViewerComponentComponent,
   TrainingCourseListComponent,
   TrainingCourceDetailComponent,
   //PublicationComponent,
   //SchemesComponent,
   //ApplicationEnquiryFormComponent,
   //MediaCentreComponent,
   NotFoundComponent,
   PublicationComponent,
   PlayMediaComponent,
   SearchPageComponent
  // CustomStripTagsPipe
  ],
  
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppMaterial,
    VerticalTimelineModule,
    AppRoutingModule,
    RecaptchaModule.forRoot(),
    PartialModule,
    CommonAllModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PdfViewerModule, 
    NgxPaginationModule,
    AutocompleteLibModule,    
    //SlickCarouselModule,
    // NgxExtendedPdfViewerModule,
    //FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    ShareModule,    
    ShareIconsModule,
    FontAwesomeModule,
    //RecaptchaFormsModule,
    CarouselModule,
    MatProgressSpinnerModule,
    NgxFullCalendarModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPubSubModule
    
  ],
  providers: [
    ShareService,    
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     //6LdalL0UAAAAAIM3jzjGItLI-LXWfymnnMRm3ApO
    //     siteKey: '6LeeY8cUAAAAAMnRpgc79Pj6XLN0wo-m1JJZt3ie',
    //   } as RecaptchaSettings,
    // },
    {
      useFactory: provideConfig,
      provide: AuthServiceConfig ,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },    
    Constants,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },FaIconLibrary,FaConfig
  ],
  bootstrap: [AppComponent],
  entryComponents: [ViewerComponentComponent,EventCalenderComponent, PlayMediaComponent,BodDialogComponent],
  exports: [
    PartialModule, //RecaptchaFormsModule, //FormsModule
    CommonAllModule,
    PdfViewerModule,    
    AutocompleteLibModule
  ]
})
  
export class AppModule { }

