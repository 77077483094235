import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/services/constant.service';

@Component({
  selector: 'app-sidebar-after-login',
  templateUrl: './sidebar-after-login.component.html',
  styleUrls: ['./sidebar-after-login.component.scss'],
  providers: [Constants]
})
export class SidebarAfterLoginComponent implements OnInit {
  accData: boolean              = true;
  isClient: boolean             = false;
  isEiacStaff: boolean          = true;
  loader: boolean               = false;
  constructor(public _router: Router, public constant:Constants) { }

  ngOnInit() {
  }

  accDropDown() {
    this.accData = !this.accData;
  }
  portalLogout(){
    this.loader = true;
    setTimeout(() => {
      sessionStorage.setItem('token', "");
      if(this.constant.userToken != ''){
        this.constant.userToken = '';
      }
      this.loader = false;
      this._router.navigate(['/user/sign-in']);
    }, 3000);    
  }
}
